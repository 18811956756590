import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import Category from '@/views/Categories.vue'
import City from '@/views/Cities.vue'
import Feedback from '@/views/Feedback.vue'
import JobAlert from '@/views/JobAlert.vue'
import JobAlertUnsbuscribe from '@/views/UnsubscribeAlert.vue'
import Terms from '@/views/Terms.vue'
import Privacy from '@/views/Privacy.vue'
import Jobs from '@/views/Jobs.vue'
import JobDetails from '@/views/job/Details.vue'
import JobCongraturation from '@/views/job/Congraturation.vue'
import CompanyDetails from '@/views/companies/Details.vue'
import Companies from '@/views/Companies.vue'
import Blog from '@/views/Blog.vue'
import BlogDetails from '@/views/blogs/Details.vue'
import Candidates from '@/views/Candidates.vue'
import CandidateDetails from '@/views/freelancers/Details.vue'
import FreelancerService from '@/views/freelancers/Request.vue'
import Freshers from '@/views/Freshers.vue'
import GetStarted from '@/views/GetStarted.vue'
import Register from '@/views/auth/Register.vue'
import EmployerRegister from '@/views/auth/EmployerRegister.vue'
import FreelancerRegister from '@/views/auth/FreelancerRegister.vue'
import FresherRegister from '@/views/auth/FresherRegister.vue'
import Login from '@/views/auth/Login.vue'
import Roles from '@/views/auth/Roles.vue'
import ForgotPassword from '@/views/auth/ForgotPassword.vue'
import Banned from '@/views/custom/Banned.vue'
import VerifyEmail from '@/views/custom/VerifyEmail.vue' 
import Help from '@/views/help/Index.vue'
import UserGuide from '@/views/help/UserGuide.vue'
import HelpEmployer from '@/views/help/HelpEmployer.vue'
import HelpPostJobs from '@/views/help/HelpPostJobs.vue'
import HelpCandidatesJob from '@/views/help/HelpCandidatesJob.vue'
import HelpJobSeeker from '@/views/help/HelpJobSeeker.vue'

// job seeker starts
import StepExperience from '@/views/user/steps/Experience.vue' 
import StepTarget from '@/views/user/steps/Target.vue' 
import StepEducation from '@/views/user/steps/Education.vue' 
import StepPersonalInfo from '@/views/user/steps/PersonalInfo.vue'
import StepCompleted from '@/views/user/steps/Completed.vue' 
import Dashboard from '@/views/user/Dashboard.vue' 
import Resume from '@/views/user/Resume.vue'
import MyResume from '@/views/user/MyResume.vue'
import Profile from '@/views/user/profile/Index.vue' 
import EditProfile from '@/views/user/profile/Edit.vue' 
import BasicProfile from '@/views/user/profile/BasicInfo.vue'
import EditBasicProfile from '@/views/user/profile/EditBasic.vue'
import ContactProfile from '@/views/user/profile/Contact.vue'
import ChangePassword from '@/views/user/profile/Password.vue' 
import EditContactProfile from '@/views/user/profile/EditContact.vue'
import Activities from '@/views/user/activities/Index.vue'
import CandidateProgress from '@/views/user/activities/ApplicationProgress.vue'
import AddExperience from '@/views/user/carrier/Add.vue' 
import EditExperience from '@/views/user/carrier/Edit.vue' 
import AddEducation from '@/views/user/education/Add.vue' 
import EditEducation from '@/views/user/education/Edit.vue'
import AddSkill from '@/views/user/skills/Add.vue'
import EditSkill from '@/views/user/skills/Edit.vue'
import AddLanguage from '@/views/user/languages/Add.vue'
import EditLanguage from '@/views/user/languages/Edit.vue'
import AddSpecialist from '@/views/user/specialists/Add.vue'
import EditSpecialist from '@/views/user/specialists/Edit.vue'
import AddMembership  from '@/views/user/membership/Add.vue'
import EditMembership  from '@/views/user/membership/Edit.vue'
import AddReferee  from '@/views/user/referee/Add.vue'
import EditReferee  from '@/views/user/referee/Edit.vue'
import AddTraining  from '@/views/user/trainings/Add.vue'
import EditTraining  from '@/views/user/trainings/Edit.vue'
import AddCertificate  from '@/views/user/certificates/Add.vue'
import EditCertificate  from '@/views/user/certificates/Edit.vue'
import AddHobby  from '@/views/user/hobby/Add.vue'
import EditHobby  from '@/views/user/hobby/Edit.vue'
import TargetJob  from '@/views/user/jobs/Target.vue'
import EditTargetJob  from '@/views/user/jobs/Edit.vue'
import TargetSalary  from '@/views/user/jobs/Salary.vue'
import EditTargetSalary  from '@/views/user/jobs/EditSalary.vue'
import CandidateJob  from '@/views/user/jobs/Index.vue'
import SeekerJobAlert  from '@/views/user/jobs/Alert.vue'
import CandidateAddPreferredCountry  from '@/views/user/preference/AddCountry.vue'
import CandidateAddPreferredIndustry  from '@/views/user/preference/AddIndustry.vue'
import CandidateAddPreferredType  from '@/views/user/preference/AddType.vue'


// job seeker ends

// freelancer starts
import FreelancerStepSpecialist from '@/views/freelancer/steps/Specialist.vue'
import FreelancerStepProfile from '@/views/freelancer/steps/Profile.vue'
import FreelancerDashboard from '@/views/freelancer/Dashboard.vue'
import FreelancerRequest from '@/views/freelancer/requests/Index.vue'
import FreelancerRequestDetails from '@/views/freelancer/requests/Details.vue'
import FreelancerProfile from '@/views/freelancer/profile/Index.vue' 
import FreelancerEditProfile from '@/views/freelancer/profile/Edit.vue' 
import FreelancerBasicProfile from '@/views/freelancer/profile/BasicInfo.vue'
import FreelancerEditBasicProfile from '@/views/freelancer/profile/EditBasic.vue'
import FreelancerContactProfile from '@/views/freelancer/profile/Contact.vue'
import FreelancerChangePassword from '@/views/freelancer/profile/Password.vue' 
import FreelancerEditContactProfile from '@/views/freelancer/profile/EditContact.vue'
import FreelancerSpecialists  from '@/views/freelancer/specialists/Index.vue'
import FreelancerAddSpecialist from '@/views/freelancer/specialists/Add.vue'
import FreelancerEditSpecialist from '@/views/freelancer/specialists/Edit.vue'
import FreelancerSkills from '@/views/freelancer/skills/Index.vue'
import FreelancerAddSkill from '@/views/freelancer/skills/Add.vue'
import FreelancerEditSkill from '@/views/freelancer/skills/Edit.vue'
import FreelancerAddLanguage from '@/views/freelancer/languages/Add.vue' 
import FreelancerEditLanguage from '@/views/freelancer/languages/Edit.vue'
//freelancer ends

import RegisterCompany from '@/views/company/steps/RegisterCompany.vue'
import CompanyDashboard from '@/views/company/Dashboard.vue'
import CompanyJobs from '@/views/company/jobs/Index.vue'
import AddJob from '@/views/company/jobs/Add.vue'
import EditJob from '@/views/company/jobs/Edit.vue'
import CompanyCandidates from '@/views/company/candidates/Index.vue'
import CompanySavedResumes from '@/views/company/candidates/SavedResume.vue'
import CandidateResume from '@/views/company/candidates/Resume.vue'
import CompanyProfile from '@/views/company/accounts/Index.vue'
import AddCompanyProfile from '@/views/company/accounts/Add.vue'
import EditCompanyProfile from '@/views/company/accounts/Edit.vue'
import AddCompanyContact from '@/views/company/contact/Add.vue'
import EditCompanyContact from '@/views/company/contact/Edit.vue'
import CompanySocial from '@/views/company/accounts/Social.vue'
import CompanyPricing from '@/views/company/pricing/Index.vue'
import CompanyPakage from '@/views/company/pricing/Upgrade.vue'
import CompanyCart from '@/views/company/cart/Index.vue'
import CompanyApplicationProgress from '@/views/company/applications/Progress.vue'
import CompanyApplicationShortlisted from '@/views/company/applications/Shortlisted.vue'
import CompanyFirstInterview from '@/views/company/applications/FirstInterview.vue'
import CompanySecondShortlisted from '@/views/company/applications/Shortlisted2.vue'
import CompanySecondInterview from '@/views/company/applications/SecondInterview.vue'
import CompanyPassed from '@/views/company/applications/Passed.vue'
import CompanyApplicationDetails from '@/views/company/applications/Details.vue'
import CompanySecondResults from '@/views/company/applications/SecondResults.vue'
import CompanyFirstResults from '@/views/company/applications/FirstResults.vue'
import CompanyUsers from '@/views/company/users/Index.vue'
import AddCompanyUser from '@/views/company/users/Add.vue'
import CompanyMyProfile from '@/views/company/profile/Index.vue'
import CompanyEditMyProfile from '@/views/company/profile/Edit.vue'
import CompanyChangePassword from '@/views/company/profile/Password.vue'
import CompanyFreelancers from '@/views/company/freelancers/Index.vue'
import CompanyFreelancerDetails from '@/views/company/freelancers/Details.vue'
import CompanyTemporaryLink from '@/views/company/temporary/Index.vue'
import TemporaryResume from '@/views/company/temporary/Resume.vue'
import TemporaryDetails from '@/views/company/temporary/Details.vue'



// import Projects from '@/views/user/projects/Index.vue'
// import ProjectDetails from '@/views/user/projects/Details.vue'
import NotFound from '../views/404.vue'

const routes = [
  {
    path: '/', name: 'Home',component: Home
  },
  {
    path: '/about',name: 'About',component: About
  },
  {
    path: '/contacts',name: 'Contacts',component: Contact
  },
  {
    path: '/feedback',name: 'Feedback',component: Feedback
  },
  {
    path: '/job/alert',name: 'JobAlert',component: JobAlert
  },
  {
    path: '/job/alert/unsubscribe',name: 'JobAlertUnsbuscribe',component: JobAlertUnsbuscribe
  },
  {
    path: '/category/:id',name: 'Category',component: Category,props: true
  },
  {
    path: '/city/:id',name: 'City',component: City,props: true
  },
  {
    path: '/terms-and-conditions',name: 'Terms',component: Terms
  },
  {
    path: '/privacy-policy',name: 'Privacy',component: Privacy
  },
  {
    path: '/jobs',name: 'Jobs',component: Jobs
  },
  {
    path: '/jobs/:id',name: 'JobDetails',component: JobDetails,props: true
  },
  {
    path: '/jobs/congaturation',name: 'JobCongraturation',component: JobCongraturation,props: true
  },
  {
    path: '/companies',name: 'Companies',component: Companies
  },
  {
    path: '/companies/:id',name: 'CompanyDetails',component: CompanyDetails,props: true
  },
  {
    path: '/blog',name: 'Job Forum',component: Blog
  },
  {
    path: '/blog/:id',name: 'BlogDetails',component: BlogDetails,props: true
  },
  {
    path: '/freelancers',name: 'Candidates',component: Candidates
  },
  {
    path: '/freelancer/:id',name: 'CandidateDetails',component: CandidateDetails, props: true
  },
  {
    path: '/freelancer/services/:id',name: 'FreelancerService',component: FreelancerService, props: true
  },
  {
    path: '/freshers',name: 'Freshers',component: Freshers
  },
  {
    path: '/register/jobseeker',name: 'Register',component: Register
  },
  {
    path: '/register/employer',name: 'EmployerRegister',component: EmployerRegister
  },
  {
    path: '/register/freelancer',name: 'FreelancerRegister',component: FreelancerRegister
  },
  {
    path: '/register/fresher',name: 'FresherRegister',component: FresherRegister
  },
  {
    path: '/login',name: 'Login',component: Login
  },
  {
    path: '/roles',name: 'Roles',component: Roles
  },
  {
    path: '/forgotpassword',name: 'ForgotPassword',component: ForgotPassword
  },

  //help and user guide

  {
    path: '/help',name: 'Help',component: Help
  },
  {
    path: '/help/user/guide',name: 'UserGuide',component: UserGuide
  },
  {
    path: '/help/user/guide/employer/getstarted',name: 'HelpEmployer',component: HelpEmployer
  },
  {
    path: '/help/user/guide/employer/postjobs',name: 'HelpPostJobs',component: HelpPostJobs
  },
  {
    path: '/help/user/guide/employer/selectcandidates',name: 'HelpCandidatesJob',component: HelpCandidatesJob
  },
  {
    path: '/help/user/guide/jobseeker/getstarted',name: 'HelpJobSeeker',component: HelpJobSeeker
  },
  // backend rootes 

  //self tests routes starts
  {
    path: '/selftests', name: 'SelfTests', component: () => import('@/views/tests/Index.vue')
  },
  {
    path: '/selftests/:id',  name: 'SelfTestsDetails', component: () => import('@/views/tests/Details.vue')
  },
  {
    path: '/selftests/question/:id',  name: 'SelfTestsQuestion', component: () => import('@/views/tests/Questions.vue')
  },
  {
    path: '/selftests/results/:id',  name: 'SelfTestsResults', component: () => import('@/views/tests/Results.vue')
  },
  //self test routes ends

  //job seeker navigations
  {
    path: '/banned', name: 'Banned',meta: { requiredAuth : true },component: Banned
  },
  {
    path: '/verify/email', name: 'VerifyEmail',meta: { requiredAuth : true },component: VerifyEmail
  },
  {
    path: '/get/started', name: 'GetStarted',component: GetStarted
  },
  {
    path: '/step/experience', name: 'StepExperience',meta: { requiredAuth : true },component: StepExperience
  },
  {
    path: '/step/target/job', name: 'StepTarget',meta: { requiredAuth : true },component: StepTarget
  },
  {
    path: '/step/education', name: 'StepEducation',meta: { requiredAuth : true },component: StepEducation
  },
  {
    path: '/step/personal/info', name: 'StepPersonalInfo',meta: { requiredAuth : true },component: StepPersonalInfo
  },
  {
    path: '/step/completed', name: 'StepCompleted',meta: { requiredAuth : true },component: StepCompleted
  },
  {
    path: '/dashboard', name: 'Dashboard',meta: { requiredAuth : true },component: Dashboard
  },
  {
    path: '/resume/:id',name: 'Resume',meta: { requiredAuth : true },component: Resume
  },
  {
    path: '/myresume',name: 'MyResume',component: MyResume
  },
  {
    path: '/profile',name: 'Profile',meta: { requiredAuth : true },component: Profile
  },
  {
    path: '/profile/edit',name: 'EditProfile',meta: { requiredAuth : true },component: EditProfile
  },
  {
    path: '/profile/basic',name: 'BasicProfile',meta: { requiredAuth : true },component: BasicProfile
  },
  {
    path: '/profile/basic/edit/:id',name: 'EditBasicProfile',meta: { requiredAuth : true },component: EditBasicProfile
  },
  {
    path: '/profile/contact',name: 'ContactProfile',meta: { requiredAuth : true },component: ContactProfile
  },
  {
    path: '/profile/password',name: 'ChangePassword',meta: { requiredAuth : true },component: ChangePassword
  },
  {
    path: '/profile/contact/edit/:id',name: 'EditContactProfile',meta: { requiredAuth : true },component: EditContactProfile
  },
  {
    path: '/activities',name: 'Activities',meta: { requiredAuth : true },component: Activities
  },
  {
    path: '/activities/application/progress/:id',name: 'CandidateProgress',meta: { requiredAuth : true },component: CandidateProgress
  },
  {
    path: '/experience/add',name: 'AddExperience',meta: { requiredAuth : true },component: AddExperience,props: true
  },
  {
    path: '/experience/edit/:id',name: 'EditExperience',meta: { requiredAuth : true },component: EditExperience,props: true
  },
  {
    path: '/education/add',name: 'AddEducation',meta: { requiredAuth : true },component: AddEducation,props: true
  },
  {
    path: '/education/edit/:id',name: 'EditEducation',meta: { requiredAuth : true },component: EditEducation,props: true
  },
  {
    path: '/skill/add',name: 'AddSkill',meta: { requiredAuth : true },component: AddSkill,props: true
  },
  {
    path: '/skill/edit/:id',name: 'EditSkill', meta: { requiredAuth : true }, component: EditSkill,props: true
  },
  {
    path: '/language/add',name: 'AddLanguage',meta: { requiredAuth : true },component: AddLanguage,props: true
  },
  {
    path: '/language/edit/:id',name: 'EditLanguage', meta: { requiredAuth : true }, component: EditLanguage,props: true
  },
  {
    path: '/specialists/add',name: 'AddSpecialist',meta: { requiredAuth : true },component: AddSpecialist,props: true
  },
  {
    path: '/specialists/edit/:id',name: 'EditSpecialist',meta: { requiredAuth : true },component: EditSpecialist,props: true
  },
  {
    path: '/membership/add',name: 'AddMembership',meta: { requiredAuth : true },component: AddMembership,props: true
  },
  {
    path: '/membership/edit/:id',name: 'EditMembership',meta: { requiredAuth : true },component: EditMembership,props: true
  },
  {
    path: '/referee/add',name: 'AddReferee',meta: { requiredAuth : true },component: AddReferee,props: true
  },
  {
    path: '/referee/edit/:id',name: 'EditReferee',meta: { requiredAuth : true },component: EditReferee,props: true
  },
  {
    path: '/job/target',name: 'TargetJob',meta: { requiredAuth : true },component: TargetJob,props: true
  },
  {
    path: '/job/target/:id',name: 'EditTargetJob',meta: { requiredAuth : true },component: EditTargetJob,props: true
  },
  {
    path: '/salary/target',name: 'TargetSalary',meta: { requiredAuth : true },component: TargetSalary,props: true
  },
  {
    path: '/salary/target/:id',name: 'EditTargetSalary',meta: { requiredAuth : true },component: EditTargetSalary,props: true
  },
  {
    path: '/myjobs/',name: 'CandidateJob',meta: { requiredAuth : true },component: CandidateJob,props: true
  },
  {
    path: '/myjobs/alert',name: 'SeekerJobAlert',meta: { requiredAuth : true },component: SeekerJobAlert,props: true
  },
  {
    path: '/preferred/country/add/',name: 'CandidateAddPreferredCountry',meta: { requiredAuth : true },component: CandidateAddPreferredCountry,props: true
  },
  {
    path: '/preferred/industry/add/',name: 'CandidateAddPreferredIndustry',meta: { requiredAuth : true },component: CandidateAddPreferredIndustry,props: true
  },
  {
    path: '/preferred/type/add/',name: 'CandidateAddPreferredType',meta: { requiredAuth : true },component: CandidateAddPreferredType,props: true
  },
  {
    path: '/hobby/add',name: 'AddHobby',meta: { requiredAuth : true },component: AddHobby,props: true
  },
  {
    path: '/hobby/edit/:id',name: 'EditHobby',meta: { requiredAuth : true },component: EditHobby,props: true
  },
  {
    path: '/training/add',name: 'AddTraining',meta: { requiredAuth : true },component: AddTraining,props: true
  },
  {
    path: '/training/edit/:id',name: 'EditTraining',meta: { requiredAuth : true }, component: EditTraining, props: true
  },
  {
    path: '/certificate/add',name: 'AddCertificate',meta: { requiredAuth : true }, component: AddCertificate, props: true
  },
  {
    path: '/certificate/edit/:id',name: 'EditCertificate',meta: { requiredAuth : true }, component: EditCertificate, props: true
  },

  // frelancer routes starts
  {
    path: '/freelancer/step/specialists',name: 'FreelancerStepSpecialist',meta: { requiredAuth : true },component: FreelancerStepSpecialist,props: true
  },
  {
    path: '/freelancer/step/profile',name: 'FreelancerStepProfile',meta: { requiredAuth : true },component: FreelancerStepProfile,props: true
  },
  {
    path: '/freelancer/dashboard',name: 'FreelancerDashboard',meta: { requiredAuth : true }, component: FreelancerDashboard, props: true
  },
  {
    path: '/freelancer/request',name: 'FreelancerRequest',meta: { requiredAuth : true }, component: FreelancerRequest, props: true
  },
  {
    path: '/freelancer/request/:id',name: 'FreelancerRequestDetails',meta: { requiredAuth : true }, component: FreelancerRequestDetails, props: true
  },
  {
    path: '/freelancer/profile',name: 'FreelancerProfile',meta: { requiredAuth : true },component: FreelancerProfile
  },
  {
    path: '/freelancer/profile/edit',name: 'FreelancerEditProfile',meta: { requiredAuth : true },component: FreelancerEditProfile
  },
  {
    path: '/freelancer/profile/basic',name: 'FreelancerBasicProfile',meta: { requiredAuth : true },component: FreelancerBasicProfile
  },
  {
    path: '/freelancer/profile/basic/edit/:id',name: 'FreelancerEditBasicProfile',meta: { requiredAuth : true },component: FreelancerEditBasicProfile
  },
  {
    path: '/freelancer/profile/contact',name: 'FreelancerContactProfile',meta: { requiredAuth : true },component: FreelancerContactProfile
  },
  {
    path: '/freelancer/profile/password',name: 'FreelancerChangePassword',meta: { requiredAuth : true },component: FreelancerChangePassword
  },
  {
    path: '/freelancer/profile/contact/edit/:id',name: 'FreelancerEditContactProfile',meta: { requiredAuth : true },component: FreelancerEditContactProfile
  },
  {
    path: '/freelancer/specialists',name: 'FreelancerSpecialists',meta: { requiredAuth : true },component: FreelancerSpecialists,props: true
  },
  
  {
    path: '/freelancer/specialists/add',name: 'FreelancerAddSpecialist',meta: { requiredAuth : true },component: FreelancerAddSpecialist,props: true
  },
  {
    path: '/freelancer/specialists/edit/:id',name: 'FreelancerEditSpecialist',meta: { requiredAuth : true },component: FreelancerEditSpecialist,props: true
  },
  {
    path: '/freelancer/skills',name: 'FreelancerSkills',meta: { requiredAuth : true },component: FreelancerSkills,props: true
  },
  {
    path: '/freelancer/skill/add',name: 'FreelancerAddSkill',meta: { requiredAuth : true },component: FreelancerAddSkill,props: true
  },
  {
    path: '/freelancer/skill/edit/:id',name: 'FreelancerEditSkill', meta: { requiredAuth : true }, component: FreelancerEditSkill,props: true
  },
  {
    path: '/freelancer/language/add',name: 'FreelancerAddLanguage',meta: { requiredAuth : true },component: FreelancerAddLanguage,props: true
  },
  {
    path: '/freelancer/language/edit/:id',name: 'FreelancerEditLanguage', meta: { requiredAuth : true }, component: FreelancerEditLanguage,props: true
  },
  //freelancer routes ends

  //company routes
  {
    path: '/register/company', name: 'RegisterCompany',meta: { requiredAuth : true },component: RegisterCompany
  },
  {
    path: '/company/dashboard',name: 'CompanyDashboard',meta: { requiredAuth : true }, component: CompanyDashboard, props: true
  },
 
  {
    path: '/company/jobs',name: 'CompanyJobs',meta: { requiredAuth : true }, component: CompanyJobs, props: true
  },
  {
    path: '/company/jobs/add',name: 'AddJob',meta: { requiredAuth : true }, component: AddJob, props: true
  },
  {
    path: '/company/jobs/edit/:id',name: 'EditJob',meta: { requiredAuth : true }, component: EditJob, props: true
  },
  {
    path: '/company/find/candidates',name: 'CompanyCandidates',meta: { requiredAuth : true }, component: CompanyCandidates, props: true
  },
  {
    path: '/company/saved/resumes',name: 'CompanySavedResumes',meta: { requiredAuth : true }, component: CompanySavedResumes, props: true
  },
  {
    path: '/company/candidate/resume/:id',name: 'CandidateResume',meta: { requiredAuth : true },component: CandidateResume
  },
  {
    path: '/company/profile',name: 'CompanyProfile',meta: { requiredAuth : true }, component: CompanyProfile, props: true 
  },
  {
    path: '/company/profile/add',name: 'AddCompanyProfile',meta: { requiredAuth : true }, component: AddCompanyProfile, props: true 
  },
  {
    path: '/company/profile/edit/:id',name: 'EditCompanyProfile',meta: { requiredAuth : true }, component: EditCompanyProfile, props: true 
  },
  {
    path: '/company/contact/add',name: 'AddCompanyContact',meta: { requiredAuth : true }, component: AddCompanyContact, props: true 
  },
  {
    path: '/company/contact/edit/:id',name: 'EditCompanyContact',meta: { requiredAuth : true }, component: EditCompanyContact, props: true 
  },
  {
    path: '/company/contact/social/:id',name: 'CompanySocial',meta: { requiredAuth : true }, component: CompanySocial, props: true 
  },
  {
    path: '/company/pricing',name: 'CompanyPricing',meta: { requiredAuth : true }, component: CompanyPricing, props: true
  },
  {
    path: '/company/pricing/packages/:id',name: 'CompanyPakage',meta: { requiredAuth : true }, component: CompanyPakage, props: true
  },
  {
    path: '/company/cart',name: 'CompanyCart',meta: { requiredAuth : true }, component: CompanyCart, props: true
  },
  {
    path: '/company/application/progress/:id',name: 'CompanyApplicationProgress',meta: { requiredAuth : true }, component: CompanyApplicationProgress, props: true
  },
  {
    path: '/company/application/shortlisted/:id',name: 'CompanyApplicationShortlisted',meta: { requiredAuth : true }, component: CompanyApplicationShortlisted, props: true
  },
  {
    path: '/company/application/intreview/:id/:job',name: 'CompanyFirstInterview',meta: { requiredAuth : true }, component: CompanyFirstInterview, props: true
  },
  {
    path: '/company/application/details/:id/:job',name: 'CompanyApplicationDetails',meta: { requiredAuth : true }, component: CompanyApplicationDetails, props: true
  },
  {
    path: '/company/application/results/:id',name: 'CompanyFirstResults',meta: { requiredAuth : true }, component: CompanyFirstResults, props: true
  },
  {
    path: '/company/application/shortlisted/second/:id',name: 'CompanySecondShortlisted',meta: { requiredAuth : true }, component: CompanySecondShortlisted, props: true
  },
  {
    path: '/company/application/intreview/second/:id/:job',name: 'CompanySecondInterview',meta: { requiredAuth : true }, component: CompanySecondInterview, props: true
  },
  {
    path: '/company/application/results/second/:id',name: 'CompanySecondResults',meta: { requiredAuth : true }, component: CompanySecondResults, props: true
  },
  {
    path: '/company/application/passed/:id',name: 'CompanyPassed',meta: { requiredAuth : true }, component: CompanyPassed, props: true
  },
  {
    path: '/company/users',name: 'CompanyUsers',meta: { requiredAuth : true }, component: CompanyUsers, props: true
  },
  {
    path: '/company/users/add',name: 'AddCompanyUser',meta: { requiredAuth : true }, component: AddCompanyUser, props: true 
  },
  {
    path: '/company/myprofile',name: 'CompanyMyProfile',meta: { requiredAuth : true }, component: CompanyMyProfile, props: true
  },
  {
    path: '/company/edit/myprofile',name: 'CompanyEditMyProfile',meta: { requiredAuth : true }, component: CompanyEditMyProfile, props: true
  },
  {
    path: '/company/myprofile/password',name: 'CompanyChangePassword',meta: { requiredAuth : true }, component: CompanyChangePassword, props: true
  },
  {
    path: '/company/freelancers',name: 'CompanyFreelancers',meta: { requiredAuth : true }, component: CompanyFreelancers, props: true
  },
  {
    path: '/company/freelancer/:id',name: 'CompanyFreelancerDetails',meta: { requiredAuth : true }, component: CompanyFreelancerDetails, props: true
  },
  {
    path: '/company/temporary/:link',name: 'CompanyTemporaryLink', component: CompanyTemporaryLink, props: true
  },
  {
    path: '/company/temporary/:link/:id',name: 'TemporaryResume', component: TemporaryResume, props: true
  },
  {
    path: '/company/temporary/details/:link/:id/:job',name: 'TemporaryDetails', component: TemporaryDetails, props: true
  },
 
  //redirect for old routes
  {
    path: '/all-jobs',redirect: '/jobs',
    path: '/services',redirect: '/about'
  }, 
  // catch all 404 pages 
  {
    path: '/:catchAll(.*)',name: 'NotFound',component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return document.getElementById('app').scrollIntoView();
    }
  }
})


function loggedIn(){
  return localStorage.getItem("user_data")
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiredAuth)) {
    if (!loggedIn()) {
      next({
        path: '/login',
        query: {redirect: to.fullpath}
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
