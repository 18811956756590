<template>
  <div class="col mt-4 pt-2" id="tables">
        <div class="component-wrapper rounded shadow">
            <div class="p-4">
                <div class=" bg-white">
                   <div v-if="jobs.length">
                       <h3 class="text-center">My Target Job</h3>
                        <div class="d-flex flex-row-reverse">
                            <router-link :to="{ name: 'EditTargetJob', params:{ id:jobs[0].id }}" class="btn btn-pills text-primary">
                                <svg height="20" width="20"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                            </router-link>
                        </div>
                        <h6>Job Name: {{ jobs[0].name }}</h6>
                                    
                              
                    </div>
                    <div v-else >
                        <div class="text-center">
                            <h4 >No Job Target has been added</h4>
                                <router-link class="btn btn-primary end-0" :to="{ name : 'TargetJob' }">Add A target Job</router-link>

                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['jobs'],
    methods:{
        
    }

}
</script>

<style>

</style>