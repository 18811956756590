<template>
    <div class="row">
        <div class="col-auto" v-for="specialist in user_info.specialists" :key="specialist.id">
            <div class="d-flex key-feature align-items-center p-3 rounded shadow"  >
                <div class="flex-1 content ms-3">
                    <h6 class="title text-dark mb-0">{{ specialist.name }}</h6>
                    <div class="d-flex justify-content-center" v-if="editable == 1">
                        <router-link :to="{ name: 'EditSpecialist', params:{ id:specialist.id }}" class="btn btn-pills text-primary">
                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                        </router-link>
                        <a @click="deleteSpecialist(specialist.id)" class="btn btn-pills text-primary">
                            <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Swal from 'sweetalert2'
export default {
    props: ['user_info','editable'],
    methods: {
        deleteSpecialist(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/specialists/destroy/'+ id)
                .then(response => {
                     this.user_info.specialists = this.user_info.specialists.filter(specialist => {
                      return specialist.id != id
                    })
                }).catch(() => {
                    this.$router.push({ name: 'Specialists'})
                })
                Swal.fire(
                'Deleted!',
                'Specialist has been Deleted.',
                'success'
                )
            }
            })
        }
    }

}
</script>

<style>

</style>