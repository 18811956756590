<template>
  <Navbar />
    <section class="section">
        <div class="container">
            <div v-if="!isLoading"><br>
                <h2 class="text-center text-primary">All Freelancers</h2>
                <div class="d-flex flex-row-reverse">
                    <router-link class="btn text-primary end-0" :to="{ name : 'FreelancerRegister' }">
                       Register As Freelancer
                    </router-link>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <input type="text" v-model="search"  class="form-control banner-search"  name="keyword" id="joblist" placeholder="Search Keyword" autocomplete="off" aria-label="Search Keyword">
                    </div>
                </div>
                <div class="row" v-if="matchingCandidates.length">
                    <div v-for="candidate in matchingCandidates" :key="candidate.id" class="col-lg-3 col-md-4 col-16 mt-4 pt-2" >
                        <div class="candidate-list card rounded border-0 shadow">
                            <div class="card-body">
                                <ul class="list-unstyled align-items-center">
                                    <li class="list-inline-item float-end"><a href="javascript:void(0)" class="text-muted like"><i class="mdi mdi-heart h5 mb-0"></i></a></li>
                                    <li class="list-inline-item" v-for="role in candidate.roles" :key="role.id"><span class="badge rounded-pill bg-soft-success">{{ role.name }}</span></li>
                                </ul>

                                <div class="content text-center">
                                    <div v-if="candidate.avatar">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + candidate.avatar" class="avatar avatar-md-md shadow-md rounded-circle" alt="">
                                    </div>
                                    <div v-else>
                                        <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-md-md shadow-md rounded-circle" alt="">
                                    </div>
                                    <router-link :to="{ name: 'CandidateDetails', params: {id: candidate.id}}" class="text-dark h5 name">{{ candidate.fname }} {{ candidate.lname }}
                                        <span class="text-primary" v-if="candidate.verified == 1">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                        </span>
                                    </router-link>
                                    <p class="text-muted mb-0" v-if="candidate.specialists[0]">{{ candidate.specialists[0].name }}</p>
                                    <p class="text-danger mb-0" v-else>No Specialist</p>
                                    <span class="text-muted" v-if="candidate.profile[0]"><span v-if="candidate.profile[0].country">{{ candidate.profile[0].country.name }},</span> <span class="text-primary" v-if="candidate.profile[0].city">{{ candidate.profile[0].city.name }}</span></span>
                                    <span class="text-danger" v-else ><br> Profile incomplete</span>
                                    
                                    <div class="d-grid">
                                        <router-link :to="{ name: 'CandidateDetails', params: {id: candidate.id}}" class="btn btn-primary" >View Details<i class="uil uil-angle-right-b align-middle"></i></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center" v-else>
                    <br>
					<img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
					<h3 class="text-center">No Job Freelancers</h3>
				</div>
            </div>
            <div v-else>
                <Loading />
            </div>
        </div>
        
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getCandidates from '@/composable/getCandidates'
import Loading from './custom/Loading.vue'
export default {
  components: { Navbar,Footer,Loading },
  data(){
      return {
          currentPage: 'Freelancers',
          previousPage: '',
          isLoading: true,
      } 
  },
  setup(){
      const { candidates, search, matchingCandidates, profile,  error,errorProfile, load } = getCandidates()
      load()
      return { candidates, search, matchingCandidates, profile,  error, errorProfile }
  },
  methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 1500);
   }
  },
  mounted () {
    document.title = `Freelancers - Vipawaworks`
    this.isLoad()
}

}
</script>

<style>
.item + .item:before {
  content: ", ";
}
</style>