<template>
    <div class="container" v-if="companies.length">
          <h2 class="text-center text-primary">Who's Hiring on Vipawaworks</h2>  
        <div class="row justify-content-center">
            <div v-for="company in companies" :key="company.id" class="col-lg-3 col-md-3 col-12 mt-4 pt-2">
                <router-link :to="{ name: 'CompanyDetails', params:{ id:company.id }}" class="justify-content-center">
                    <div class="rounded shadow">
                        <div class="row justify-content-center">
                            <div class="col-3">
                                <div v-if="company.logo != null">
                                    <img v-bind:src="'https://api.vipawaworks.com/' + company.logo" class="avatar avatar avatar-md-sm  rounded-circle shadow" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar avatar-md-sm rounded-circle shadow" alt="">
                                </div>
                            </div>
                            <div class="col-9">
                                <h4 class="title mb-0 text-dark"><router-link :to="{ name: 'CompanyDetails', params:{ id:company.id }}" class="text-dark" v-if="company.name">{{ company.name && company.name.length < 15 ? company.name : company.name.substring(0,12)+".."   }}</router-link></h4>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <p class="text-primary mb-0" v-if="company.jobs_count">
                                        <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="feather feather-link fea icon-sm text-warning me-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                        {{ company.jobs_count }} Jobs
                                    </p> 
                                    <p class="text-primary mb-0" v-else>No active Jobs</p> 
                                </div>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="text-center">
                <br>
                <router-link :to="{ name:'Companies'}" class="btn btn-primary">See More Companies</router-link>
                
            </div>
        </div>
    </div><br>
    <div class="alertJob">
        <div class="container mt-100 mt-60">
            <div class="row">
                    <div class="section-title">
                        <h3 class="text-white">Set Job Alert</h3>
                        <h6 class="text-white">Want To be the First to know New Job Post?</h6>
                        <p class="text-white">Set Job Alert, when hiring company Post a job through <span class="text-success fw-bold">Vipawaworks,</span> We will notify you Right a way</p>
                        <router-link :to="{ name : 'JobAlert' }" class="btn btn-outline-light mt-4">
                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path></svg>
                                Set Job Alert
                        </router-link>
                    </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import getCompanies from '@/composable/getFrontCompanies'
export default {
    setup(){
      const { companies, search, matchingCompanies, error, loadCompanies } = getCompanies()
      loadCompanies()
      return { companies, matchingCompanies, search, loadCompanies, error }
  },
}
</script>

<style scoped>
.alertJob {
    background-image: url(../assets/img/jobAlert.png);
    background-color: #156394;
    background-repeat: no-repeat;
    background-position: center right;
    height: auto;
    line-height: 1.2;
    font-size: 1rem;
    text-align: left;
}
</style>