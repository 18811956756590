<template>
    <Navbar />
    <div v-if="!isLoading">
        <section class="bg-half-260 d-table w-100 slider" >
            <div class="bg-overlay"></div>
        </section>
        <section class="section" v-if="candidate">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-5 col-12">
                        <div class="card job-profile shadow border-0">
                            <div class="text-center py-5 border-bottom rounded-top">
                                <div v-if="candidate.avatar">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + candidate.avatar" class="avatar avatar-medium mx-auto rounded-circle shadow d-block" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-medium mx-auto rounded-circle shadow d-block" alt="">
                                </div>
                                <h5 class="mt-3 mb-0">{{ candidate.fname }} {{candidate.lname }} 
                                     <span class="text-primary" v-if="candidate.verified == 1">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                        </span>
                                </h5>
                                <p class="text-muted mb-0" v-if="candidate.specialists[0]">{{ candidate.specialists[0].name }}</p>
                            </div>                               
                            <div class="card-body" v-if="candidate.profile[0]">
                                <h5 class="card-title">Personal Details :</h5>
                                <ul class="list-unstyled" >
                                    <li class="h6" v-if="age">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gift fea icon-sm text-warning me-3"><polyline points="20 12 20 22 4 22 4 12"></polyline><rect x="2" y="7" width="20" height="5"></rect><line x1="12" y1="22" x2="12" y2="7"></line><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path><path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path></svg>
                                        <span class="text-muted">Age :</span> {{ age }} Years
                                    </li>
                                    <li class="h6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home fea icon-sm text-warning me-3"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                        <span class="text-muted">Address :</span> {{ candidate.profile[0].physical_address }}
                                    </li>
                                    <li class="h6" v-if="candidate.profile[0].city">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin fea icon-sm text-warning me-3"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg><span class="text-muted">City :</span> {{ candidate.profile[0].city.name }} 
                                    </li>
                                    <li class="h6" v-if="candidate.profile[0].country">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe fea icon-sm text-warning me-3" ><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                                        <span class="text-muted">Nationality :</span> {{ candidate.profile[0].country.name }} 
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" v-if="candidate.profile[0]">
                        <div class=" mt-4" v-if="candidate.freelancing_services_count">
                            <div class="d-flex">
                                <div class="flex-1">
                                    <h4>Services Summary</h4>
                                    <h6 class="mb-0">Services Completed: <span class="text-primary">{{ candidate.freelancing_services_count }} Services</span></h6>
                                    <h6 class="mb-0" v-if="rating">
                                        Average Ratings by Customer:  <star-rating v-model:rating="rating" :increment="0.1" :read-only="true" :animate="true"></star-rating>
                                    </h6>  
                                </div>
                            </div>
                        </div>
                        <div class="ms-lg-4">
                            <h4 class="text-primary" v-if="candidate.profile[0].description">About me :</h4>
                            <span class="text-muted" v-if="candidate.profile[0]" v-html="candidate.profile[0].description"></span> 
                            <div v-if="candidate.specialists.length">
                                <h5>Specialists</h5>
                                <div class="row">
                                    <div class="col-md-4" v-for="specialist in candidate.specialists" :key="specialist.id">
                                        <h6 class="text-muted item">{{ specialist.name }}</h6>
                                    </div>
                                </div>
                            </div>                       
                            <div v-if="candidate.skills.length">
                                <h5 class="mt-lg-5 mt-4">Skills :</h5>
                                <div class="row">
                                    <div class="col-md-4 progress-box" v-for="skill in candidate.skills" :key="skill.id">
                                        <h6 class="text-muted">{{ skill.name }}</h6>
                                        <div class="progress">
                                            <div v-if="skill.proficiency == 3" class="progress-bar position-relative bg-primary" style="width:90%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>90%</span>
                                                </div>
                                            </div>
                                            <div v-else-if="skill.proficiency == 2" class="progress-bar position-relative bg-primary" style="width:67%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>67%</span>
                                                </div>
                                            </div>
                                            <div v-else-if="skill.proficiency == 1" class="progress-bar position-relative bg-primary" style="width:40%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>40%</span>
                                                </div>
                                            </div>
                                            <div v-else class="progress-bar position-relative bg-primary" style="width:5%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>5%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="candidate.languages.length">
                                <h5 class="mt-lg-5 mt-4">Languages :</h5>
                                <div class="row">
                                    <div class="col-md-4 progress-box" v-for="language in candidate.languages" :key="language.id">
                                        <h6 class="text-muted">{{ language.name }}</h6>
                                        <div class="progress">
                                            <div v-if="language.proficiency == 3" class="progress-bar position-relative bg-primary" style="width:90%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>90%</span>
                                                </div>
                                            </div>
                                            <div v-else-if="language.proficiency == 2" class="progress-bar position-relative bg-primary" style="width:67%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>67%</span>
                                                </div>
                                            </div>
                                            <div v-else-if="language.proficiency == 1" class="progress-bar position-relative bg-primary" style="width:40%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>40%</span>
                                                </div>
                                            </div>
                                            <div v-else class="progress-bar position-relative bg-primary" style="width:5%;">
                                                <div class="progress-value d-block text-muted h6" >
                                                    <span>5%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div v-if="candidate.experiences.length">
                                <h4 class="mt-lg-5 mt-4">Experience :</h4>
                                <div class="row">
                                    <div class="col-lg-12 mt-4 pt-2" v-for="experience in candidate.experiences" :key="experience.id">
                                        <div class="d-flex">
                                            <div class="company-logo text-muted h6 me-3 text-center">
                                                <img src="@/assets/img/job/Codepen.svg" class="avatar avatar-md-sm mx-auto d-block mb-2" alt="">{{ new Date(experience.started_at).getFullYear() }} - {{ new Date(experience.ended_at).getFullYear() }}
                                            </div>
                                            <div class="flex-1">
                                                <h5 class="title mb-0">{{ experience.position }}</h5>
                                                <small class="text-muted company-university">{{ experience.company_name }} - {{ experience.country }}</small>
                                                <p class="text-muted mt-2 mb-0">{{ experience.description }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            
                            <br>
                            <div v-if="candidate.specialists.length">
                                <router-link :to="{ name: 'FreelancerService', params: {id:id}}"  class="btn btn-success">Request For Service<i class="mdi mdi-send"></i></router-link>
                            </div>
                            <div v-else class="alert alert-danger text-center">
                                <p>No Enough Information Yet</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0" v-if="!candidate.profile[0]">
                        <div class="alert alert-danger">
                            <h4 class="text-center">We Still have no infomation about this Freelancer</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getSingleFreelancer from '@/composable/getSingleFreelancer'
import { computed } from 'vue'
import Loading from '@/views/custom/Loading.vue'
import StarRating from 'vue-star-rating'
export default {
  components: { Navbar,Footer,Loading, StarRating },
    props: ['id'],
    setup(props){
        const { candidate, age, rating, company,  error,  load } =  getSingleFreelancer(props.id)
        load()
        
        return { candidate, age, rating, company,  error }
    },
     data(props){
        return {
             currentPage: 'Freelancers',
            previousPage: '',
            user: { roles:[0]},
            errors: '',
            token: localStorage.getItem('user_data'),
            form: {
                id: props.id
            },
            isLoading: true,
      }
    },
    methods: {
        isLoad() {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 1000);
    }
  },
  mounted () {
  this.isLoad()
}

}
</script>

<style>
.slider{
    background-image: url(../../assets/img/slider.jpg);
}
.item + .item:before {
  content: ", ";
}
</style>