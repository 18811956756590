<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Personal Information</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Gender</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.sex" id="" class="form-control">
                                                        <option value="">Select Your Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    <div v-if="errors.sex" class="text-small text-danger">{{ errors.sex[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Martial Status</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.martial_status" id="" class="form-control">
                                                        <option value="">Select Your Status</option>
                                                        <option value="Single">Single</option>
                                                        <option value="Married">Married</option>
                                                        <option value="Unspecified">Unspecified</option>
                                                    </select>
                                                    <div v-if="errors.martial_status" class="text-small text-danger">{{ errors.martial_status[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Date Of Birth</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.birth_date" type="date" class="form-control" >
                                                    <div v-if="errors.birth_date" class="text-small text-danger">{{ errors.birth_date[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Residence Country</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.nationality" class="form-control" @change="getCities()">
                                                        <option value="">Select Your Nationality</option>
                                                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                    </select>
                                                    <div v-if="errors.nationality" class="text-small text-danger">{{ errors.nationality[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">City</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.city_id">
                                                        <option value="">Select City</option>
                                                        <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                                                    </select>
                                                    <div v-if="errors.city_id" class="text-small text-danger">{{ errors.city_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Visa</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.visa" class="form-control" >
                                                        <option value="No Visa">No Visa</option>
                                                        <option value="Residency Visa(Non Transferble)">Residency Visa(Non Transferble)</option>
                                                        <option value="Residency Visa(Transferble)">Residency Visa(Transferble)</option>
                                                        <option value="Student Visa">Student Visa</option>
                                                        <option value="Transit Visa">Transit Visa</option>
                                                        <option value="Visit Visa">Visit Visa</option>
                                                    </select>
                                                </div>
                                                <div v-if="errors.visa" class="text-small text-danger">{{ errors.visa[0] }}</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mb-3">
                                                <label class="form-label">Number of Dependants</label>
                                                <div class="form-icon position-relative">
                                                    <input v-model="form.dependants" type="number" class="form-control" placeholder="" min="0">
                                                    <div v-if="errors.dependants" class="text-small text-danger">{{ errors.dependants[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <input type="submit" id="submit" class="btn btn-primary" value="Save Changes" v-else>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
  	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'

import qs from 'qs'
export default {
    components: { Footer,NavSeeker,Loading },
    data(){
        return {
            form: {
               sex:'',birth_date: '',nationality: '', addition_nationality: '', city_id: '', visa: 'No Visa', dependants: '', martial_status: '',website: ''
            },
            cities:{},
            countries: {roles: [0]},
            user: {}, 
            token: localStorage.getItem('user_data'),           
            errors: [],
            submitting: false,  
            isLoading: true,
        }
    },
    created(){
        document.title = `Edit Basic Info - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.getCountries()

    },
    methods: {
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            let id = this.$route.params.id
            axios.post('/profile/editProfile/' + id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'Profile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getCities(){
        axios.get('/cities/' + this.form.nationality).then(response => {
            this.cities = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getUser(){ 
          let id = this.$route.params.id
          axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user 
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/profile/show/' + id).then(response => {
            this.form = response.data
        })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      }
       
    }
    
}
</script>

<style>

</style>