<template>
    <div class="sidebar sticky-bar p-4 rounded shadow">
        <div class="widget mt-4 pt-2">
            <div class="row">
                <div class="col-12 mt-4 pt-2" v-if="job">
                    <router-link :to="{ name: 'CompanyApplicationProgress', params:{ id:job}}"  class="btn accounts rounded d-block shadow text-center py-3">
                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                        <h6 class="title text-dark h6 my-0">Progress</h6>
                    </router-link>
                </div>
                <div class="col-12 mt-4 pt-2" v-if="job">
                    <router-link :to="{ name: 'CompanyApplicationShortlisted', params:{ id:job}}"  class="btn accounts rounded d-block shadow text-center py-3">
                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                        <h6 class="title text-dark h6 my-0">Shortlisted</h6>
                    </router-link>
                </div>
                <div class="col-12 mt-4 pt-2" v-if="job">
                    <router-link :to="{ name: 'CompanyFirstResults', params:{ id:job}}"  class="btn accounts rounded d-block shadow text-center py-3">
                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                        <h6 class="title text-dark h6 my-0">First Results</h6>
                    </router-link>
                </div>
                <div class="col-12 mt-4 pt-2" v-if="job">
                    <router-link :to="{ name: 'CompanySecondShortlisted', params:{ id:job}}"  class="btn accounts rounded d-block shadow text-center py-3">
                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                        <h6 class="title text-dark h6 my-0">Second Shortlisted</h6>
                    </router-link>
                </div>
                <div class="col-12 mt-4 pt-2" v-if="job">
                    <router-link :to="{ name: 'CompanySecondResults', params:{ id:job}}"  class="btn accounts rounded d-block shadow text-center py-3">
                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                        <h6 class="title text-dark h6 my-0">Second Results</h6>
                    </router-link>
                </div>
                <div class="col-12 mt-4 pt-2" v-if="job">
                    <router-link :to="{ name: 'CompanyPassed', params:{ id:job}}"  class="btn accounts rounded d-block shadow text-center py-3">
                        <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                        <h6 class="title text-dark h6 my-0">Passed</h6>
                    </router-link>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import Nav from '@/components/company/Nav.vue'
import Tabs from '@/components/company/Tabs.vue'
export default {
     components: { Nav,Tabs },
     props: ['name','applicants','job','progress'],
     data(){
      return {
          profile: []
      }
     },
      methods: {
        companyProgress(){
            axios.get('/company/progress/' + this.progress.id).then(response => {
                this.profile = response.data
            }).catch(errors => {
            })
        }
     },
     created(){
      this.companyProgress()  
     }
    
}
</script>

<style>

</style>