<template>
    <h5 class="text-center">Company Basic Information</h5>
    <div class="d-flex flex-row-reverse" v-if="user_info">
        <router-link class="btn btn-primary end-0" :to="{ name : 'EditCompanyProfile', params:{ id:user_info.id }}" >Edit Basic Info</router-link>
    </div>
    <div class="d-flex flex-row-reverse" v-else>
        <router-link class="btn btn-primary end-0" :to="{ name : 'AddCompanyProfile' }">Add Basic Info</router-link>
    </div>
    <div v-if="user_info">
        <table class="table table-borderless">    
            <tbody>
                <tr>
                    <td><span class="fw-bold">Company Name:</span> {{ user_info.name }}</td>
                    <td><span class="fw-bold">Short Name:</span> {{ user_info.short_name }}</td>
                    <td><span class="fw-bold">Year Founded:</span> {{ user_info.founded_at }}</td>
                    <td><span class="fw-bold">Company Size:</span> {{ user_info.size }}</td>
                </tr>
            </tbody>
        </table>
        <div><span class="fw-bold">Vision:</span> {{ user_info.vision }}</div>
        <div><span class="fw-bold">Mission:</span> {{ user_info.mission }}</div>
        <div><span class="fw-bold">Description:</span> {{ user_info.description }}</div>
    </div>
    <div class="alert alert-danger" v-else>
        <h6 class="text-center">Nothing to Show!</h6>
    </div>
    <hr>
    <h5 class="text-center">Contacts Info</h5>
    <div v-if="user_info">
        <div class="d-flex flex-row-reverse" >
            <router-link class="btn btn-primary end-0" :to="{ name : 'EditCompanyContact', params:{ id:user_info.id } }">Edit Contact Info</router-link>
        </div>
        <div v-if="user_info.phone ">
            <table class="table table-borderless" >    
                <tbody>
                    <tr>
                        <td><span class="fw-bold">Email:</span> {{ user_info.email }}</td>
                        <td><span class="fw-bold">Phone:</span> {{ user_info.phone }}</td>
                        
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Website:</span> {{ user_info.web_address }}</td>
                        <td><span class="fw-bold">Physical Address:</span> {{ user_info.local_address1 }}</td>
                        
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Local Andress 2:</span> {{ user_info.local_address2 }}</td>
                        <td v-if="user_info.city"><span class="fw-bold">City:</span> {{ user_info.city.name }}</td>
                        <td v-if="user_info.country_id"><span class="fw-bold">Country:</span> {{ user_info.country.name }}</td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
    <div class="alert alert-danger" v-else>
        <h6 class="text-center">Nothing to Show!</h6>
    </div>
    <div v-if="user_info">
        <div class="d-flex flex-row-reverse" >
            <router-link class="btn btn-primary end-0" :to="{ name : 'CompanySocial', params:{ id:user_info.id } }">Manage Social Media</router-link>
        </div>
        <div>
            <table class="table table-borderless" >    
                <tbody>
                    <tr>
                        <td><span class="fw-bold">Facebook:</span>
                            <span v-if="user_info.facebook_url">
                                <a :href="user_info.facebook_url" class="text-dark" target="blank"> Click Here To Visit</a>
                            </span><span v-else>Not Set</span>
                         </td>
                        <td><span class="fw-bold">Twitter:</span>
                            <span v-if="user_info.tweeter_url">
                                <a :href="user_info.tweeter_url" class="text-dark" target="blank"> Click Here To Visit</a>
                            </span><span v-else>Not Set</span>
                        </td>
                        
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Instagram:</span>
                        <span v-if="user_info.instagram_url">
                            <a :href="user_info.instagram_url" class="text-dark" target="blank"> Click Here To Visit</a>
                        </span><span v-else>Not Set</span>
                        </td>
                        <td><span class="fw-bold">LinkedIn</span>
                            <span v-if="user_info.linkedin_url">
                                <a :href="user_info.linkedin_url" class="text-dark" target="blank"> Click Here To Visit</a>
                            </span><span v-else>Not Set</span>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
</template>

<script>
export default {
     props : ['user_info','user'],
}
</script>

<style>

</style>