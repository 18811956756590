<template>
    <div class="container mt-100">
        <div class="row">
            <h2 class="text-center text-primary">SEARCH BY CITIES</h2>
            <div v-for="city in cities" :key="city.id" class="col-lg-4 col-md-6 col-12">
                <router-link :to="{ name: 'City', params:{ id:city.id }}">
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
                        <div class="flex-1 ms-3">
                            <h4 class="title mb-0 text-dark"><router-link :to="{ name: 'City', params:{ id:city.id }}" class="text-dark">{{ city.name && city.name.length < 16 ? city.name : city.name.substring(0,12)+".."  }},{{ city.country.name && city.country.name.length < 10 ? city.country.name : city.country.name.substring(0,8)+".."  }}</router-link></h4>
                            <p class="text-primary mb-0" v-if="city.jobs_count">
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="feather feather-link fea icon-sm text-warning me-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                {{ city.jobs_count }} Jobs
                            </p> 
                            <p class="text-primary mb-0" v-else>No Jobs Available</p> 
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import getCities from '@/composable/getCities'
export default {
  setup(){
      const { cities, country, error, errorCountry, loadCities } = getCities()
      loadCities()

      return { cities, country, errorCountry, error, loadCities }
  }
}
</script>