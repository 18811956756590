<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">

                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h4 class="text-center">My Account</h4>
                                <div class="alert alert-success" v-if="success">
                                    <p class="text-center">{{ success }}</p>
                                </div>
                                <div class="alert alert-danger" v-if="imageErrors">
                                    <p class="text-center">{{ imageErrors }}</p>
                                </div>
                                <div class="alert alert-danger" v-else-if="errors[0]">
                                    <p class="text-center">{{ errors[0] }}</p>
                                </div>
                                <div class="alert alert-danger" v-else-if="errors">
                                    <p class="text-center">{{ errors }}</p>
                                </div>
                                <div class="mt-3 justify-content-center d-sm-flex">
                                    <div>
                                        <div v-if="form.photo">
                                            <img :src="form.photo" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                        <div v-else-if="user.avatar">
                                            <img v-bind:src="'https://api.vipawaworks.com/' + user.avatar" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                        <div v-else>
                                            <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                         <button class=" btn text-center text-success mt-2 border-0 bg-light collapsed" type="button" v-on:click="editable = !editable">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                                        </button>
                                        <form @submit.prevent="profileUpload" v-if="editable" id="hide" enctype="multipart/form-data" method="post">
                                            <div class="custom-file">
                                                <input class="custom-file-input input-sm" @change="imageSelected" type="file"  id="customFile">
                                            </div>
                                            <button class="btn btn-outline-success" disabled v-if="submitting">
                                                    <span class="label">{{ value }}</span>
                                                </button>
                                            <button type="submit" class="btn btn-outline-success mt-2 ms-2" v-else>Upload</button>
                                        </form>
                                    </div>
                                </div>
                                <hr>
                                <div>
                                    <ResumeInfo :user="user" :user_info="user_info" />
                                </div>
                                    
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
  	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import ResumeInfo from '@/components/user/ResumeInfo.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { 
      Footer,NavSeeker, ResumeInfo,Loading
    },
    data(){
      return {
        drawer: true,
        currentUser: {},
        user: {roles: [0]},
        user_info: {
        profile: [0], 
        },
        isLoading: true,
        token: localStorage.getItem('user_data'),
        editable: false,
        success: false,
        errors: false,
        imageErrors: false,
        submitting: false,  
        form:{
              photo: null
          },
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/profile/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
    },
      imageSelected(event){
        let file = event.target.files[0]
        if (file.size > 1548770) {
            this.imageErrors = 'Image Should be less than 1MB'
        }else{
            this.errors = false
            let reader = new FileReader()
            reader.onload = event => {
                this.form.photo = event.target.result
            }
            reader.readAsDataURL(file)
        }
    },
    profileUpload(){
        this.submitting = true
        this.value = 'Uploading...'
        axios.post('/profile/upload/profile/' + this.user.id, this.form).then(response => {
            this.$refs.clearForm.reset(),
            this.user.avatar = response.data.avatar
            this.success = 'Profile Picture Changed'
            editable = !editable
            this.submitting = false
        }).catch((error) => {
            if (!error.response) {
                this.editable = !this.editable
                return this.success = 'Profile Picture Changed'
            }
            this.errors = error.response.data.errors
            this.submitting = false
        }) 
    }
  },
  created(){
      document.title = `My Profile - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()      
  }
}
</script>

<style>

</style>