<template>
    <Navbar /> 
    <section class="section">
        <div class="container">
            <div class="container" v-if="!isLoading">
                <div v-if="error">
                    <div class="alert alert-danger text-center">{{ error }}</div>
                </div>
                <div class="row">
                    <br>
                    <h2 class="text-center text-primary">Jobs in {{ city.name }}, {{ city.country.name }}</h2>
                    <div class="col-12">
                        <div class="border-bottom pb-4">
                        <div class="row">
                            <div class="col">
                                <input type="text" v-model="search"  class="form-control banner-search"  name="keyword" id="joblist" placeholder="Search Keyword" autocomplete="off" aria-label="Skills, Designations, Company Keyword">
                            </div>
                        </div>
                        <br>
                        </div>
                    </div>
                    <div v-if="matchingJobs.length">
                        <JobList :matchingJobs="matchingJobs" />
                    </div>
                    <div class="justify-content-center" v-else>
                        <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                        <h3 class="text-center">No Job Found</h3>
                        <p class="text-center">We are Sorry We couldnot find what you are looking for. It maybe the job you are looking for is not found in this city</p>
                        <div class="col-md-12 text-center">  
                            <button @click="$router.go(-1)" class="btn btn-outline-primary mt-4">Go Back</button>
                            <router-link class="btn btn-primary mt-4 ms-2" :to="{ name: 'Home'}">Go To Home</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-else>
                <Loading />
            </div>
            
        </div>
    </section>
    <Footer /> 
</template>

<script>
import jobCity from '@/composable/jobCity'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Banner from '@/components/Banner.vue'
import JobList from '@/components/JobList.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
props: ['id'],
  name: 'Jobs',
  components: {
    Navbar,Footer,JobList,Loading
  },
  data(){
      return {
          currentPage: 'Jobs',
          previousPage: '',
          isLoading: true,
      }
  },
  setup(props){
      const { jobs, city, search, matchingJobs, error, errorCity, loadCity } = jobCity(props.id)
      loadCity()
      return { jobs, city, search, matchingJobs, error, errorCity, loadCity }
  },
  methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 2000);
   }
  },
  created () {
  this.isLoad()
}
}
</script>

<style scoped>

</style>

