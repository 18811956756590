<template>
    <section class="bg-half bg-light d-table w-100 banner">
        <div class="bg-overlay"> </div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12 text-center">
                        <div class="page-next-level">
                            <div class="card bg-transparent">
                                <h4 class="title text-white">{{ currentPage }} </h4>
                            </div>
                            <div class="page-next">
                                <nav aria-label="breadcrumb" class="d-inline-block">
                                    <ul class="breadcrumb bg-white rounded shadow mb-0">
                                        <li class="breadcrumb-item"><router-link :to="{ name : 'Home' }">Home</router-link></li>
                                        <li v-if="!!previousPage"><router-link v-bind:to="'/' + previousPage">Jobs</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">{{ currentPage }}</li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </section>
</template>
<script>
export default {
    props: ['currentPage', 'previousPage', ]
}
</script>
<style scoped>
.banner {
  background-image: url(../assets/img/slider.jpg);
}
.card{
	border: none
}
.page-next-level .page-next li::after {
	content: "";
	font-size: '';
	color: '';
	font-family: '';
}
</style>