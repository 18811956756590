  <template>
  <NavEmployer :user="user" />
  <div @submit-form="getUser()">
    <section class="section mt-60">
        <div class="container mt-lg-3">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-12">
                    <div class="card border-0 rounded shadow">
                        <div class="card-body">
                            <h5 class="text-md-start text-center">Personal Information</h5>
                            <form ref="clearForm" @submit.prevent="editPersonal">
                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">First Name</label>
                                            <div class="form-icon position-relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user fea icon-sm icons"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                                <input type="text" class="form-control ps-5" v-model="form.fname"  >
                                                <div v-if="errors.fname" class="text-small text-danger">{{ errors.fname[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">Second Name</label>
                                            <div class="form-icon position-relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                                <input type="text" class="form-control ps-5" v-model="form.sname" >
                                                <div v-if="errors.sname" class="text-small text-danger">{{ errors.sname[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">Last Name</label>
                                            <div class="form-icon position-relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                                <input type="text" class="form-control ps-5" v-model="form.lname" >
                                                <div v-if="errors.lname" class="text-small text-danger">{{ errors.lname[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">Email</label>
                                            <div class="form-icon position-relative">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-sm icons"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                                <input type="email" class="form-control ps-5" v-model="form.email" >
                                                <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary" disabled v-if="submitting">
                                        <span class="label">{{ value }}</span>
                                    </button>
                                    <button class="btn btn-primary" v-else>Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>  
</div>
  <Footer />
    
</template>
p
<script>
import Profile from '@/components/company/Profile.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import qs from 'qs'
export default {
    components: { Profile,Sidebar,NavEmployer,Footer },
    data(){
        return {
            values: [],
            form: {
               email: '', fname: '', sname: '', lname: '', phone : '', description: '',
            },
            user: {}, 
            user_info: { companies:[0]},
            token: localStorage.getItem('user_data'),           
            errors: [],
            submitting: false,
        }
    },
    created(){
        document.title = `Edit Profile - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            this.form = this.currentUser.user
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
    },
    methods: {
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/profile/edit/' + this.user.id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CompanyMyProfile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    }
    

}
</script>

<style>

</style>