<template>
<FreelancerNav :user="user"/>
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <div class="alert alert-success" v-if="success">
                                <p class="text-center">{{ success }}</p>
                            </div>
                            <div class="alert alert-danger" v-if="errors[0]">
                                <p class="text-center">{{ errors }}</p>
                            </div>
                            <div v-if="offer">
                                <h4 class="mb-0 text-center text-primary">Request Received from {{ offer.company.name }}</h4>
                                <div>
                                    <hr>
                                    <p class="mb-0">
                                        Hellow Mr/Mrs {{user.fname}} {{ user.lname }}, I hope you re doing okay, We <strong>({{ offer.company.name }})</strong> 
                                        <span v-if="offer.company.local_address1">Located at {{ offer.company.local_address1 }}, {{ offer.company.city}} in {{ offer.company.country_id}}</span>
                                        after reviewing your profile, we are interested in your service for <span v-if="offer.currency == 1">TSH </span> <span v-else-if="offer.currency == 2">USD </span><strong>{{ Number(offer.amount).toLocaleString() }}</strong> 
                                        <span v-if="offer.duration == 1"> per Day</span><span v-else-if="offer.duration == 2"> Weekly</span><span v-else-if="offer.duration == 3"> Monthly</span><span v-else-if="offer.duration == 4"> per Service</span>. 
                                    </p>
                                    <p class="mb-0">{{ offer.details }}</p>
                                </div>
                                <hr>
                                <div class="justify-content-center">
                                    <h5 class="text-center text-primary">Actions</h5>
                                    <div v-if="offer.rejected != 1">
                                        <div class="d-grid gap-2 d-md-flex d-md-block" v-if="offer.accepted != 1">
                                            <div >
                                                <button type="button"  @click="acceptOffer()" class="btn btn-success">Accept Offer</button>
                                            </div>
                                            <div>
                                                <button class="btn btn-danger" v-on:click="editable = !editable">Reject Offer</button>
                                            </div>
                                        </div> 
                                        <div class="alert alert-success" v-else>
                                            <h4 class="text-center">You Have Accepted This Offer</h4>
                                        </div>
                                    </div>
                                        
                                    <div class="alert alert-danger" v-else>
                                        <h4 class="text-center">You Have Rejected This Offer</h4>
                                    </div>
                                    <br>
                                    <div v-if="editable">
                                        <form @submit.prevent="rejectOffer" enctype="multipart/form-data" method="post">
                                            <div class="col-12">
                                                <label class="form-label">Reason For Rejection</label>
                                                <textarea v-model="form.rejection_reason" rows="4" class="form-control" placeholder="Please tell us Why you Rejected Our offer, It will help us in the future"></textarea>
                                                <div v-if="errors.rejection_reason" class="text-small text-danger">{{ errors.rejection_reason[0] }}</div>
                                            </div>
                                        <button type="submit" class="btn btn-outline-danger mt-2 ms-2" >Submit Rejection</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="alert alert-danger" v-else>
                                <h5 class="text-center">No Request Received Yet</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import RequestInfo from '@/components/freelancer/RequestInfo.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: { Footer,FreelancerNav,RequestInfo,Loading, },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          editable: false,
          form:{
              rejection_reason:''
          },
          offer: {
              company: []
          },
          token: localStorage.getItem('user_data'),
          isLoading: true,
          success: false,
          errors: [],
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
            let id = this.$route.params.id
            axios.get('/freelancer/request/details/' + id).then(response => {
                this.offer = response.data
                }).catch(errors => {
                    if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login'})
            }
            })
            }).catch(errors => {
                this.errors = 'Something went wrong'
        }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
      },
      acceptOffer(){
          let id = this.$route.params.id
          axios.post('/freelancer/request/accept/' + id).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      rejectOffer(){
          let id = this.$route.params.id
          axios.post('/freelancer/request/reject/' + id, this.form).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
  },
  created(){
      document.title = `Request Details - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>

</style>