<template>

    <form class="login-form mt-4" @submit.prevent="loginUser">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3">
                    <div class="position-relative">
                        <input type="email" class="form-control" placeholder="Email Address" name="email" v-model="form.email">
                        <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="mb-3">
                    <div class="input-group mb-3">
                        <input :type="passwordFieldType" class="form-control " placeholder="Password" v-model="form.password">
                        <a @click="switchVisibility" class="input-group-text">
                            <div v-if="passwordIcon == true">
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                            </div>
                            <div v-else>
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
                            </div>
                        </a>  
                    </div>
                    <div v-if="errors.password" class="text-small text-danger">{{ errors.password[0] }}</div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="d-flex justify-content-between">
                    <div class="mb-3">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                        </div>
                    </div>
                    <p class="forgot-pass mb-0"><router-link :to="{ name: 'ForgotPassword' }" class="text-dark fw-bold">Forgot password ?</router-link></p>
                </div>
            </div>

            <div class="col-lg-12 mb-0">
                <div class="d-grid">
                    <button class="btn btn-primary" disabled v-if="submitting">
                        <span class="label">{{ value }}</span>
                    </button>
                    <button class="btn btn-primary" v-else>Sign in</button>
                </div>
            </div>
            <div class="col-12 text-center">
                <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account yet? </small> <router-link :to="{ name : 'Register', query: { redirect: $route.query.redirect } }" class="text-dark fw-bold">Sign Up Here</router-link></p>
            </div>
        </div>
    </form>
</template>

<script>

export default {
  components: {  },
    
    data(){
        return{
            alerts: false,
            values: [],
            passwordFieldType: "password",
            passwordIcon: true,
            form:{
                email: '',
                password: '',
                device_name: 'browser'
            },
            errors: {},
            submitting: false,
        }
    },
    methods:{
        loginUser(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/login',this.form).then(response =>{
                localStorage.setItem('user_data', response.data)
                this.alerts = true
                this.$router.push(this.$route.query.redirect || { name: 'Dashboard'})
            }).catch((errors) => {
                this.errors = errors.response.data.errors
                this.submitting = false
            })
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password"
            this.passwordIcon = this.passwordIcon === true ? false : true
        }
    }
    

}
 
</script>

<style>

.btn-fb{
    background-color: #1a77f2 !important;
    color: #ffffff !important;
}
.btn-google{
    background-color: #DB4437 !important;
    color: #ffffff !important;
}

</style>