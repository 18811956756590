  <template>
   <NavSeeker :user="user" />
    <div v-if="!isLoading">
    <section class="section mt-60">
        <div class="container mt-lg-3">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-12">
                    <div class="card border-0 rounded shadow">
                        <div class="card-body">
                            <h5 class="text-center">Add Education Background</h5>
                            <div class="alert alert-danger" v-if="errors[0]">
                                <h6 class="text-center">{{ errors }}</h6>
                            </div>
                            <form ref="clearForm" @submit.prevent="editPersonal">
                                <div class="row mt-4">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">Starting Date</label>
                                            <div class="form-icon position-relative">
                                                <input type="date" class="form-control" v-model="form.started_at" >
                                                <div class="form-text">Tip: Choose the first day of month if you cant remmeber the exactly date</div>
                                                <div v-if="errors.started_at" class="text-small text-danger">{{ errors.started_at[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">Still Studing</label>
                                            <div class="form-icon position-relative">
                                                <select v-model="form.studying" class="form-control" >
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="form.studying == 0">
                                        <div class="mb-3">
                                            <label class="form-label">Graduation Date</label>
                                            <div class="form-icon position-relative">
                                                <input type="date" class="form-control" v-model="form.ended_at" >
                                                <div class="form-text">Tip: Choose the first day of month if you cant remmeber the exactly date</div>
                                                <div v-if="errors.ended_at" class="text-small text-danger">{{ errors.ended_at[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label">Country</label>
                                            <div class="form-icon position-relative">
                                                <select v-model="form.country" class="form-control" >
                                                    <option value="">Select Institute Country</option>
                                                    <option :value="country.name" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                </select>
                                                <div v-if="errors.country" class="text-small text-danger">{{ errors.country[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">City</label>
                                            <div class="form-icon position-relative">
                                                <input type="text" class="form-control" v-model="form.city" >
                                                <div v-if="errors.city" class="text-small text-danger">{{ errors.city[0] }}</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label">Institute Name</label>
                                            <div class="form-icon position-relative">
                                                <input type="text" class="form-control" v-model="form.name" >
                                                <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label">Education Level</label>
                                            <div class="form-icon position-relative">
                                                <select v-model="form.level_id" class="form-control" >
                                                    <option value="">Select Education Level</option>
                                                    <option :value="edu.id" v-for="edu in education" :key="edu.id">{{ edu.name }}</option>
                                                </select>
                                                <div v-if="errors.level_id" class="text-small text-danger">{{ errors.level_id[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label">Facult Name</label>
                                            <div class="form-icon position-relative">
                                                <input type="text" class="form-control" v-model="form.faculty" >
                                                <div v-if="errors.faculty" class="text-small text-danger">{{ errors.faculty[0] }}</div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label">Division/GPA Scored (with points)</label>
                                            <div class="form-icon position-relative">
                                                <input type="text" class="form-control" v-model="form.marks" >
                                                <div v-if="errors.marks" class="text-small text-danger">{{ errors.marks[0] }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label class="form-label">Description</label>
                                            <div class="form-icon position-relative">
                                                <textarea v-model="form.description" name="comments" id="comments" rows="4" class="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="text-center">
                                    <button class="btn btn-primary" disabled v-if="submitting">
                                        <span class="label">{{ value }}</span>
                                    </button>
                                    <button class="btn btn-primary" v-else>Save Changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>  
    </div>
  <div v-else>
    <Loading />
   </div>
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
    components: { Footer,NavSeeker,Loading },
    data(){
        return {
            values: [],
            form: {
               started_at: '', ended_at: '', country: '', city : '', name: '',level_id: '',marks: '',description: '', studying:0
               
            },
            education: {},
            countries: {},
            user: {roles: [0]},
            token: localStorage.getItem('user_data'),
            errors: [],
            isLoading: true,
            submitting: false,
        }
    },
    methods: {
        getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/education/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'MyResume'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getEducation(){
        axios.get('/educationLevel').then(response => {
            this.education = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        }
    },
    created(){
        document.title = `Add Education - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()  
        this.getCountries()
        this.getEducation()
    }
    

}
</script>

<style>

</style>