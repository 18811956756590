<template>
    <div v-for="company in matchingCompanies" :key="company.id" class="col-lg-3 col-md-3 col-12 mt-4 pt-2">
        <router-link :to="{ name: 'CompanyDetails', params:{ id:company.id }}" class="justify-content-center">
            <div class="rounded shadow">
                <div class="row justify-content-center">
                    <div class="col-3">
                        <div v-if="company.logo != null">
                            <img v-bind:src="'https://api.vipawaworks.com/' + company.logo" class="avatar avatar avatar-md-sm  rounded-circle shadow" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar avatar-md-sm rounded-circle shadow" alt="">
                        </div>
                    </div>
                    <div class="col-9">
                        <h4 class="title mb-0 text-dark"><router-link :to="{ name: 'CompanyDetails', params:{ id:company.id }}" class="text-dark" v-if="company.name">{{ company.name && company.name.length < 15 ? company.name : company.name.substring(0,12)+".."   }}</router-link></h4>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p class="text-primary mb-0" v-if="company.jobs_count">
                                <svg width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="feather feather-link fea icon-sm text-warning me-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                {{ company.jobs_count }} Jobs
                            </p> 
                            <p class="text-primary mb-0" v-else>No active Jobs</p> 
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
    
</template>

<script>
export default {
props: ['matchingCompanies']
}
</script>

<style>

</style>