import { computed, ref } from'vue'

const getCompanies = () => {
    const search = ref('')
    const companies = ref({ logo:[]})
    const error = ref(null)

    const loadCompanies = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/companies')
            if (!data.ok){
                throw Error('no data available')
            }
            companies.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
    }
    const matchingCompanies = computed(() => {
        return companies.value.filter(function(companyName){
            return companyName.name.toLowerCase().indexOf(search.value.toLowerCase()) !== -1
        })
    })
    return { companies, error, search, matchingCompanies, loadCompanies }
  }

  export default getCompanies

