<template>
  <Navbar />
  <div class="home" v-if="!isLoading">
    <section class="bg-auth-home d-table w-100">
          <div class="container">
            <h2 class="card-title text-center">Keep going, {{ user.fname }}</h2> 
              <p class="text-center">You're halfway there...</p> 
              <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6">
                      <div class="card shadow rounded border-0">
                          <div class="card-body">
                            <p class="text-md-start text-center">Step 4 of 5</p>
                              <h5 class="text-md-start text-center">Tell us about your education.</h5>
                                <EducationForm :user="user.id" />
                          </div>
                      </div>
                  </div> 
                  <div class="col-lg-7 col-md-6">
                      <div class="me-lg-5">   
                          <img :src="require('@/assets/img/site/fresher.png')" class="img-fluid d-block mx-auto" alt="">
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
  <div class="home" v-else>
		<Loading />
	</div>
    	<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
import EducationForm from '@/components/user/steps/EducationForm.vue'
export default {
    components: {
   Navbar, Footer,Loading,EducationForm
  },
  data(){
      return {
        form: {
            started_at: '', ended_at: '', country: '', city : '', name: '',level_id: '',marks: '',description: '', studying:0
               
            },
        categories:{},
        countries:{},
        industries:{},
        user: {roles: [0]},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
      }
  },
   methods:{
     getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
        editPersonal(){
            axios.post('/education/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'StepPersonalInfo'})
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getEducation(){
        axios.get('/educationLevel').then(response => {
            this.education = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
  },
  created(){
      document.title = `Register Education - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getUser()  
      this.getCountries()
      this.getEducation()
    }

}
</script>

<style>

</style>