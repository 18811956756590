<template>
    <NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div v-if="user.fname">
                    <div class="row justify-content-center">
                        <div v-if="user_info.companies[0]" class="col-12">
                            <div class="text-center" v-if="user_info.companies[0].active == 0" >
                                <img src="@/assets/img/svg/campling.svg" height="200" class="rounded mx-auto d-block">
                                <h2>Your Company Is Domant</h2>
                                <p>Since we need to make sure that the entered details are correct and do not violate our terms and Conditions. At meantime you can continue <router-link  :to="{ name : 'CompanyProfile' }" class="text-primary">filling details</router-link> and learning more about our features.</p>
                                <p>We apologies for inconvinient, since there have been alot of untrusted people in internet, we may contact you inorder to verify during working hour.</p>
                                <p>If this takes more time please <router-link class="text-primary" :to="{ name : 'Contacts' }">Contact us</router-link></p>
                            </div>
                            <div v-else>
                                <div class="border-bottom pb-4">
                                    <div class="row align-items-center" v-if="user_info.companies[0]">
                                        <div v-if="user_info.companies[0].logo" class="col-lg-2 col-md-3 text-md-start text-center">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + user_info.companies[0].logo" class="avatar float-md-left avatar-medium rounded shadow me-md-4" alt="">
                                        </div>
                                        <div v-else class="col-lg-2 col-md-3 text-md-start text-center">
                                            <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar-large rounded shadow d-block mx-auto" alt="">
                                        </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row align-items-end">
                                            <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                                                <h3 class="title mb-0" v-if="user_info.companies[0]">{{ user_info.companies[0].name }} 
                                                    <span class="text-primary" v-if="profile.verified == 1">
                                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                                        </span>
                                                </h3>
                                                <h6><span class="text-primary">{{ user.fname }} {{ user.sname }} {{ user.lname }}</span></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <p class="text-muted mb-0">{{ user_info.companies[0].description }}</p>
                                </div>
                                <div class="border-bottom pb-4">
                                    <div class="row">
                                        <CompanyDetails  :user_info="user_info.companies[0]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="col-lg-8 col-12">
                            <div class="justify-content-center">
                                <img src="@/assets/img/svg/empty.svg" height="200" class="rounded mx-auto d-block">
                                <h4 class="text-center">Empty Company Profile</h4>
                                <p class="text-center">Get Started by Filling Up Your <router-link class="text-dark" :to="{ name : 'AddCompanyProfile' }">Company Info</router-link></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <br>
                        <Welcome />
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
   </div>
    <Footer /> 
</template>

<script>
import Profile from '@/components/company/Profile.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import CompanyDetails from '@/components/company/CompanyDetails.vue'
import Loading from '@/views/custom/Loading.vue'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Welcome from '@/components/company/Welcome.vue'
export default {
  components: { Footer, Profile, Sidebar, CompanyDetails,Loading,NavEmployer, Welcome },
  data(){
      return {
          drawer: true,
          currentUser: {},
          user: {},
          profile:{},
          user_info: { company: {}, roles: [0], companies:[0]},
          isLoading: true,
          token: localStorage.getItem('user_data')
      }
  },
 methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
            if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
  },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true 
      document.title = `Company Dashboard - Vipawaworks`  
  }
}
</script>

<style>
.item + .item:before {
  content: ", ";
}

</style>