<template>
    <div v-if="!isLoading">
        <div class="row" v-if="profile">
            <h4 class=" text-center">Welcome</h4>
                <p class="text-center text-muted">You were assigned By Your Company As Human Resource Manager. Press </p>
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <button @click="assignHRRole()" class="btn btn-primary"><i class="uil uil-envelope"></i> Proceed</button>
                    </div>
                </div>
                
        </div>
        <div class="row" v-else>
            <h4 class=" text-center">Who Are You</h4>
                <p class="text-center text-muted">Help Us understand You better. In which category here you belong</p>
            <div class="col-md-3" v-for="role in roles" :key="role.id">
                <form @submit.prevent="attachRole(role.id)">
                    
                        <div class="card categories overflow-hidden rounded shadow border-0" >
                            <button class="btn" type="submit">
                                <div v-if="role.image">
                                    <img v-bind:src="require('@/assets/img/svg/' + role.image)" class="img-fluid avatar avatar-large rounded-circle mx-auto d-block">   
                                </div>
                                <div v-else>
                                    <img v-bind:src="require('@/assets/img/svg/banned.svg')" class="img-fluid avatar avatar-large rounded-circle mx-auto d-block">
                                </div>
                            <div class="card-body">
                                <h5 class="text-center"><button type="submit" class="btn title  h6 text-primary">{{ role.name }}</button></h5>
                            </div>
                            </button>
                        </div>
                    
                        
                </form>
            </div>
        </div>
    </div>
    <div v-else>
        <Loading />
    </div>
</template>

<script>
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { Loading },
props: ['user','profile'],
 data(props){
      return {
          roles: {},
          role: {},
          isLoading: true,
      }
  },
methods:
 {
      getRoles(){
        axios.get('/roles/onRegister').then(response => {
            this.roles = response.data
        }).catch(errors => {
            this.errors = errors.response.data.errors
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
      attachRole(id){
          axios.post('/attachRole/' + id, this.user.id).then(response => {
              this.$router.push({ name: 'Dashboard'})
        }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
       assignHRRole(){
             axios.post('/company/user/assignHR/' + this.user.id, ).then(response => {
              this.success = response.data.success
              this.$router.push({ name: 'CompanyDashboard'})
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
       }
  },
  created(){
      this.getRoles()      
  }
}

</script>

<style>

</style>