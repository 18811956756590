<template>
    <Navbar />
<section class="bg-auth-home d-table w-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="me-lg-5">   
                    <h2>Hire Top Talent in The East Africa</h2>
                    <h5>Join 1000+ companies hiring on Vipawaworks</h5>
                    <p>Access the largest CV database in the East Africa, post your jobs, and find employees in Tanzania, Kenya, Uganda, Rwanda, Burundi. </p>

                    <h4>Why Choose Vipawaworks</h4>
                    <ul class="job-facts ">
                <li class="list-inline-item text-muted" >
                    <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    More than 10 thousands CVs
                </li><br>
                <li class="list-inline-item text-muted" >
                    <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    AI-powered recruitment technology, saving you time and effort!
                </li><br>
                <li class="list-inline-item text-muted">
                    <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    7 Million visits per month, giving your jobs maximum visibility.
                </li>
                <li class="list-inline-item text-muted">
                    <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    Dedicated customer support from our 12 regional offices.
                </li>
                <li class="list-inline-item text-muted">
                    <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    We speak Swahili, English, Chinese.
                </li>
                <li class="list-inline-item text-muted">
                    <svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                    7 Million visits per month, giving your jobs maximum visibility.
                </li>
            </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        <p class="text-md-start">Step 2 of <span class="text-primary">2</span></p>
                        <h3 class="card-title">You're Almost There!</h3> 

                        <CompanyRegister :user="user.id" />
                        </div>
                </div>
            </div> 
        </div>
    </div>
</section>
<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CompanyRegister from '@/components/company/CompanyRegister.vue'
export default {
  components: { Navbar, Footer,CompanyRegister },
   data(){
      return {
        user: {roles: [0]},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
      }
  },
   methods:{
     getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
  },
  created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getUser()  
    document.title = `Register Company - Vipawaworks`
    }

}
</script>

<style>

</style>