<template>
<FreelancerNav :user="user"/>
    <div @submit-form="getUser()">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-center">Specialists</h5>
                                <div class="alert alert-danger" v-if="errors[0]">
                                    <h6 class="text-center">{{ errors }}</h6>
                                </div>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4 justify-content-center">
                                        <div class="col-md-10 ">
                                            <div class="mb-3">
                                                <label class="form-label">Specialist Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.name" >
                                                    <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'

import qs from 'qs'
export default {
    components: { Footer,FreelancerNav, },
    data(){
        return {
            values: [],
            form: {
               name: '',
            },
            user: {},
            token: localStorage.getItem('user_data'),
            submitting: false,
            errors: []
        }
    },
    methods: {
        getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
      },
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/specialists/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'FreelancerSpecialists'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        }
    },
    created(){
        document.title = `Add Specialist - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()  
    }
    

}
</script>

<style>

</style>