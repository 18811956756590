<template>
<Navbar />
    <section class="section pb-0">
        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                    <div class="card shadow rounded border-0">
                        <div class="card-body py-5">
                            <h4 class="card-title text-center">Unsbscribe to Job Alert</h4>
                            <div class="custom-form mt-3">
                                <form @submit.prevent="jobAlert" ref="clearForm" name="myForm">
                                        <div class="alert alert-danger" v-if="errors[0]">
                                            <h6 class="text-center">{{ errors }}</h6>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="mb-3">
                                                    <label class="form-label">Confirm Your Email Address<span class="text-danger">*</span></label>
                                                    <div class="form-icon position-relative">
                                                        <input v-model="email" id="email" type="email" class="form-control " placeholder="Email :" required>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="d-grid">
                                                    <button type="submit" id="submit" name="send" class="btn btn-primary">Set Alert</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <p>We will not send you emails for job alert again after unsubscribing</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 order-1 order-md-2">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <img src="@/assets/img/png/alert.png"  height="500" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: {
        Navbar,Footer,
        Loading,
    },
    data(){
        return {
            errors: [],
            success: false,
            email: '',
            countries: {},
            categories: {},
            industries: {},
            errors: [],
        }
    },
    methods: {
        jobAlert(){
            axios.delete('/jobAlert/'+ this.email).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'Home'})
                this.success = response.data.success
                this.errors = false
            }).catch((error) => {
                this.errors = error.response.data.error
            })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        }, 
        getIndustries(){
            axios.get('/industries').then(response => {
                this.industries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getCategories(){
            axios.get('/categories').then(response => {
                this.categories = response.data
            }).catch(errors => {
                this.CountryErrors = errors.response.data.errors
            })
        },
    },
    created(){
        document.title = `Unsubscribe Job Alert - Vipawaworks`
        this.getCountries()
        this.getCategories()
        this.getIndustries()
    }
}
</script>

<style>

</style>