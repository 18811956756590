<template>

<div>
    <div class="col mt-4 pt-2" id="tables">
        <div class="component-wrapper rounded shadow">
            <div class="p-4">
                <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                            <thead>
                                <tr>
                                <th scope="col" class="border-bottom">Request Date</th>
                                <th scope="col" class="border-bottom">Offer Name</th>
                                <th scope="col" class="border-bottom">Offer Price</th>
                                <th scope="col" class="border-bottom">View Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="offer in offers" :key="offer.id">
                                    <td>
                                        {{ moment(new Date(offer.created_at)).format('DD/MM/YYYY')}}
                                    </td>
                                    <td>
                                        {{ offer.company.name }}
                                    </td>
                                    <td>
                                        {{ Number(offer.amount).toLocaleString() }}
                                        <span v-if="offer.duration == 1"> per Day</span>
                                        <span v-else-if="offer.duration == 2"> Weekly</span>
                                        <span v-else-if="offer.duration == 3"> Monthly</span>
                                        <span v-else-if="offer.duration == 4"> per Service</span>
                                    </td>
                                    <td>
                                       <router-link :to="{ name: 'FreelancerRequestDetails', params: {id: offer.id}}" class="text-primary">
                                        View Request
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                     
                </div>
            </div>
        </div>
    </div>
    
</div>
  
</template>

<script>
import moment from 'moment'
export default {
    props:['offers'],
created(){
        this.moment = moment
}
}
</script>

<style>

</style>