<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <div class="row projects-wrapper">
                                <ApplicationDetails :application="application" :job="job" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import ApplicationDetails from '@/components/user/ApplicationDetails.vue'
import Loading from '@/views/custom/Loading.vue'

export default {
  components: { Footer,NavSeeker,ApplicationDetails,Loading, },
    data(){
      return {
          drawer: true,
          currentUser: {},
          user: {roles: [0]},
          application: [],
          job: [],
          token: localStorage.getItem('user_data'),
          isLoading: true,
          
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            let id = this.$route.params.id
                axios.get('/candidate/application/show/' + id).then(response => {
                this.application = response.data
                    axios.get('/job/progress/' + this.application.job_id).then(response => {
                    this.job = response.data
                    }).catch(errors => {
                    })
                }).catch(errors => {
            })
            }).catch(errors => {
        })
        .finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
      },
  },
  created(){
      document.title = `Application Progress - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.isCreated = true   
  }
}
</script>

<style>

</style>