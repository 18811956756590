<template>
    <form ref="clearForm" @submit.prevent="targetJob">
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Job Name</label>
                    <div class="form-icon position-relative">
                        <input type="text" class="form-control" v-model="form.name">
                        <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Carrier Level</label>
                    <div class="form-icon position-relative">
                        <select class="form-control"  v-model="form.carrier_level">
                            <option value="1" >Entry Level</option>
                            <option value="2" >Student/Intership</option>
                            <option value="3" >Mid Carrier</option>
                            <option value="4" >Managment</option>
                            <option value="5" >Executive Director</option>
                            <option value="6" >Senior Executive Director(President CEO)</option>
                        </select>
                        <div v-if="errors.notice" class="text-small text-danger">{{ errors.carrier_level[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>Carrier Objective</label>
                    <div class="form-icon position-relative">
                        <textarea class="form-control" v-model="form.carrier_objective"></textarea>
                        <div v-if="errors.carrier_objective" class="text-small text-danger">{{ errors.carrier_objective[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Employment Status</label>
                    <div class="form-icon position-relative">
                        <select class="form-control"  v-model="form.status">
                            <option value="1" >Full time</option>
                            <option value="2" >Part time</option>
                        </select>
                        <div v-if="errors.status" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label>Employment Notice</label>
                    <div class="form-icon position-relative">
                        <select class="form-control"  v-model="form.notice">
                            <option value="1" >Immediately</option>
                            <option value="2" >One Month or Less</option>
                            <option value="3" >Three Months or Less</option>
                            <option value="4" >Six Month or Less</option>
                            <option value="5" >More than Six Months</option>
                            <option value="6" >More than One year</option>
                        </select>
                        <div v-if="errors.notice" class="text-small text-danger">{{ errors.notice[0] }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="mb-3">
                    <label>Job Category</label>
                    <div class="form-icon position-relative">
                        <select class="form-control"  v-model="form.category_id">
                            <option value="">Select Category</option>
                            <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                        </select>
                        <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="row justify-content-between">
            <div class="col-4">
                <router-link :to="{ name: 'StepExperience', query: { redirect: $route.query.redirect  }}" class="btn btn-secondary">Previous</router-link>
            </div>
            <div class="col-4">
                <button class="btn btn-primary" disabled v-if="submitting">
                    <span class="label">{{ value }}</span>
                </button>
                <button class="btn btn-primary" v-else>
                    Next
                </button>
            </div>
        </div>
    </form>
</template>

<script>
export default {
props:['user'],
  data(props){
      return {
        form: {
              name:'', carrier_level:1, category_id:'', status: 1, notice:1, carrier_objective:''
          },
        categories:{},
        countries:{},
        industries:{},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
        submitting: false,
        userId:props.user,
      }
  },
  methods:{
      targetJob(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/targetJob/createOrUpdate/' + this.userId , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'StepEducation', query: { redirect: this.$route.query.redirect  }})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
      getStepTarget(){
            axios.get('/targetJob/step/' + this.userId).then(response => {
                this.form = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
  },
      created(){
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getCategories()
      this.getStepTarget()
    }
}
</script>

<style>

</style>