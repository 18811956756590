import { ref } from 'vue'

const getSingleJob = (id) => {
    const value = ref(null)
    const country = ref(null)
    const company = ref(null)
    const minEducation = ref(null)
    const maxEducation = ref(null)
    const categoryJobs = ref(null)
    const industry = ref(null)
    const error = ref(null)
    const errorCountry = ref(null)
    const errorCompany = ref(null)
    const errorIndustry = ref(null)
    const errorMin = ref(null)
    const errorMax = ref(null)
    const errorJobs = ref(null)
    

    const load = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/job/details/' + id)
            if (!data.ok){
                throw Error('We are Sorry! We Could not Find Job You are Looking For')
            }
            value.value = await data.json()
            document.title = value.value.job.name +'- Vipawaworks'
        }
        catch (err){
            error.value = err.message
        }
        try {
            let minEdu = await fetch('https://api.vipawaworks.com/api/educationlevel/show/' + value.value.job.min_education)
            if (!minEdu.ok){
                throw Error('The Minimum Education does not exist')
            }
            minEducation.value = await minEdu.json()
        }
        catch (err){
            errorMin.value = err.message
        }
        try {
            let maxEdu = await fetch('https://api.vipawaworks.com/api/educationlevel/show/' + value.value.job.max_education)
            if (!maxEdu.ok){
                throw Error('The Maximum Education does not exist')
            }
            maxEducation.value = await maxEdu.json()
        }
        catch (err){
            errorMax.value = err.message
        }
        try {
            let jobs = await fetch('https://api.vipawaworks.com/api/categories/jobs/' + value.value.job.category_id)
            if (!jobs.ok){
                throw Error('No Jobs found in this category')
            }
            categoryJobs.value = await jobs.json()
        }
        catch (err){
            errorJobs.value = err.message
        }
        
    }
    return { value, categoryJobs, country, company, minEducation, maxEducation, industry, error, errorCountry, errorCompany, errorMin, errorMax,errorIndustry,errorJobs, load }
  }

  export default getSingleJob