<template>
    <h5 class="text-center">Personal Information</h5>
    <div class="d-flex flex-row-reverse">
        <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerEditProfile' }">Edit Basic Info</router-link>
    </div>
    <div v-if="user">
        <table class="table table-borderless">    
            <tbody>
                <tr>
                    <td><span class="fw-bold">First Name:</span> {{ user.fname }}</td>
                    <td><span class="fw-bold">Second Name:</span> {{ user.sname }}</td>
                    <td><span class="fw-bold">Last Name:</span> {{ user.lname }}</td>
                    <td><span class="fw-bold">Email:</span> {{ user.email }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="alert alert-danger" v-else>
        <h6 class="text-center">Nothing to Show!</h6>
    </div>
    <hr>
    <h5 class="text-center">Basic Information</h5>
     <div class="alert alert-danger" v-if="!user_info.profile[0]" >
            <div class="d-flex flex-row-reverse">
                <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerBasicProfile' }">Add Basic Info</router-link>
            </div>
            <h6 class="text-center">You Have Not Added </h6>
        </div>
   <div v-if="user_info.profile[0]">    
       <div v-if="user_info.profile[0]">
            <div class="d-flex flex-row-reverse">
                <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerEditBasicProfile' , params: {id:user_info.profile[0].id}}">Edit Basic Info</router-link>
            </div>
            <table class="table table-borderless">    
                <tbody>
                    <tr>
                        <td><span class="fw-bold">Full Name:</span> {{ user_info.fname }} {{ user_info.profile[0].sname }} {{ user_info.lname }}</td>
                        <td><span class="fw-bold">Age:</span> {{ age }} Years</td>
                        <td><span class="fw-bold">Gender:</span> {{ user_info.profile[0].sex }}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Nationality:</span> {{ user_info.profile[0].country.name }}</td>

                        <td v-if="user_info.profile[0].city"><span class="fw-bold">City:</span>{{ user_info.profile[0].city.name }}</td>
                    </tr>
                    <tr>
                        <td><span class="fw-bold">Visa Status:</span> {{ user_info.profile[0].visa }}</td>
                        <td><span class="fw-bold">Martial Status:</span> {{ user_info.profile[0].martial_status }}</td>
                        <td><span class="fw-bold">Number Of Dependants:</span>{{ user_info.profile[0].dependants }}</td>
                    </tr>
                   <hr>
                    <tr> 
                        <td><span class="fw-bold">About Me:</span><span v-html="user_info.profile[0].description"></span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="alert alert-danger" v-else>
            <div class="d-flex flex-row-reverse">
                <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerBasicProfile' }">Add Basic Info</router-link>
            </div>
            <h6 class="text-center">You Have Not Added </h6>
        </div>
        <hr>
        <h5 class="text-center">Contacts Info</h5>
        
        <div v-if="user_info.profile[0].mobile_phone">
            <div class="d-flex flex-row-reverse">
                <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerEditContactProfile' , params: {id:user_info.profile[0].id}}">Edit Contact Info</router-link>
            </div>
            <div >
                <table class="table table-borderless" >    
                    <tbody>
                        <tr>
                            <td><span class="fw-bold">Email:</span> {{ user_info.email }}</td>
                            <td><span class="fw-bold">Phone:</span> {{ user_info.profile[0].mobile_phone }}</td>
                            <td><span class="fw-bold">Website:</span> {{ user_info.profile[0].website }}</td>
                        </tr>
                        <tr>
                            <td><span class="fw-bold">Physical Address:</span> {{ user_info.profile[0].physical_address }}</td>
                            <td  v-if="user_info.profile[0].city"><span class="fw-bold">City:</span> {{ user_info.profile[0].city.name }}</td>
                            <td><span class="fw-bold">Country:</span> {{ user_info.profile[0].country.name }}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
       
    
        <div class="alert alert-danger" v-else>
            <div class="d-flex flex-row-reverse">
                <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerContactProfile' }">Add Contact Info</router-link>
            </div>
            <h6 class="text-center">Nothing to Show!</h6>
        </div>
    </div> 
    
</template>

<script>
export default {
     props : ['user_info','user'],
     data(){
      return {
          profile: [],
          age:'',
          token: localStorage.getItem('user_data'),
        }
    },
    methods:{
        getAge(){
            axios.get('/profile/getAge/' + this.user.id).then(response => {
                this.age = response.data
            }).catch(errors => {
            })
        },
    },
      created(){
      this.getAge()  
  }
}
</script>

<style>

</style>