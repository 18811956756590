<template>
    <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-center">Preferred Country</h5>
                                <div class="alert alert-danger" v-if="errors[0]">
                                    <p class="text-center">{{ errors }}</p>
                                </div>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4 justify-content-center">
                                        <div class="col-md-10 ">
                                            <div class="mb-3">
                                                <label class="form-label">Country Name</label>
                                                <div class="form-icon position-relative">
                                                    <select v-model="form.country_id" class="form-control" >
                                                        <option value="">Select Country</option>
                                                        <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                    </select>
                                                    <div v-if="errors.country_id" class="text-small text-danger">{{ errors.country_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-sm-6">
                                            <input type="submit" id="submit" class="btn btn-primary" value="Save Changes">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
      </div>
    <div v-else>
       <Loading />
   </div>
    	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'

import qs from 'qs'
export default {
    components: { Footer,NavSeeker,Loading },
    data(){
        return {
            values: [],
            form: {
               country_id: '',
            },
            countries: [],
            user: {roles: [0]},
            token: localStorage.getItem('user_data'),
            errors: [],
           isLoading: true,
        }
    },
    methods: {
        getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
        editPersonal(){
            axios.post('/preferredCountries/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CandidateJob'})
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
        getCountries(){
        axios.get('/countries').then(response => {
            this.countries = response.data
        }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
    },
    created(){
        document.title = `Add Preference Country - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser() 
        this.getCountries() 
    }
    

}
</script>

<style>

</style>