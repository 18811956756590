import { computed, ref } from'vue'

const getBlogs = () => {
    const blogs = ref([])
    const error = ref(null)

    const loadBlogs = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/blogs/all')
            if (!data.ok){
                throw Error('no data available')
            }
            blogs.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
    }
    return { blogs, error, loadBlogs }
  }

  export default getBlogs
