<template>
    <div >
         <div class="d-flex flex-row-reverse" v-if="editable == 1">
            <router-link class="btn text-primary end-0" :to="{ name : 'Profile' }">
               <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
            </router-link>
         </div>
       <h6>
            <strong>Full Name: </strong> <span >{{ user_info.fname }} {{ user_info.sname }} {{ user_info.lname }}</span>
        </h6>
        <h6>
            <strong>Age:</strong> <span>{{ age }} Years</span>
        </h6>
        <h6>
           <strong>Gender</strong> <span>{{ user_info.profile[0].sex }} </span> 
        </h6>
        <h6 v-if="user_info.profile[0].country">
           <strong>Nationality: </strong> <span>{{ user_info.profile[0].country.name }} </span> 
        </h6>
        <h6 v-if="user_info.profile[0].city">
           <strong>City:</strong> <span>{{ user_info.profile[0].city.name }}</span> 
        </h6>
        <h6>
           <strong>Visa Status: </strong> <span>{{ user_info.profile[0].visa }}</span>  
        </h6>
        <h6>
           <strong>Martial Status: </strong> <span>{{ user_info.profile[0].martial_status }}</span> 
        </h6> 
        <h6>
            <strong>Number Of Dependants: </strong> <span>{{ user_info.profile[0].dependants }}</span>
        </h6>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['user_info','editable'],
     data(){
      return {
          profile: [],
          age: {},
          token: localStorage.getItem('user_data'),
        }
    },
    methods:{
        getAge(){
            axios.get('/profile/getAge/' + this.user_info.id).then(response => {
                this.age = response.data
            }).catch(errors => {
            })
        },
    },
      created(){
      this.getAge()  
       this.moment = moment
    }
}
</script>

<style>

</style>