<template>
<Navbar />
    <section class="section">
        <br>
        <div class="container">
            <h2 class="text-center text-primary">How can we help you?</h2>
            <form>
                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search What You Need">
                        </div>
                    </div>
                </div>
            </form>					
            <div id="grid" class="row shuffle justify-content-center" >
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item shuffle-item shuffle-item--visible" >
                    <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0 text-center">
                            <router-link :to="{ name : 'HelpEmployer' }"><img src="@/assets/img/svg/company-owner.svg" class="rounded work-image" height="100" alt=""></router-link>
                            <div class="content pt-3">
                                <h5 class="mb-0 text-center"><router-link :to="{ name : 'HelpEmployer' }" class="text-dark title ">How To get started as Employer</router-link></h5>
                                <p>Learn more how to get started as Employer what you need to do and evert step to follow</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item shuffle-item shuffle-item--visible">
                    <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0 text-center">
                            <router-link :to="{ name : 'HelpPostJobs' }"><img src="@/assets/img/svg/jobs.svg" class="rounded work-image" height="100" alt=""></router-link>
                            <div class="content pt-3">
                                <h5 class="mb-0 text-center"><router-link :to="{ name : 'HelpPostJobs' }" class="text-dark title">How to Post, Edit and Delete Jobs</router-link></h5>
                                 <p>Learn more how to manage your account. Best activities, profile verification, company verification, recove </p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item shuffle-item shuffle-item--visible">
                    <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0 text-center">
                            <router-link :to="{ name : 'HelpCandidatesJob' }"><img src="@/assets/img/svg/job-seeker.svg" class="rounded work-image" height="100" alt=""></router-link>
                            <div class="content pt-3">
                                <h5 class="mb-0 text-center"><router-link :to="{ name : 'HelpCandidatesJob' }" class="text-dark title">How To Do Interview and Filter Candidates</router-link></h5>
                                <p>Learn how to use VipawaRecruitment to do filtering candidates according to thier CV, do the interviews and hire them.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item shuffle-item shuffle-item--visible">
                    <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0 text-center">
                            <router-link :to="{ name : 'HelpJobSeeker' }"><img src="@/assets/img/svg/job-seeker.svg" class="rounded work-image" height="100" alt=""></router-link>
                            <div class="content pt-3">
                                <h5 class="mb-0 text-center"><router-link :to="{ name : 'HelpJobSeeker' }" class="text-dark title">How To Get started as Job Seeker</router-link></h5>
                                <p>Learn how to get started, making best choices. Fill up Your Resume, apply jobs get called for interview. Do interview and get hired </p>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
        </div>
    </section>
<Footer />
</template>

<script>
import Navbar from '@/components/help/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { Navbar,Footer },

}
</script>

<style>

</style>