<template>
    <div class="support-area bg-dark">
        <div class=" ">
            <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active ">
                        <div class="row justify-content-center">
                            <div class="support-item col-2">
                                <img src="@/assets/img/logos/airtel.png" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/tigo.gif" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/m-pesa.png" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/huawei.png" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/sgs.png" class="avatar" alt="Support">
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item ">
                        <div class="row justify-content-center">
                           <div class="support-item col-2">
                                <img src="@/assets/img/logos/airtel.png" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/tigo.gif" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/m-pesa.png" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/huawei.png" class="avatar" alt="Support">
                            </div>
                            <div class="col-2 support-item">
                                <img src="@/assets/img/logos/sgs.png" class="avatar" alt="Support">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.support-area {
	text-align: center;
    padding-bottom: 15px;
}

.support-area .support-slider {
	padding-right: 120px;
    
}
.support-item {
    padding-top: 20px;
}
img{
    height: 40px;
    top:20px;
}

</style>