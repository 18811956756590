<template>
<Navbar /> 
    <div class="container" v-if="!isLoading">
        <div class="position-relative">
            <div class="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section class="section">
                <div class="container">
                    <br>
                    <h2 class="text-center">Who's Hiring on Vipawaworks.com</h2><br>
                    <div class="row justify-content-center" v-if="companies.length">
                        <CompanyList :matchingCompanies="companies" />
                    </div>
                </div>
        </section>
    </div>

    <div v-else>
        <Loading />
    </div>
    <Footer />
</template>

<script>
import getCompanies from '@/composable/getCompanies'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import CompanyList from '@/components/CompanyList.vue'
import Loading from './custom/Loading.vue'
export default {
  name: 'Companies',
  components: {
    Navbar,Footer,CompanyList,Loading
  },
  data(){
      return {
        currentPage: 'Companies',
        previousPage: '',
        isLoading: true,
      }
  },
  setup(){
      const { companies, search, matchingCompanies, error, loadCompanies } = getCompanies()
      loadCompanies()
      return { companies, matchingCompanies, search, loadCompanies, error }
  },
  methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 1000);
   }
  },
  mounted () {
    document.title = `Search by Companies - Vipawaworks`
  this.isLoad()
}

}
</script>

<style scoped>

</style>

