<template>
    <div class="mt-4">
        <div class="clearfix post-recent" v-for="blog in blogs" :key="blog.id">
            <div class="post-recent-thumb float-start"> 
                <router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}"> <img :src="'https://adminora.vipawaworks.com/images/blogs/' + blog.image" class="img-fluid rounded"></router-link>
            </div>
            <div class="post-recent-content float-start"><router-link :to="{ name: 'BlogDetails', params: {id: blog.id}}">{{ blog.name }}</router-link><span class="text-muted mt-2">{{ moment(new Date(blog.created_at)).format('DD/MM/YYYY') }} </span></div>
        </div>
    </div>
</template>

<script>
import getFrontBlogs from '@/composable/getFrontBlogs'
import moment from 'moment'
export default {
  setup(){
      const { blogs, error, loadFrontBlogs } = getFrontBlogs()
      loadFrontBlogs()

      return { blogs, error, loadFrontBlogs }
  },
  mounted(){
      this.moment = moment
  }

}
</script>

<style>

</style>