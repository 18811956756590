<template>
    <Navbar />
    <div v-if="!isLoading">
        <section class="bg-half-260 d-table w-100 slider" >
            <div class="bg-overlay"></div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row" v-if="candidate">
                    <div class="col-lg-4 col-md-5 col-12">
                        <div class="card job-profile shadow border-0">
                            <div class="text-center py-5 border-bottom rounded-top">
                                <div v-if="candidate.avatar">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + candidate.avatar" class="avatar avatar-medium mx-auto rounded-circle shadow d-block" alt="">
                                </div>
                                <div v-else>
                                    <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-medium mx-auto rounded-circle shadow d-block" alt="">
                                </div>
                                <h5 class="mt-3 mb-0">{{ candidate.fname }} {{candidate.lname }}
                                    <span class="text-primary" v-if="candidate.verified == 1">
                                        <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                                    </span>
                                </h5>
                                <p class="text-muted mb-0" v-if="candidate.specialists[0]">{{ candidate.specialists[0].name }}</p>
                            </div>                               
                            <div class="card-body" v-if="candidate.profile[0]">
                                <h5 class="card-title">Personal Details :</h5>
                                <ul class="list-unstyled" >
                                    <li class="h6" v-if="age">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gift fea icon-sm text-warning me-3"><polyline points="20 12 20 22 4 22 4 12"></polyline><rect x="2" y="7" width="20" height="5"></rect><line x1="12" y1="22" x2="12" y2="7"></line><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path><path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path></svg>
                                        <span class="text-muted">Age :</span> {{ age }} Years
                                    </li>
                                    <li class="h6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home fea icon-sm text-warning me-3"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                        <span class="text-muted">Address :</span> {{ candidate.profile[0].physical_address }}
                                    </li>
                                    <li class="h6" v-if="candidate.profile[0].city">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin fea icon-sm text-warning me-3"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg><span class="text-muted">City :</span> {{ candidate.profile[0].city.name }} 
                                    </li>
                                    <li class="h6" v-if="candidate.profile[0].country">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe fea icon-sm text-warning me-3" ><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                                        <span class="text-muted">Nationality :</span> {{ candidate.profile[0].country.name }} 
                                    </li>                                
                                </ul>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <div class="rounded shadow-lg p-4" v-if="token">
                            <h5 class="text-center">Request Freelancing Service From <span class="text-primary">{{ candidate.fname }} {{candidate.lname }}</span> </h5>
                            <div v-if="user.roles[0].id == 1">
                                <FreelancerRequest :company="user_info.companies[0]" :id="id"/>
                            </div>
                            <div class="mt-4" v-else>
                                <div class="text-small alert alert-danger text-center">Freelancing Services is Available For Employer Only</div>
                            </div>
                        </div>
                        <div class="rounded shadow-lg p-4" v-if="!token">
                            <div class="alert alert-danger">
                                <h5 class="text-center">You Must be <router-link :to="{ name : 'Login' }" class="text-primary">Logged in</router-link> To Request this Service</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>    
   
    <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getSingleCandidate from '@/composable/getSingleCandidate'
import Loading from '@/views/custom/Loading.vue'
import FreelancerRequest from '@/components/FreelancerRequest.vue'
export default {
  components: { Navbar,Footer,Loading,FreelancerRequest },
    props: ['id'],
    setup(props){
        const { candidate, age, company,  error,  load } =  getSingleCandidate(props.id)
        load()
        
        return { candidate, age, company,  error }
    },
     data(props){
        return {
            user: { roles:[0]},
            user_info: { company: {}, roles: [0], companies:[0]},
            errors: '',
            token: localStorage.getItem('user_data'),
            form: {
                id: props.id
            },
            isLoading: true,
      }
    },
    methods: {
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
                if (this.user.roles[0].id == 1) {
                axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
            }
            }).catch(errors => {
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
  },
  mounted () {
  axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
    this.getUser()
}

}
</script>

<style>
.item + .item:before {
  content: ", ";
}
</style>