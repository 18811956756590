  <template>
    <NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Add Social Account</h5>
                                <form ref="clearForm" @submit.prevent="editPersonal">
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Facebook</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.facebook_url"  >
                                                    <div id="passwordHelpBlock" class="form-text">Please copy and paste link from use profile link provided by Facebook or copy url from browser</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Tweeter</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.tweeter_url" >
                                                    <div id="passwordHelpBlock" class="form-text">Please copy and paste link from use profile link provided by Tweeter or copy url from browser</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">LinkedIn</label>
                                                <div class="form-icon position-relative">
                                                    <input type="year" class="form-control" v-model="form.linkedin_url" >
                                                    <div id="passwordHelpBlock" class="form-text">Please copy and paste link from use profile link provided by LinkedIn or copy url from browser</div>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Instagram</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.instagram_url" >
                                                    <div id="passwordHelpBlock" class="form-text">Please copy and paste link from use profile link provided by Instagram or copy url from browser</div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
    </div>
    <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { NavEmployer,Footer,Loading, },
    data(){
        return {
            values: [],
            form: {
              facebook_url: '', tweeter_url: '', linkedin_url: '', instagram_url: ''
            },
            user: {}, 
            user_info: { companies:[0]},
            token: localStorage.getItem('user_data'),
            isLoading: true,
            submitting: false,          
            errors: []
        }
    },
    created(){
        document.title = `Manage Social - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.getCompany()
    },
    methods: {
        editPersonal(){
            let id = this.$route.params.id
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/company/social/' + id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CompanyProfile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyProfile'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
            }).catch(errors => {
                if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        getCompany(){
            let id = this.$route.params.id
        axios.get('/company/show/' + id).then(response => {
            this.form = response.data
        })
        .catch(console.log('error'))
        },
    }
    

}
</script>

<style>

</style>