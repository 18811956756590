<template>
    <div class="card shop-list border-0 shadow position-relative">
        <div class="row align-items-center g-0">
            <div class="col-lg-3 col-md-9">
                <div class="shop-image position-relative overflow-hidden">
                    <router-link :to="{ name:'CandidateResume', params:{ id:applicant.id} }">
                        <div v-if="applicant.avatar">
                            <img v-bind:src="'https://api.vipawaworks.com/' + applicant.avatar" class="avatar float-md-left avatar-large img-fluid" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-large img-fluid" alt="">
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="col-lg-9 col-md-9">
                <div class="card-body content p-4">
                    <router-link :to="{ name:'CandidateResume', params:{ id:applicant.id} }" class="text-dark product-name h4">{{ applicant.fname }} {{ applicant.lname}}</router-link>
                    <a href="javascript:void(0)" class="badge badge-link rounded-pill text-primary" v-if="applicant.verified == 1">
                        <svg height="25" width="25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"></path></svg>
                    </a>
                    <div class="d-lg-flex align-items-center mt-2 mb-3">
                        <h6 class="text-primary small fst-italic mb-0 me-3">{{ applicant.roles[0].name }}</h6>
                        <h6 class="text-muted small fst-italic mb-0 me-3" v-if="applicant.specialists[0]">{{ applicant.specialists[0].name && applicant.specialists[0].name.length < 20 ? applicant.specialists[0].name : applicant.specialists[0].name.substring(0,17)+".."  }}</h6>
                        <h6 class="text-muted small fst-italic mb-0 me-3" v-else>No Specialist</h6>
                    </div>
                    <div>
                        <h6 class="text-muted small fst-italic mb-0 me-3" v-if="applicant.education[0]">{{ applicant.education[0].faculty && applicant.education[0].faculty.length < 36 ? applicant.education[0].faculty : applicant.education[0].faculty.substring(0,32)+".."  }}</h6>
                    </div>
                    <p class="para-desc text-muted mb-1" v-if="applicant.profile[0]">
                        <span v-if="applicant.profile[0].city">{{ applicant.profile[0].city.name }}, </span>
                        <span>{{ applicant.profile[0].country.emoji }} {{ applicant.profile[0].country.name }}</span>
                    </p>
                    <p class="para-desc text-muted mb-1" v-else>No Profile Infomation</p>
                    <div class="d-grid gap-2 d-md-block d-lg-block">
                        <router-link :to="{ name:'CandidateResume', params:{ id:applicant.id} }" class="btn btn-secondary  me-md-2">Resume</router-link>
                        <router-link :to="{ name:'CompanyApplicationDetails', params:{ id:applicant.id, job:$route.params.id} }" class="btn btn-primary">Take Action</router-link>
                    </div>
                    <div class="text-end">
                        <div v-if="progress.rejected == 1">
                            <p class="text-danger">Rejected</p>
                        </div>
                        <div v-else-if="progress.passed == 1">
                            <p class="text-success">Passed</p>
                        </div>
                        <div v-else-if="progress.second_interview == 1">
                            <p class="text-primary">Done Second Interview</p>
                        </div>
                        <div v-else-if="progress.second_shortlisted == 1">
                            <p class="text-primary">Shortlisted for Second Interview</p>
                        </div>
                        <div v-else-if="progress.first_interview == 1">
                            <p class="text-primary">Done First Interview</p>
                        </div>
                        <div v-else-if="progress.first_shortlisted == 1">
                            <p class="text-primary">Shortlisted for First Interview</p>
                        </div>
                        <div v-else>
                            <p class="text-dark">Just Applied</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['applicant','job'],
    data(){
      return {
          progress: {}
      }
    },
     methods: {
               checkProgress(){
          axios.get('/candidate/application/checkInterview/' + this.applicant.id + '/' + this.job.id).then(response => {
              this.progress = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
     },
      created(){
          this.checkProgress()
      }
}
</script>

<style>

</style>