<template>
    <div class="col mt-4 pt-2" id="tables">
        <div class="component-wrapper rounded shadow">
            <div class="p-4">
                
                <div class="table-responsive bg-white">
                    <div v-if="user_info.languages.length">
                        <div class="d-flex flex-row-reverse">
                            <router-link class="btn text-primary end-0" :to="{ name : 'FreelancerAddLanguage' }">
                                <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            </router-link>
                        </div>
                        <table class="table mb-0 table-center">
                            <thead>
                                <tr>
                                <th scope="col" class="border-bottom">Language Name</th>
                                <th scope="col" class="border-bottom">Proficiency</th>
                                <th scope="col" class="border-bottom">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="language in user_info.languages" :key="language.id">
                                    <td>{{ language.name }}</td>
                                    <td>
                                        <span v-if="language.proficiency == 1">Biginner</span>
                                        <span v-else-if="language.proficiency == 2">Intermediate</span>
                                        <span v-else-if="language.proficiency == 3">Expert</span>
                                        <span v-else>5 Years +</span>
                                    </td>
                                    <td>
                                         <router-link :to="{ name: 'FreelancerEditLanguage', params:{ id:language.id }}" class="btn btn-pills text-warning">
                                             <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
                                         </router-link>
                                        <button @click="deleteLanguage(language.id)"  class="btn btn-pills text-danger">
                                            <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                    <div v-else >
                        <div  class="text-center">
                            <h4>You Have not Added Your Languages Yet</h4>
                            <router-link class="btn btn-primary end-0" :to="{ name : 'FreelancerAddLanguage' }">Add A new Language</router-link>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Swal from 'sweetalert2'
export default {
    props: ['user_info'],
     methods: {
        deleteLanguage(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/languages/destroy/'+ id)
                .then(response => {
                    this.user_info.languages = this.user_info.languages.filter(language => {
                      return language.id != id
                      })
                }).catch(() => {
                    this.$router.push({ name: 'Skills'})
                })
                Swal.fire(
                'Deleted!',
                'Language has been Deleted.',
                'success'
                )
            }
            })
        }
    }
}
</script>

<style>

</style>