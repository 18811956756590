<template>
<Navbar />
    <section class="bg-auth-home d-table w-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class=" justify-content-center">
                            <img src="@/assets/img/site/fresher.png" height="250" class=" rounded mx-auto d-block">
                            <h3 class="text-center">College Recruits</h3>
                            <p class="text-center">Vipawaworks understand that there are many graduated student who are yet to compete for the employment market.</p>
                            <p class="text-center">Graduated students and students who are still in college, Vipawaworks offers nice enviroment for to develop your skills. And anytime you can change to competent job seeker</p>
                            <p class="text-center">After getting started and successfully registed, You can choose your role as fresher</p>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-7">
                        <div class="card shadow rounded border-0">
                            <div class="card-body">
                                <h3 class="card-title text-center">Create a Fresher Account</h3>  
                                <FresherRegisterForm />
                            </div>
                        </div>
                    </div> 
                    
                </div>
            </div>
        </section>
<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import FresherRegisterForm from '@/components/auth/FresherRegisterForm.vue'
export default {
  components: { Navbar, Footer,FresherRegisterForm },
created(){
    document.title = `Fresher Register - Vipawaworks`
}
}
</script>

<style>

</style>