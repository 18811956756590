  <template>
  <NavEmployer :user="user"  />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-md-start text-center">Add A New User</h5>
                                <RegisterUser :profile="user_info.companies[0]" :roles="roles" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
    </div>
     <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
import RegisterUser from '@/components/company/RegisterUser.vue'
export default {
    components: { NavEmployer,Footer,Loading,RegisterUser, },
    data(){
        return {
            values: [],
            user: {}, 
            token: localStorage.getItem('user_data'),
            isLoading: true, 
            roles: {},
            user_info: { companies:[0]},          
            errors: []
        }
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.getRoles()
        document.title = `Add User - Vipawaworks`
    },
    methods: {
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        
            }).catch(errors => {
                 if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        getRoles(){
        axios.get('/roles/company').then(response => {
            this.roles = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
    }    
      },
    

}
</script>

<style>

</style>