<template>
  <NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="rounded shadow p-4">
                            <h5 class="mb-0">Add A Target Job: </h5>

                            <div class="row projects-wrapper">
                                <form ref="clearForm" @submit.prevent="targetJob">
                                    <div class="row mt-4">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Job Name</label>
                                                <div class="form-icon position-relative">
                                                    <input type="text" class="form-control" v-model="form.name">
                                                    <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Carrier Level</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.carrier_level">
                                                        <option value="1" >Entry Level</option>
                                                        <option value="2" >Student/Intership</option>
                                                        <option value="3" >Mid Carrier</option>
                                                        <option value="4" >Managment</option>
                                                        <option value="5" >Executive Director</option>
                                                        <option value="6" >Senior Executive Director(President CEO)</option>
                                                    </select>
                                                    <div v-if="errors.notice" class="text-small text-danger">{{ errors.carrier_level[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="form-label">Carrier Objective</label>
                                                <div class="form-icon position-relative">
                                                    <textarea class="form-control" v-model="form.carrier_objective"></textarea>
                                                    <div v-if="errors.carrier_objective" class="text-small text-danger">{{ errors.carrier_objective[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Employment Status</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.status">
                                                        <option value="1" >Full time</option>
                                                        <option value="2" >Part time</option>
                                                    </select>
                                                    <div v-if="errors.status" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">Employment Notice</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.notice">
                                                        <option value="1" >Immediately</option>
                                                        <option value="2" >One Month or Less</option>
                                                        <option value="3" >Three Months or Less</option>
                                                        <option value="4" >Six Month or Less</option>
                                                        <option value="5" >More than Six Months</option>
                                                        <option value="6" >More than One year</option>
                                                    </select>
                                                    <div v-if="errors.notice" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="form-label">Job Category</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.category_id">
                                                        <option value="">Select Category</option>
                                                        <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                                    </select>
                                                    <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="text-center">
                                        <button class="btn btn-primary" disabled v-if="submitting">
                                            <span class="label">{{ value }}</span>
                                        </button>
                                        <button class="btn btn-primary" v-else>Save Changes</button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
       <Loading />
   </div>
    	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import Loading from '@/views/custom/Loading.vue'

import qs from 'qs'
export default {
    components: { Footer,NavSeeker,Loading },
    data(){
        return {
            drawer: true,
            currentUser: {},
            userProfile: {},
            user: {roles: [0]},
            form: {
                name:'', carrier_level:1, category_id:'', status: 1, notice:1, carrier_objective:''
            },
            categories: '',
            user_info: [],
            errors: [],
            token: localStorage.getItem('user_data'),
            isMounted: false,
            isLoading: true,
            submitting: false,
        }
    },
    methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
      getCategories(){
        axios.get('/categories').then(response => {
            this.categories = response.data
        }).catch(errors => {
            this.CountryErrors = error.response.data.errors
        })
      },
        targetJob(){
            this.submitting = true
            this.value = 'Please Wait...'
            let id = this.$route.params.id
            axios.post('/targetJob/edit/' + id, this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CandidateJob'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        },
    },
  created(){
      document.title = `Edit Job Target - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser()  
      this.getCategories() 
      this.isCreated = true  
      let id = this.$route.params.id
        axios.get('/targetJob/show/' + id).then(response => {
            this.form = response.data
        })
  }
}
</script>

<style>

</style>