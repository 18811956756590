<template>
<NavSeeker :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-4 col-md-12 col-12 d-lg-block ">
                        <div class="sticky-top">
                            <Sidebar :user="user" :user_info="user_info" :picture="1"/>
                        </div>
                        
                    </div>
                    <div v-if="user_info.profile[0]" class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow-lg">
                            <div class="card-body">
                                <div class="row justify-content-center d-sm-flex">
                                    <div v-if="user_info.avatar" class="col-md-4">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar avatar-large shadow rounded me-md-4" alt="">
                                    </div>
                                    <div v-else class="col-md-4">
                                        <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-large rounded shadow me-md-4" alt="">
                                    </div>
                                    <div class="col-md-8">
                                        <h4>{{ user.fname }} {{ user.sname }}  {{ user.lname }}</h4>
                                        <div v-for="experience in user_info.experiences.slice(0, 1)" :key="experience.id">
                                            <h6>{{ experience.position }}</h6>
                                            <h5 class="text-muted">{{ experience.company_name }}</h5>
                                        </div>
                                        <p class="text-muted"><span v-if="user_info.profile[0].city">{{ user_info.profile[0].city.name }},</span> <span v-if="user_info.profile[0].name"> {{ user_info.profile[0].country.name}}</span></p>
                                        <div v-for="edu in user_info.education" :key="edu.id">
                                            <p class="text-muted">{{ edu.faculty }}, {{ edu.name }}</p>
                                        </div>
                                        <router-link :to="{ name : 'Resume', params:{ id:user.id } }"  class="btn btn-outline-secondary text-center">
                                           View As Employer
                                        </router-link>
                                    </div>
                                </div>

                                <hr>
                                 <div ref="content">
                                     <br>
                                     <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                        <h4 class="text-bold">Basic Information</h4>
                                        <PersonalInfo :user_info="user_info" :editable="1"/>
                                        <br>
                                    </div>
                                    <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                        <h4 class="text-bold">Contact Information</h4>
                                        <BasicInfo :user_info="user_info" :editable="1" />
                                    </div>
                                    <div v-if="user_info.candidate_jobs.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Preferred Job</h4>
                                            <TargetJob :user_info="user_info" :salary="salary" :employmentTypes="employmentTypes" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Add Your Job Preferences</h4>
                                            <p>Write that one job You prefer, with salary range etc.</p>
                                            <router-link :to="{ name : 'CandidateJob' }"  type="button" class="btn btn-primary" >Add Job Preferences</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.education.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Education Background</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link class="btn text-primary end-0" :to="{ name : 'AddEducation' }">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <EducationInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Add Your Education Background</h4>
                                            <p>List academic background to show that you are qualified for any job in your field of study.</p>
                                            <router-link :to="{ name : 'AddEducation' }"  type="button" class="btn btn-primary" >Add Education</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.experiences.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Work Experience</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link class="btn text-primary end-0" :to="{ name : 'AddExperience' }">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <ExperienceInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                     <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <div v-if="user_info.roles[0].id == 3">
                                                <h4 class="text-bold">Add Your Work Experience</h4>
                                                <p>Describe your work history and be sure to add all of your responsibilities, and accomplishments.</p>
                                                <p><strong>Do you have work experience?</strong></p>
                                                <router-link :to="{ name : 'AddExperience' }"  type="button" class="btn btn-primary" >Yes</router-link>
                                                <a @click="switchFresher" type="button" class="btn btn-secondary" >No</a>
                                            </div>
                                            <div v-else>
                                                 <h4 class="text-bold">Add Your Work Experience</h4>
                                                <p>Describe your work history and be sure to add all of your responsibilities, and accomplishments.</p>
                                                <p><strong>Do you have work experience now?</strong></p>
                                                <a @click="switchJobSeeker" type="button" class="btn btn-primary" >Yes</a>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div v-if="user_info.specialists.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Specialists</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddSpecialist' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <SpecialistInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Top 5 Specialists</h4>
                                            <p>List your top five skills and post about your expertise to stand out from other applicants. </p>
                                            <router-link :to="{ name : 'AddSpecialist' }"  type="button" class="btn btn-primary">Add Specialisties</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.skills.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Skills</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddSkill' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <SkillsInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Skills</h4>
                                            <p>List all your skills so employers can learn about your professional knowledge.</p>
                                            <router-link :to="{ name : 'AddSkill' }"  type="button" class="btn btn-primary">Add Skill</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.languages.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Languages</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddLanguage' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <LanguagesInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Languages</h4>
                                            <p>List all languages you speak so employers can learn about your languages of communication.</p>
                                            <router-link :to="{ name : 'AddLanguage' }"  type="button" class="btn btn-primary">Add Language</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.trainings.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Trainings</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddTraining' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <TrainingsInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Trainings</h4>
                                            <router-link :to="{ name : 'AddTraining' }"  type="button" class="btn btn-primary">Add Training</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.certificates.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Certifications</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddCertificate' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <CertificatesInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Certifications</h4>
                                            <router-link :to="{ name : 'AddCertificate' }"  type="button" class="btn btn-primary">Add Certification</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.memberships.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Membership</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddMembership' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                            <MembershipInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Memberships</h4>
                                            <router-link :to="{ name : 'AddMembership' }"  type="button" class="btn btn-primary">Add Membership</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.hobbies.length">
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Hobbies</h4>
                                            <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddHobby' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                        <HobbiesInfo :user_info="user_info" :editable="1" />
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Hobbies</h4>
                                            <router-link :to="{ name : 'AddHobby' }"  type="button" class="btn btn-primary">Add Hobby</router-link>
                                        </div>
                                    </div>
                                    <div v-if="user_info.referees.length">
                                        <h4 class="text-bold">Referees</h4>
                                        <div class="d-flex flex-row-reverse">
                                                <router-link :to="{ name : 'AddReferee' }"  type="button" class="btn text-primary">
                                                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                                </router-link>
                                            </div>
                                        <RefereesInfo :user_info="user_info" :editable="1" />
                                    </div>
                                    <div v-else>
                                        <div class="shadow-sm p-3 mb-5 bg-body rounded">
                                            <h4 class="text-bold">Referees</h4>
                                            <router-link :to="{ name : 'AddReferee' }"  type="button" class="btn btn-primary">Add Referee</router-link>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
    </div>
    	<Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import domtoimage from 'dom-to-image';
import Profile from '@/components/user/Profile.vue'
import Sidebar from '@/components/user/Sidebar.vue'
import NavSeeker from '@/components/user/NavSeeker.vue'
import PersonalInfo from '@/components/user/resume/PersonalInfo.vue'
import BasicInfo from '@/components/user/resume/BasicInfo.vue'
import EducationInfo from '@/components/user/resume/EducationInfo.vue'
import ExperienceInfo from '@/components/user/resume/ExperienceInfo.vue'
import SkillsInfo from '@/components/user/resume/SkillsInfo.vue'
import LanguagesInfo from '@/components/user/resume/LanguagesInfo.vue'
import SpecialistInfo from '@/components/user/resume/SpecialistInfo.vue'
import MembershipInfo from '@/components/user/resume/MembershipInfo.vue'
import TrainingsInfo from '@/components/user/resume/TrainingInfo.vue'
import CertificatesInfo from '@/components/user/resume/CertificatesInfo.vue'
import RefereesInfo from '@/components/user/resume/RefereesInfo.vue'
import HobbiesInfo from '@/components/user/resume/HobbiesInfo.vue'
import Loading from '@/views/custom/Loading.vue'
import TargetJob from '@/components/user/resume/TargetJob.vue';
export default {
  components: { 
      Profile,Sidebar, Footer, PersonalInfo, BasicInfo,EducationInfo,ExperienceInfo, SkillsInfo,
      LanguagesInfo,SpecialistInfo,MembershipInfo,TrainingsInfo,CertificatesInfo,RefereesInfo,HobbiesInfo, NavSeeker,
    Loading,TargetJob,
    },
    data(){
      return {
        drawer: true,
        currentUser: {},
        user: {roles: [0]},
        user_info: {
        profile: [0],preference_countries: [],preference_industries: [],candidate_jobs: [], education: [], experiences: [], skills: [], languages: [], specialists : [], memberships: [], trainings: []
        , certificates: [], hobbies: [], referees: [], roles: [0]
        },
        salary: [],
        employmentTypes:[],
        job: '',
        isLoading: true,
        token: localStorage.getItem('user_data'),
      }
  },
  methods: {
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
                if (this.user.banned == 1) {
                    this.$router.push({ name: 'Banned'})
                }
                if (this.user.roles.length == 0) {
                    this.$router.push({ name: 'Roles'})
                }if (this.user.roles[0].id == 1) {
                    this.$router.push({ name: 'CompanyDashboard'})
                }if (this.user.roles[0].id == 4) {
                    this.$router.push({ name: 'CompanyDashboard'})
                }if (this.user.roles[0].id == 2 ) {
                    this.$router.push({ name: 'FreelancerDashboard'})
                }
                axios.get('/profile/' + this.user.id).then(response => {
                    this.user_info = response.data
                })
                axios.get('/targetSalary/' + this.user.id).then(response => {
                    this.salary = response.data
                })
                axios.get('/employmentTypes/' + this.user.id).then(response => {
                    this.employmentTypes = response.data
                })
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login', query: { redirect: '/myresume' } })
                }
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        switchFresher(){
            axios.post('/role/changeToFresher').then(response => {
                this.success = response.data.success
                this.$router.push({ name: 'Dashboard'})
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        switchJobSeeker(){
            axios.post('/role/changeToJobSeeker').then(response => {
                this.success = response.data.success
                this.$router.push({ name: 'Dashboard'})
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        }, 
    downloadWithCSS() {
        domtoimage
        .toPng(this.$refs.content)
        .then(function(dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        const doc = new jsPDF({
            orientation: "landscape",
            format: [900, 1400]
        });
        const imgProps= doc.getImageProperties(img);
            const pageHeight = 900
            const pdfWidth = doc.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            var heightLeft = pdfHeight
            var position = 0
            doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight

            while(heightLeft >= 0){
                position = heightLeft - pdfHeight;
                doc.addPage()
                doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight
            }

        const date = new Date();
        const filename =
            "resume" +
            date.getFullYear() +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            ("0" + date.getDate()).slice(-2) +
            ("0" + date.getHours()).slice(-2) +
            ("0" + date.getMinutes()).slice(-2) +
            ("0" + date.getSeconds()).slice(-2) +
            ".pdf";
        doc.save(filename);
        })
        .catch(function(error) {
        console.error("oops, something went wrong!", error);
        });
    },
    
  },
  created(){
      document.title = `My Resume - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getUser() 
  }
}
</script>

<style scoped>
.sticky-top{
    z-index: 0 !important;
}
</style>