<template>

<div>
    <h4 class="text-center">Application Progress on {{ job.name }}</h4>
    <div class="mt-4 pt-2" id="tables">
            <div class="p-4">
                <div class="table-responsive">
                    <div v-if="application.rejected == 1">
                        <div class="alert alert-danger">
                            <img src="@/assets/img/svg/failed.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Unfortunately You Not Have Passed The Stage</h4>
                            <p class="text-center">Please Do not be disappointed, it is not that you are not good enough, Please keep it up to emprove your skills and keep applying for more jobs.</p>

                        </div>
                    </div>
                    <div v-else-if="application.passed == 1">
                        <div class="alert alert-success">
                            <img src="@/assets/img/svg/winner.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Congraturations!</h4>
                            <p class="text-center">You have been Selected as A possible Candidate. We will inform you shortly on next stage</p>
                        </div>
                    </div>
                    <div v-else-if="application.second_interview == 1">
                        <div class="alert alert-secondary">
                            <img src="@/assets/img/svg/waiting.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Waiting For Further Instructions</h4>
                            <p class="text-center">You have successfully Done Your Second Interview! We will keep you updated on results</p>
                        </div>
                    </div>
                    <div v-else-if="application.second_shortlisted == 1">
                        <div class="alert alert-secondary">
                            <img src="@/assets/img/svg/progress.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">You Have been Shortlisted</h4>
                            <p class="text-center">We are happy to inform you that you have been called for Second interview</p>
                        </div>
                    </div>                   
                    <div v-else-if="application.first_interview == 1">
                        <div class="alert alert-secondary">
                            <img src="@/assets/img/svg/waiting.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">Waiting For Further Instructions</h4>
                            <p class="text-center">You have successfully Done Your First Interview! We will keep you updated on results</p>
                        </div>
                    </div>
                    <div v-else-if="application.first_shortlisted == 1">
                        <div class="alert alert-secondary">
                            <img src="@/assets/img/svg/progress.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">You Have been Shortlisted</h4>
                            <p class="text-center">We are happy to inform you that you have been called for first interview, please come with your updated copy of Cv</p>
                        </div>
                    </div>
                    <div v-else>
                        <div class="alert alert-secondary">
                            <img src="@/assets/img/svg/noprogress.svg" height="200" class=" rounded mx-auto d-block">
                            <h4 class="text-center">No Progress </h4>
                            <p class="text-center">No There are still no progress on interview. Do not worry we will keep you updated in everything that is happening</p>
                        </div>
                    </div>
                    <div>
                        <h5 class="text-center">View Application Progress</h5>
                        <div class="table-responsive bg-white shadow rounded">
                        <table class="table mb-0 table-center">
                            <thead>
                                <tr>
                                    <th scope="col" class="border-bottom">Shortlisted</th>
                                    <th scope="col" class="border-bottom">First interview</th>
                                    <th scope="col" class="border-bottom">Second Shortlisted</th>
                                    <th scope="col" class="border-bottom">Second Interview</th>
                                    <th scope="col" class="border-bottom" v-if="application.rejected == 1" >
                                        Rejected
                                    </th>
                                    <th scope="col" class="border-bottom" v-else-if="application.passed == 1">
                                        Passed 
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td>
                                        <span v-if="application.first_shortlisted == 1" >
                                            Shortlisted
                                        </span>
                                        <span v-else class="text-secondary">
                                            Not Shortlisted
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="application.first_interview == 1" >
                                            Interviewed
                                        </span>
                                        <span v-else class="text-secondary">
                                            Not Interviewed
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="application.second_shortlisted == 1" >
                                            Shortlisted
                                        </span>
                                        <span v-else class="text-secondary">
                                            Not Shortlisted
                                        </span>
                                    </td>
                                    <td>
                                      <span v-if="application.second_interview == 1" >
                                            Interviewed
                                        </span>
                                        <span v-else class="text-secondary">
                                            Not Interviewed
                                        </span>
                                    </td>
                                    <td v-if="application.rejected == 1">
                                        Rejected
                                    </td>
                                    <td v-else-if="application.passed == 1">
                                        Passed
                                    </td>
                                </tr>
                            </tbody>
                        </table> 
                        </div>
                    </div>
                </div>
        </div>
    </div>
    
</div>
  
</template>

<script>
export default {
    props:['application','job'],
}
</script>

<style>

</style>