<template>
     <!-- Sidebar -->
    <div class="bg-light border-right" id="sidebar-wrapper">
      <div class="sidebar-heading">Start Bootstrap </div>
      <div class="list-group list-group-flush">
        <router-link :to="{ name : 'Dashboard' }" class="list-group-item list-group-item-action bg-light">Dashboard</router-link>
        <router-link :to="{ name : 'Dashboard' }" class="list-group-item list-group-item-action bg-light">Shortcuts</router-link>
        <router-link :to="{ name : 'Dashboard' }" class="list-group-item list-group-item-action bg-light">Overview</router-link>
        <router-link :to="{ name : 'Dashboard' }" class="list-group-item list-group-item-action bg-light">Events</router-link>
        <router-link :to="{ name : 'Dashboard' }" class="list-group-item list-group-item-action bg-light">Profile</router-link>
        <router-link :to="{ name : 'Dashboard' }" class="list-group-item list-group-item-action bg-light">Account</router-link>
      </div>
    </div>
    <!-- /#sidebar-wrapper -->
</template>

<script>
export default {

}
</script>

<style>

</style>