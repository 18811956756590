<template>
    <div class="col-md-4 mt-4">
        <h5>Company Details :</h5>
        <div class="mt-4">
            <div class="d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail fea icon-ex-md text-muted me-3"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Email :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.email }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone fea icon-ex-md text-muted me-3"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Cell No :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.phone }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.mission">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-italic fea icon-ex-md text-muted me-3"><line x1="19" y1="4" x2="10" y2="4"></line><line x1="14" y1="20" x2="5" y2="20"></line><line x1="15" y1="4" x2="9" y2="20"></line></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Mission :</h6>
                    <a href="javascript:void(0)" class="text-muted " >{{ user_info.mission }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.vision">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-italic fea icon-ex-md text-muted me-3"><line x1="19" y1="4" x2="10" y2="4"></line><line x1="14" y1="20" x2="5" y2="20"></line><line x1="15" y1="4" x2="9" y2="20"></line></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Vission :</h6>
                    <a href="javascript:void(0)" class="text-muted " >{{ user_info.vision }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.web_address">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe fea icon-ex-md text-muted me-3"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Website :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.web_address }}</a>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.founded_at">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-gift fea icon-ex-md text-muted me-3"><polyline points="20 12 20 22 4 22 4 12"></polyline><rect x="2" y="7" width="20" height="5"></rect><line x1="12" y1="22" x2="12" y2="7"></line><path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path><path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Year Founded :</h6>
                    <p class="text-muted mb-0">{{ user_info.founded_at }}</p>
                </div>
            </div>
            <div class="d-flex align-items-center mt-3" v-if="user_info.local_address1" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin fea icon-ex-md text-muted me-3"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                <div class="flex-1">
                    <h6 class="text-primary mb-0">Location :</h6>
                    <a href="javascript:void(0)" class="text-muted">{{ user_info.local_address1 }}, {{ user_info.local_address2 }}, {{ user_info.city.name }}, {{ user_info.country_id }}</a>
                </div>
            </div>
            
        </div>
    </div>

    <div class="col-md-8 mt-4 pt-2 pt-sm-0" v-if="jobs">
        <div v-if="jobs.length">
            <h5 class="text-center text-primary">Recently Jobs Posted :</h5>
            <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-4"  v-for="job in limitedJob" :key="job.id">
                <img src="images/job/Circleci.svg" class="avatar avatar-ex-sm" alt="">
                <div class="flex-1 content ms-3">
                    <h6 class="title mb-0">{{ job.name }}</h6>
                    <p class="text-muted mb-0">{{  moment(new Date(job.created_at)).format('DD/MM/YYYY') }} - {{  moment(new Date(job.deadline)).format('DD/MM/YYYY') }} <router-link class="text-primary" :to="{ name: 'CompanyApplicationProgress', params:{ id:job.id}}">View Progress({{ job.applicants_count }})</router-link></p>
                    <p class="text-muted mb-0"></p>    
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row" >
                <div class="col-md-6">
                    <h5 class="text-center text-primary">Post Jobs</h5>
                    <div class="text-center">
                        <span class="text-muted">
                            <svg height="70" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                        </span>
                        <p class="text-muted">
                            Advertise your jobs for free with 1 easy step and receive candidate applications.
                        </p>
                        <router-link class="btn btn-primary " :to="{ name : 'AddJob' }">Start By Posting Job</router-link>
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="text-center text-primary">Get Applications</h5>
                    <div class="text-center">
                        <span class="text-muted">
                            <svg height="70" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"></path></svg>                    </span>
                        <p class="text-muted">
                            Get application alert by email. Browse and search applicant by jobs, dates and keywords.
                        </p>
                        <router-link class="btn btn-primary " :to="{ name : 'AddJob' }">Start By Posting Job</router-link>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-md-6">
                    <h5 class="text-center text-primary">Engage</h5>
                    <div class="text-center">
                        <span class="text-muted">
                            <svg height="70" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path></svg>
                        </span>
                        <p class="text-muted">
                            View resume and contact details. Schedule interviews, send messages and manage status.
                        </p>
                        <router-link class="btn btn-primary " :to="{ name : 'AddJob' }">Start By Posting Job</router-link>
                    </div>
                </div>
                <div class="col-md-6">
                    <h5 class="text-center text-primary">Hire</h5>
                    <div class="text-center">
                        <span class="text-muted">
                            <svg height="70" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path></svg>
                        </span>
                        <p class="text-muted">
                            Hire the best suitable candidates from the most relevant job applications.
                        </p>
                        <router-link class="btn btn-primary " :to="{ name : 'AddJob' }">Start By Posting Job</router-link>
                    </div>
                </div>
                
            </div>
        </div>
            
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['user_info'],
    data(){
        return{
            jobs: {},
            limit: 4,
        }
    },
    methods:{
        getAllJobs(){
            axios.get('/companyAllJobs').then(response => {
                this.jobs = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    computed:{
    limitedJob(){
        return this.limit ? this.jobs.slice(0,this.limit) : this.jobs
    },
    },
    created(){
        this.moment = moment
        this.getAllJobs()
    }
}
</script>

<style>

</style>