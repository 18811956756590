<template>
<FreelancerNav :user="user"/>
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-center">Change Password :</h5>
                                <div class="alert alert-success" v-if="success">
                                    <p class="text-center">{{ success }}</p>
                                </div>
                                <div v-if="errors.old_password">
                                </div>
                                <div  v-else-if="errors.new_password">
                                </div>
                                <div  v-else-if="errors.confirm_password">
                                </div>
                                <div class="alert alert-danger" v-else-if="errors">
                                    <p class="text-center">{{ errors }}</p>
                                </div>
                                
                                    <form @submit.prevent="changePassword">
                                        <div class="row justify-content-center mt-4">
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">Old password :</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input type="password" class="form-control ps-5" v-model="form.old_password">
                                                        <div v-if="errors.old_password" class="text-small text-danger">{{ errors.old_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">New password :</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input type="password" class="form-control ps-5" v-model="form.new_password">
                                                        <div v-if="errors.new_password" class="text-small text-danger">{{ errors.new_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="col-lg-8">
                                                <div class="mb-3">
                                                    <label class="form-label">Re-type New password :</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-key fea icon-sm icons"><path d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg>
                                                        <input type="password" class="form-control ps-5" v-model="form.confirm_password">
                                                        <div v-if="errors.confirm_password" class="text-small text-danger">{{ errors.confirm_password[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-center">
                                                <button class="btn btn-primary" disabled v-if="submitting">
                                                    <span class="label">{{ value }}</span>
                                                </button>
                                                <button class="btn btn-primary" v-else>Save Changes</button>
                                            </div>
                                        </div>
                                    </form>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { Footer,FreelancerNav,Loading },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {roles:[0]},
          success: false,
          errors: false,
          errorsOldPassword: false,
          form: {
               old_password:'',new_password:'',confirm_password:'',
            },
          company_id: null,
          isLoading: true,
          submitting: false,
          user_info: { companies:[0], },
          token: localStorage.getItem('user_data'),
      }
  },
  methods: {
    getUser(){
    axios.get('/user').then(response => {
        this.currentUser = response.data
        this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
    }).catch(errors => {
         if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
    }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 1000);
    })
    }, 
    changePassword(){
        this.submitting = true
        this.value = 'Please Wait...'
        axios.post('/change/password', this.form).then(response => {
            this.$router.push({ name: 'FreelancerProfile'})
             this.success = response.data.success
        }).catch((error) => {
            this.errors = error.response.data.errors
            this.submitting = false
        })
    },
  },
  mounted(){
      document.title = `Change Password - Vipawaworks`
     axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
  }
}
</script>

<style>

</style>