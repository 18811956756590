<template>
  <div class="row">
    <div class="col-6 mt-4 pt-2">
        <router-link :to="{ name : 'CompanyDashboard' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <span class="pro-icons h3 text-muted"><i class="uil uil-dashboard"></i></span>
            <h6 class="title text-dark h6 my-0">Dashboard</h6>
        </router-link>
    </div><!--end col-->
    <div class="col-6 mt-4 pt-2">
        <router-link :to="{ name : 'CompanyJobs' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
            <h6 class="title text-dark h6 my-0">Jobs</h6>
        </router-link>
    </div><!--end col-->
    <div class="col-6 mt-4 pt-2">
        <router-link :to="{ name: 'CompanyApplicationProgress', params:{ id:job.id}}"  class="btn accounts rounded d-block shadow text-center py-3">
            <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
            <h6 class="title text-dark h6 my-0">Progress</h6>
        </router-link>
    </div><!--end col-->
    <div class="col-6 mt-4 pt-2">
        <router-link :to="{ name : 'CompanyProfile' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
            <h6 class="title text-dark h6 my-0">Company Profile</h6>
        </router-link>
    </div><!--end col-->
    <div class="col-6 mt-4 pt-2">
        <router-link :to="{ name : 'CompanyPricing' }"  class="btn accounts rounded d-block shadow text-center py-3">
            <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
            <h6 class="title text-dark h6 my-0">Pricing</h6>
        </router-link>
    </div><!--end col-->
</div><!--end row-->
  
</template>

<script>
export default {

}
</script>

<style>

</style>