  <template>
  <FreelancerNav :user="user"/>
  <section class="section mt-60">
    <div class="container mt-lg-3">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card border-0 rounded shadow">
                    <div class="card-body">
                        <h5 class="text-primary text-center">Add Language</h5>
                        <div class="alert alert-danger" v-if="errors[0]">
                            <h6 class="text-center">{{ errors }}</h6>
                        </div>
                        <form ref="clearForm" @submit.prevent="editPersonal">
                            <div class="row mt-4">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Language Name</label>
                                        <div class="form-icon position-relative">
                                            <input type="text" class="form-control ps-5" v-model="form.name" >
                                            <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label class="form-label">Proficiency</label>
                                        <div class="form-icon position-relative">
                                            <select v-model="form.proficiency" class="form-control ps-5" >
                                                <option value="1">Beginer</option>
                                                <option value="2">Intermediate</option>
                                                <option value="3">Expert</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <div class="text-center">
                                <button class="btn btn-primary" disabled v-if="submitting">
                                    <span class="label">{{ value }}</span>
                                </button>
                                <button class="btn btn-primary" v-else>Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>  
  <Footer />
    
</template>

<script>
import Footer from '@/components/Footer.vue'
import FreelancerNav from '@/components/freelancer/FreelancerNav.vue'
import qs from 'qs'
export default {
    components: { FreelancerNav,Footer, },
    data(){
        return {
            values: [],
            form: {
               proficiency: '1', name: '',
            },
            user: {},
            token: localStorage.getItem('user_data'),
            submitting: false,
            errors: []
        }
    },
    methods: {
        getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
           if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
      },
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            axios.post('/languages/add/' + this.user.id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'FreelancerSkills'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        }
    },
    created(){
        document.title = `Add Language - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()  
    }
    

}
</script>

<style>

</style>