<template>
    <NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-12 d-lg-block ">
                        <ProgressSidebar :progress="user_info.companies[0]" :job="job.id" />
                    </div>

                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h4 class="text-center text-primary">All Shortlisted For First Interview  on <span class="text-dark">{{ job.name }}</span></h4>
                                <div  v-if="job.applicants.length">
                                    <div class="col mt-4 pt-2" id="tables">
                                        <div class="text-end">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@getbootstrap">Send Email To Person Incharge</button>
                                        </div>
                                        <div class="component-wrapper">
                                            <div class="p-4">
                                                <div class="row">
                                                    <div class="alert alert-success" v-if="success">
                                                        <p class="text-center">{{ success }}</p>
                                                    </div>
                                                    <div class="alert alert-danger" v-if="errors">
                                                        <p class="text-center">{{ errors }}</p>
                                                    </div>
                                                    <div class="col-6 mt-4 pt-2" v-for="applicant in job.applicants" :key="applicant.id">
                                                        <CandidateProgress :applicant="applicant" :job="job" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center" v-else>
                                    <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                    <h3 class="text-center">No Applicants</h3>
                                    <p class="text-center">No applicant has been Shortlisted</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        <!-- modal for sending mail  -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="Close">
                <div class="modal-dialog">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Email To Person Incharge</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <form @submit.prevent="mailIncharge">
                        <div class="modal-body">
                            <div class="mb-3">
                                <label for="recipient-name" class="col-form-label">Recipient Email:</label>
                                <input type="email" class="form-control" v-model="form.email">
                                <div v-if="errors.email" class="text-small text-danger">{{ errors.email[0] }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Addition Message(Optional):</label>
                                <textarea class="form-control" id="message-text" v-model="form.message"></textarea>
                            </div>
                        
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Send Email</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        <!-- end modal for sending mail  -->
    </div>
     <div v-else>
       <Loading />
   </div>
  <Footer />
</template>

<script>
import CandidateProgress from '@/components/company/CandidateProgress.vue'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import ProgressSidebar from '@/components/company/ProgressSidebar.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { NavEmployer,Footer,ProgressSidebar,Loading,CandidateProgress },
    data(){
      return {
          drawer: true,
          currentUser: {},
          userProfile: {},
          user: {},
          form:{
              email:'', message:''
          },
          success: false,
          errors: false,
          isLoading: true,
          applicant: {
              specialists: []
          },
          specialists: [],
          job: {
              applicants: []
          },
          user_info: {companies:[0]},
          token: localStorage.getItem('user_data'),
      }
  },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
                let id = this.$route.params.id
                axios.get('/jobShortlisted/' + id).then(response => {
                    this.job = response.data
                })
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 1000);
        })
      },
      mailIncharge(){
          let job = this.$route.params.id
          axios.post('/application/mailIncharge/' + job, this.form).then(response => {
              this.$refs.Close.click()
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
      },
       rejectApplicant(applicant){
          let job = this.$route.params.id
          axios.post('/application/reject/' + job, applicant).then(response => {
              this.success = response.data.success
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      
  },
  created(){
      document.title = `Shortlisted Candidates - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
  },
}
</script>

<style>

</style>