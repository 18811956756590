<template>
    <div @submit-form="getUser()">
        <NavEmployer :user="user"  />
        <div v-if="!isLoading">
            <section class="section mt-60">
                <div class="container mt-lg-3">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-12">
                            <div class="card border-0 rounded shadow">
                                <div class="card-body">
                                    <h2 class="text-center">Edit Job {{ form.name }}</h2>
                                    <form ref="clearForm" @submit.prevent="editPersonal">
                                        <div class="row mt-4">
                                            <h4 class="text-center">Job Description</h4>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Job Title</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="text" class="form-control" v-model="form.name" >
                                                        <div v-if="errors.name" class="text-small text-danger">{{ errors.name[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Job Category</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.category_id ">
                                                            <option value="">Select Category</option>
                                                            <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.name }}</option>
                                                        </select>
                                                        <div v-if="errors.category_id" class="text-small text-danger">{{ errors.category_id[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="form-label">Job Role</label>
                                                <div class="form-icon position-relative">
                                                    <textarea :editor="editor" v-model="form.roles" class="form-control"></textarea>
                                                    <div v-if="errors.roles" class="text-small text-danger">{{ errors.roles[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <label class="form-label">Job Responsibilities</label>
                                                <div class="form-icon position-relative">
                                                    <ckeditor :editor="editor" v-model="form.responsibilities"></ckeditor>
                                                    <div v-if="errors.responsibilities" class="text-small text-danger">{{ errors.responsibilities[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Company Industry</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.industry_id">
                                                            <option value="">Select Industry</option>
                                                            <option :value="industry.id" v-for="industry in industries" :key="industry.id">{{ industry.name }}</option>
                                                        </select>
                                                        <div v-if="errors.industry_id" class="text-small text-danger">{{ errors.industry_id[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Employment Type</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.employment_type">
                                                            <option value="">Select Type</option>
                                                            <option value="1">Consultant</option>
                                                            <option value="2">Contractor</option>
                                                            <option value="3">Employee</option>
                                                            <option value="4">Temporary Employee</option>
                                                            <option value="5">Volunteer</option>
                                                        </select>
                                                        <div v-if="errors.employment_type" class="text-small text-danger">{{ errors.employment_type[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Employment Status</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.employment_status">
                                                            <option value="">Select Category</option>
                                                            <option value="1">Full Time</option>
                                                            <option value="2">Party Time</option>
                                                        </select>
                                                        <div v-if="errors.employment_status" class="text-small text-danger">{{ errors.employment_status[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Number of Vaccanties</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="number" class="form-control" v-model="form.vaccanties" min="1">
                                                        <div v-if="errors.vaccanties" class="text-small text-danger">{{ errors.vaccanties[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Country</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  @change="getCities()" v-model="form.country_id">
                                                            <option value="">Select Country</option>
                                                            <option :value="country.id" v-for="country in countries" :key="country.id">{{ country.name }}</option>
                                                        </select>
                                                        <div v-if="errors.country_id" class="text-small text-danger">{{ errors.country_id[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                            <div class="mb-3">
                                                <label class="form-label">City</label>
                                                <div class="form-icon position-relative">
                                                    <select class="form-control"  v-model="form.city_id">
                                                        <option value="0">All Cities In the Country</option>
                                                        <option :value="city.id" v-for="city in cities" :key="city.id">{{ city.name }}</option>
                                                    </select>
                                                    <div v-if="errors.city_id" class="text-small text-danger">{{ errors.city_id[0] }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="row mt-4">
                                            <h4 class="text-center">Requirements & Qualifications</h4>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Minimum Education</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.min_education ">
                                                            <option value="0">Not Limited</option>
                                                            <option :value="edu.id" v-for="edu in education" :key="edu.id">{{ edu.name }}</option>
                                                        </select>
                                                        <div v-if="errors.min_education" class="text-small text-danger">{{ errors.min_education[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Maximum Education</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.max_education ">
                                                            <option value="0">Not Limited</option>
                                                            <option :value="edu.id" v-for="edu in education" :key="edu.id">{{ edu.name }}</option>
                                                        </select>
                                                        <div v-if="errors.max_education" class="text-small text-danger">{{ errors.max_education[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Minimum Experience(years)</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="number" class="form-control" v-model="form.min_experience" min="0">
                                                        <div v-if="errors.min_experience" class="text-small text-danger">{{ errors.min_experience[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Maximum Experience(years)</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="number" class="form-control" v-model="form.max_experience" min="0">
                                                        <div v-if="errors.max_experience" class="text-small text-danger">{{ errors.max_experience[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Gender</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.gender">
                                                            <option value="1">Any</option>
                                                            <option value="2">Female</option>
                                                            <option value="2">Male</option>
                                                        </select>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="mb-3">
                                                    <label class="form-label">Other Qualification (optional)</label>
                                                    <div class="form-icon position-relative">
                                                        <ckeditor :editor="editor" v-model="form.other_qualifications"></ckeditor>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <h4 class="text-center">Benefits and Remunerations</h4>
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label class="form-label">Minimum Salary(Monthly)</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="text" class="form-control" v-model="form.min_salary" min="0">
                                                        <div v-if="errors.min_salary" class="text-small text-danger">{{ errors.min_salary[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="mb-3">
                                                    <label class="form-label">Maximum Salary(Monthly)</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="text" class="form-control" v-model="form.max_salary" min="0">
                                                        <div v-if="errors.max_salary" class="text-small text-danger">{{ errors.max_salary[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <label class="form-label">Currency</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.currency">
                                                            <option value="1">TSH</option>
                                                            <option value="2">USD</option>
                                                        </select>
                                                        <div v-if="errors.currency" class="text-small text-danger">{{ errors.currency[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="mb-3">
                                                    <label class="form-label">Hide Salary</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.salary_hidden">
                                                            <option value="0">Seen</option>
                                                            <option value="1">Hidden</option>
                                                        </select>
                                                        <div v-if="errors.salary_hidden" class="text-small text-danger">{{ errors.salary_hidden[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="row mt-4">
                                            <h4 class="text-center">Application Instructions</h4>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Application Method</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.method">
                                                            <option value="1">Apply Online</option>
                                                            <option value="2">Apply Via Email</option>
                                                            <option value="3">Apply Via Link</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6" v-if="form.method == 2">
                                                <div class="mb-3">
                                                    <label class="form-label">Application Email</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="email" class="form-control" v-model="form.application_email" min="0">
                                                        <div v-if="errors.application_email" class="text-small text-danger">{{ errors.application_email[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6" v-if="form.method == 3">
                                                <div class="mb-3">
                                                    <label class="form-label">Application Link</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="text" class="form-control" v-model="form.application_link" min="0">
                                                        <div v-if="errors.application_link" class="text-small text-danger">{{ errors.application_link[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Application Target</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.target">
                                                            <option value="1">Both</option>
                                                            <option value="2">Experienced</option>
                                                            <option value="3">Freshers</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Application Deadline</label>
                                                    <div class="form-icon position-relative">
                                                        <input type="date" class="form-control" v-model="form.deadline" min="0">
                                                        <div v-if="errors.deadline" class="text-small text-danger">{{ errors.deadline[0] }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="mb-3">
                                                    <label class="form-label">Application Instructions (Optional)</label>
                                                    <div class="form-icon position-relative">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check fea icon-sm icons"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                                        <ckeditor :editor="editor" v-model="form.instructions"></ckeditor>
                                                        <div v-if="form.method == 2" class="form-text text-danger">You are adviced to fill this field with instruction what the candidate should write and attach in the Email</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Post Status</label>
                                                    <div class="form-icon position-relative">
                                                        <select class="form-control"  v-model="form.status">
                                                            <option value="0">Daft</option>
                                                            <option value="1">Published</option>
                                                        </select>
                                                        <div v-if="form.status == 0" class="form-text">This job will not be available to candidates change to Published</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="mb-3">
                                                    <label class="form-label">Featured</label>
                                                    <select class="form-control"  v-model="form.featured">
                                                        <option value="0">Not Featured</option>
                                                        <option value="1">Featured</option>
                                                    </select>
                                                    <div v-if="form.featured == 0" class="form-text">This job will not be diplayed to Home page but will be available to all jobs</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text-center">
                                            <button class="btn btn-primary" disabled v-if="submitting">
                                                <span class="label">{{ value }}</span>
                                            </button>
                                            <input type="submit" id="submit" class="btn btn-primary btn-lg" value="Save Changes" v-else>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  
        </div>
        <div v-else>
            <Loading />
        </div>
        <Footer />
    </div>
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Loading from '@/views/custom/Loading.vue'

import qs from 'qs'
export default {
    components: { NavEmployer,Footer,Sidebar,Loading },
    data(){
        return {
            values: [],
            form: {
               name: '', country_id: '', city_id: 0, category_id: '', roles: '', industry_id: '', employment_type: '', employment_status: '', vaccanties: '',
                min_education: 0, max_education: 0, min_experience: '', max_experience: '', gender: 1, qualifications: '', featured: false,
                salary_hidden: 0, method: 1,  target: 1, instructions: '', file: '', deadline: '', status: 0, min_salary: 0, max_salary: 0,  currency: 1, 
                application_email: '', application_link: '', responsibilities: '', other_qualifications: ''
               
            },
            editor: ClassicEditor,
            countries: {},
            cities:{},
            categories: {},
            industries: {},
            education: {},
            user: {},
            user_info: { companies:[0]},
            token: localStorage.getItem('user_data'),
            errors: [],
            isLoading: true,
            submitting: false,
            CountryErrors: null
        }
    },
    methods: {
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
                if (this.user.banned == 1) {
                    this.$router.push({ name: 'Banned'})
                }
                if (this.user.email_verified_at == null) {
                    this.$router.push({ name: 'VerifyEmail'})
                }
                if (this.user.roles.length == 0) {
                    this.$router.push({ name: 'Roles'})
                }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                    this.$router.push({ name: 'Dashboard'})
                }if (this.user.roles[0].id == 2 ) {
                    this.$router.push({ name: 'FreelancerDashboard'})
                }
                axios.get('/company/' + this.user.id).then(response => {
                    this.user_info = response.data
                })
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('user_data')
                    this.$router.push({ name: 'Login'})
                }
                })
        },
        getCountries(){
            axios.get('/countries').then(response => {
                this.countries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        }, 
        getCities(){
            axios.get('/cities/' + this.form.country_id).then(response => {
                this.cities = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        }, 
        getIndustries(){
            axios.get('/industries').then(response => {
                this.industries = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getCategories(){
            axios.get('/categories').then(response => {
                this.categories = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            })
        },
        getEducation(){
            axios.get('/educationLevel').then(response => {
                this.education = response.data
            }).catch(errors => {
                this.CountryErrors = error.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        editPersonal(){
            this.submitting = true
            this.value = 'Please Wait...'
            let id = this.$route.params.id
            axios.post('/jobs/editJob/' + id , this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'CompanyJobs'})
            }).catch((error) => {
                this.errors = error.response.data.errors
                this.submitting = false
            })
        }
    },
    created(){
        document.title = `Edit Job - Vipawaworks`
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.getCountries()
        this.getCategories()
        this.getIndustries()
        this.getEducation()
        if(this.form.country_id){
            this.getCities()
        }
        
        let id = this.$route.params.id
        axios.get('/jobs/showJob/' + id).then(response => {
            this.form = response.data
        })
    }
    

}
</script>

<style>

</style>