<template>
    <div class="justify-content-center">
        <img src="@/assets/img/svg/welcome.svg" height="200" class=" rounded mx-auto d-block">
        <h3 class="text-center">Welcome To Vipawaworks</h3>
        <p>
            Due to increase in demand and requirement from you(users), and to meet standard of quickly changes of technology, we have made huge upgrade of our system. Here are the main changes
        </p>
        <ul class="job-facts ">
            <li class="list-inline-item text-muted" >
                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                Improved UI/UX, i.e. system is more user friendly and easy to use.
            </li><br>
            <li class="list-inline-item text-muted" >
                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                Improved security and data privacy.
            </li><br>
            <li class="list-inline-item text-muted" >
                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                Easy to post and manage jobs, easy interviews process.
            </li><br>
            <li class="list-inline-item text-muted" >
                <svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
                Easy access to more than 10,000 CV, of most compitents candidates.
            </li>
        </ul>
        <p>
            We hope you enjoy the new system and service
        </p>
        <p>
            Please feel free to <router-link :to="{ name : 'Contacts' }" class="text-primary">contact us</router-link> and gives us <router-link :to="{ name : 'Feedback' }" class="text-primary">feedback,</router-link> on how we can improve our systems and services
        </p>
        <p class="text-center">Get Started by Filling Up Your <router-link :to="{ name : 'CompanyEditMyProfile' }" class="btn btn-primary">Personal Info</router-link> </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>