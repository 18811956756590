<template>
    <Navbar />
    <div v-if="!isLoading">
        <section class="section mt-100">
            <div class="container">
                <div class="" v-if="error">
                    <div class="d-flex justify-content-center">
                        <img src="@/assets/img/svg/empty.svg" height="200" alt="">
                    </div>
                    <div class="text-center">
                        <h3>{{ error }}</h3>
                        <router-link class="btn btn-primary mt-4" :to="{ name: 'Jobs'}">Browse More Jobs</router-link>
                    </div>
                </div>
                <div v-else>
                    <div ref="content">
                        <div class=" row justify-content-center text-primary ">
                            <div class="col-md-12">
                                <h1 class="text-center text-primary">{{ value.job.name }}</h1>
                            </div>
                        </div>
                        <h3 class="text-center">Job Description</h3>
                        <div class="row">
                        <div class="col-lg-5 col-md-5 col-12">
                            <div class="card sidebar sticky-bar rounded shadow border-0">
                                <div class="card-body widget border-bottom">
                                    <h5 class="mb-0">Job Summary</h5>
                                </div>
                                <div class="card-body">
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Employee Status:
                                        <span class="text-primary mb-0" v-if="value.job.employment_status == 1">Full Time</span>
                                        <span class="text-primary mb-0" v-if="value.job.employment_status == 2">Party Time</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Employee Type:
                                        <span class="text-primary mb-0" v-if="value.job.employment_type == 1">Consultant</span>
                                        <span class="text-primary mb-0" v-if="value.job.employment_type == 2">Contractor</span>
                                        <span class="text-primary mb-0" v-if="value.job.employment_type == 3">Employee</span>
                                        <span class="text-primary mb-0" v-if="value.job.employment_type == 4">Temporary Employee</span>
                                        <span class="text-primary mb-0" v-if="value.job.employment_type == 5">Volunteer</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Location:
                                        <span class="text-primary mb-0">{{ value.job.city.name }}, {{ value.job.country.name }} {{ value.job.country.emoji }}</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Company:
                                        <span class="text-primary mb-0">{{ value.job.company.name }}</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Company Industry:
                                        <span class="text-primary mb-0">{{ value.job.industry.name }}</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Gender Preference:
                                        <span class="text-primary mb-0" v-if="value.job.gender == 1">Both</span>
                                        <span class="text-primary mb-0" v-else-if="value.job.gender == 2">Female</span>
                                        <span class="text-primary mb-0" v-else-if="value.job.gender == 3">Male</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Experience Preference:
                                        <span class="text-primary mb-0" v-if="value.job.target == 1">Equal</span>
                                        <span class="text-primary mb-0" v-else-if="value.job.target == 2">Expereinced</span>
                                        <span class="text-primary mb-0" v-else-if="value.job.target == 3">Freshers</span>
                                        <span class="text-primary mb-0" v-else>Equal</span>
                                    </h6>
                                    <h6 v-if="value.job.target == 2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Experience:
                                        <span class="text-primary mb-0" v-if="value.job.min_experience == 0 && value.job.max_experience == 0">Not Limited</span>
                                        <span class="text-primary mb-0" v-else>{{ value.job.min_experience }}<span v-if="value.job.max_experience != 0"> - {{ value.job.max_experience }}</span>  in Years</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Salary:
                                        <span class="text-primary mb-0" v-if="value.job.salary_hidden == 0"> 
                                            <span v-if="value.job.currency == 1">TSH</span> <span v-if="value.job.currency == 2">USD</span> {{ Number(value.job.min_salary).toLocaleString() }} to {{ Number(value.job.max_salary).toLocaleString() }}
                                        </span>
                                        <span class="text-primary mb-0" v-else>
                                            <span>Unspecified</span>
                                        </span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Number Of Vaccanties:
                                        <span class="text-primary mb-0">
                                            <span> {{ value.job.vaccanties }}</span>
                                        </span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Date posted:
                                        <span class="text-primary mb-0 mb-0">{{  moment(new Date(value.job.updated_at)).format('DD/MM/YYYY') }}</span>
                                    </h6>
                                    <h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm text-success me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                                        Deadline:
                                        <span v-if="value.passed_deadline == 0" class="text-primary mb-0 mb-0">{{  moment(new Date(value.job.deadline)).format('DD/MM/YYYY') }}</span>
                                        <span v-else class="text-danger mb-0 mb-0">Deadline Has Passed</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="ms-lg-4">
                                <div>
                                    <h5>Job Roles</h5>
                                    <span class="text-muted" v-html="value.job.roles"></span> 
                                    <hr> 
                                </div>
                                <div v-if="value.job.responsibilities">
                                    <h5>Job Responsibilities</h5>
                                    <span class="text-muted" v-html="value.job.responsibilities"></span> 
                                    <hr> 
                                </div>
                                <div>
                                    <div v-if="minEducation && maxEducation">
                                        <h5>Education Qualifications & Experience Requirements</h5>
                                        <div class="text-muted">
                                            Education: <span class="text-primary" v-if="value.job.min_education == 0">Minimum Not Limited</span>
                                            <span class="text-primary" v-else>Minimum {{ minEducation.name }}</span> & 
                                            <span class="text-primary" v-if="value.job.max_education == 0">Maximum Not Limited</span>
                                            <span class="text-primary" v-else>Not Higher than {{ maxEducation.name }}</span>
                                        </div>
                                    </div>
                                    <div class="text-muted"  v-if="value.job.min_experience">
                                        Experience: <span class="text-primary" v-if="value.job.min_experience == 0">Minimum Experience Not Neccesssary</span>
                                    <span class="text-primary" v-else>Not Less than {{ value.job.min_experience }} years</span>
                                    <span v-if="value.job.max_experience == 0" class="text-primary"></span>
                                    <span class="text-primary" v-else> & Not Higher than {{ value.job.max_experience }} years</span> <span v-if="value.job.max_experience != 0 && value.job.min_experience != 0">of Experience in Related Field</span> 
                                    </div>
                                    <hr>
                                    <div v-if="value.job.qualifications">
                                        <h5>Addition Qualifications Information: </h5>
                                        <span class="text-muted" v-html="value.job.qualifications"></span>
                                        <hr>
                                    </div>
                                </div>
                                <div v-if="value.job.other_qualifications">
                                    <h5>Other Qualifications</h5>
                                    <span class="text-muted" v-html="value.job.other_qualifications"></span> 
                                    <hr> 
                                </div>
                                <div v-if="value.job.instructions">
                                    <h5>Application Instructions: </h5>
                                    <span class="text-muted" v-html="value.job.instructions"></span>
                                    <hr> 
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-md-8">
                            <div class="mt-4 alert alert-danger text-center" v-if="value.passed_deadline == 1">
                                <div class="text-small">Deadline has Passed</div>
                            </div>
                            <div class="mt-4 alert alert-danger text-center" v-else-if="auth_errors">
                                <div><span class="text-small">Please <router-link :to="{ name : 'Login', query: { redirect: '/jobs/'+ value.job.id } }" class="text-primary">Sign In</router-link> {{ auth_errors }}</span></div>
                            </div>
                            <div class="mt-4 alert alert-danger text-center" v-else-if="errors">
                                <div><span class="text-small">{{ errors }}</span></div>
                            </div>
                            <div class="mt-4 alert alert-danger text-center" v-else-if="user.roles[0].id == 1">
                                <div class="text-small">Employer Cannot Apply For Jobs</div>
                            </div>
                            <div class="mt-4 alert alert-danger text-center" v-else-if="user.roles[0].id == 2">
                                <div class="text-small">Freelancer Cannot Apply For Jobs</div>
                            </div>
                            <div class="mt-4 alert alert-danger text-center" v-else-if="user.banned == 1">
                                <div class="text-small ">You are banned From All Activities within The Website</div>
                            </div>

                            <div class="mt-4" v-else>
                                <div v-if="value.job.method == 2">
                                    <a  class="btn btn-primary" :href="`mailto:${value.job.application_email}`">Apply Now via {{ value.job.application_email }}<i class="mdi mdi-send"></i></a>
                                </div>
                                <div v-else-if="value.job.method == 3">
                                    <a  class="btn btn-primary" :href="value.job.application_link" target="_blank">Apply Now via {{ value.job.application_link }}<i class="mdi mdi-send"></i></a>
                                </div>
                                <div v-else>
                                    <form @submit.prevent="applyJob">
                                        <input type="hidden" v-model="form.id">
                                        <button  class="btn btn-primary">Apply Now <i class="mdi mdi-send"></i></button>
                                        <div v-if="errors" class="text-small text-danger">{{ errors }}</div>
                                    </form>
                                </div>
                                
                                
                            </div>
                            <div class="card rounded shadow border-0">
                                <div class="card-body widget border-bottom text-end">
                                    <h5 class="text-primary">Share this Job</h5>
                                     <a @click="downloadWithCSS" class="btn text-primary mt-2 border-0 bg-light" type="button">
                                        <svg width="30" height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path></svg>
                                     </a>
                                    <ShareNetwork network="Email" :url="share.url" :title="value.job.name" hashtags="vipawarecruitment" class="btn text-primary" width="30" height="30">
                                        <svg width="30" height="30"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                                    </ShareNetwork>
                                    <ShareNetwork network="facebook" :url="share.url" :title="value.job.name" hashtags="vipawarecruitment" class="btn text-primary" width="30" height="30">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path  stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-lg fea-social" d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                                    </ShareNetwork>
                                    <ShareNetwork network="twitter" :url="share.url" :title="value.job.name" hashtags="vipawarecruitment" class="btn text-primary" width="30" height="30">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path  stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-lg fea-social" d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                    </ShareNetwork>
                                    <ShareNetwork network="linkedin" :url="share.url" :title="value.job.name" hashtags="vipawarecruitment" class="btn text-primary" width="30" height="30">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social" d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                                    </ShareNetwork>
                                    <ShareNetwork network="WhatsApp" :url="share.url" :title="value.job.name" hashtags="vipawarecruitment" class="btn text-primary" width="30" height="30">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16"><path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>
                                    </ShareNetwork>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer />
</template>
<script>
import jsPDF from 'jspdf' 
import domtoimage from 'dom-to-image';
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import getSingleJob from '@/composable/getSingleJob'
import SingleJob from '@/components/SingleJob.vue'
import Loading from '@/views/custom/Loading.vue'
import moment from 'moment'

export default {
  components: { Loading,Navbar,Footer,SingleJob },
    props: ['id'],
    setup(props){
        const { value, categoryJobs, country, company,industry, minEducation, maxEducation, error, errorCountry, errorCompany, errorMin, errorMax, errorJobs, load } =  getSingleJob(props.id)
        load()
        return { categoryJobs, value, country, company,industry, minEducation, maxEducation, errorCountry, errorCompany, errorMin, errorMax,errorJobs, error }
    },
    data(props){
        return {
            user: { roles:[0]},
            errors: '',
            auth_errors: '',
            success:'',
            token: localStorage.getItem('user_data'),
            form: {
                id: props.id
            },
            isLoading: true,
            share: {
                url: 'https://recruitment.vipawaworks.com/jobs/' + props.id
            }
      }
    },
    methods: {
        applyJob(id){
            axios.post('/jobs/applyJob/' + this.user.id, this.form).then(response => {
                this.success = response.data
                this.$router.push({ name: 'JobCongraturation'})
            }).catch(errors => {
            this.errors = errors.response.data.errors
        })
        },
        getUser(){
            axios.get('/user').then(response => {
                this.currentUser = response.data
                this.user = this.currentUser.user
            }).catch(errors => {
                if (errors.response.status === 401) {
                this.auth_errors = 'to Apply for This Job' 
            }
                this.errors = 'to Apply for This Job' 
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 1000);
            })
        },
        countView(){
            let id = this.$route.params.id
            axios.post('/jobs/countView/' + id).then(response => {
                this.success = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
        downloadWithCSS() {
            domtoimage
            .toPng(this.$refs.content)
            .then(function(dataUrl) {
            var img = new Image();
            img.src = dataUrl;
            const doc = new jsPDF({
                orientation: "landscape",
                format: [900, 1400]
            });
            const imgProps= doc.getImageProperties(img);
                const pageHeight = 900
                const pdfWidth = doc.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                var heightLeft = pdfHeight
                var position = 0
                doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
                heightLeft -= pageHeight

                while(heightLeft >= 0){
                    position = heightLeft - pdfHeight;
                    doc.addPage()
                    doc.addImage(img, "JPEG",0, position, pdfWidth, pdfHeight);
                    heightLeft -= pageHeight
                }

            const date = new Date();
            const filename =
                "Job" +
                date.getFullYear() +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                ("0" + date.getDate()).slice(-2) +
                ("0" + date.getHours()).slice(-2) +
                ("0" + date.getMinutes()).slice(-2) +
                ("0" + date.getSeconds()).slice(-2) +
                ".pdf";
            doc.save(filename);
            })
            .catch(function(error) {
            console.error("oops, something went wrong!", error);
            });
        },
    },
     created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
        this.getUser()
        this.moment = moment
         if (this.user) {
             this.countView()
         }
        
     }
}
</script>
<style scoped>
.banner {
  background-image: url(../../assets/img/slider.jpg);
}
.page-next-level .page-next li::after {
	content: "";
	font-size: '';
	color: '';
	font-family: '';
}
.card{
	border: none
}
</style>