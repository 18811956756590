<template>
    <div class="card job-box rounded shadow border-0 overflow-hidden">
        <div class="border-bottom">
            <div class="position-relative">
                <img src="@/assets/img/job/full.jpg" class="img-fluid" alt="" style="height:60px">
                <div class="job-overlay bg-white"></div>
            </div>
            <h5 class="mb-0 position">
                <router-link :to="{ name: 'JobDetails', params: {id: job.id}}"  class="text-dark">{{ job.name && job.name.length < 25 ? job.name : job.name.substring(0,23)+".." }}</router-link>
            </h5>
        </div>         
        <div class="card-body content position-relative">
             <ul class="list-unstyled head mb-0">
                <li class="badge bg-success rounded-pill" v-if="job.employment_status == 1">Full Time</li>
                <li class="badge bg-warning rounded-pill" v-if="job.employment_status == 2">Party Time</li>
            </ul>
            <div class="firm-logo text-center">
                <div v-if="job.company.logo != null">
                    <img v-bind:src="'https://api.vipawaworks.com/' + job.company.logo" class="avatar avatar avatar-md-sm float-md-left  rounded-circle shadow me-md-4" alt="">
                </div>
                <div v-else>
                    <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar avatar-md-sm float-md-left rounded-circle shadow me-md-4" alt="">
                </div>
            </div>
            <div class="company-detail text-center mt-3">
                <h5 class="mb-0" v-if="job.company.name">{{ job.company.name && job.company.name.length < 16 ? job.company.name : job.company.name.substring(0,14)+".."  }}</h5>
                    <p class="text-muted" v-if="job.country">{{ job.country.emoji }} {{ job.country.name }}</p>
            </div>
            <ul class="job-facts ">
                <li class="list-inline-item text-center text-muted" v-if="job.target == 3"><svg class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>Freshers Oportunity</li>
                <li class="list-inline-item text-center text-muted" v-else><svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>Expirence:{{ job.min_experience }} <span v-if="job.min_experience == 1">Year</span><span v-else>Years</span></li><br>
                <li class="list-inline-item text-center text-muted"><svg  class="text-success" height="16" width="16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>Deadline: {{moment(new Date(job.deadline)).format('DD/MM/YYYY') }}</li>
            </ul>
            <div class="d-grid">
                <router-link :to="{ name: 'JobDetails', params: {id: job.id}}" class="btn btn-primary">View Details</router-link>
            </div>
        </div>
    </div>
    
</template>

<script>
import moment from 'moment'
export default {
    props: ['job'],
    created(){
        this.moment = moment
    }
}
</script>

<style>
 .card{
     border-radius: 6px;
     border: 1px solid rgba(88,136,247,0.1);
 }
</style>