import { computed, ref } from'vue'

const getCities = () => {
    const cities = ref([])
    const country = ref([])
    const error = ref(null)
    const errorCountry = ref(null)

    const loadCities = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/cities/all')
            if (!data.ok){
                throw Error('no data available')
            }
            cities.value = await data.json()
        }
        catch (err){
            error.value = err.message
        }
        try {
            let selectedCountry = await fetch('https://api.vipawaworks.com/api/country/details/' + city.value.country_id)
            if (!selectedCountry.ok){
                throw Error('The Country does not exist')
            }
            country.value = await selectedCountry.json()
        }
        catch (err){
            errorCountry.value = err.message
        }
    }
    return { cities, country, error, errorCountry, loadCities }
  }

  export default getCities
