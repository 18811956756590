<template>
  <Navbar />
  <div class="home" v-if="!isLoading">
    <section class="bg-auth-home d-table w-100">
          <div class="container">
            <h2 class="card-title text-center">Congratulations {{ user.fname }}, you've added the most important information to your CV!</h2> 

              <div class="row align-items-center">
                    <div class="widget mt-4 pt-2">
                        <div class="progress-box mt-4">
                            <div class="progress">
                                <div class="progress-bar position-relative bg-success" :style="{'width':profile[0] + '%'}">
                                    <div class="progress-value d-block text-muted h6">Your profile strength: {{ profile[0] }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                  <div class="col-lg-8 col-md-6">
                      <div class="card shadow rounded border-0">
                          <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-lg-10 col-md-10 text-center">
                                        <img :src="require('@/assets/img/svg/defaultAvatar.svg')" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                                        <h3 class="title mb-0">{{ user.fname }} {{ user.sname }} {{ user.lname }}</h3>
                                    </div>
                                </div>
                            </div>
                      </div>
                  </div> 
                  <div class="col-lg-4 col-md-6">
                      <br>
                        <div class="card shadow rounded border-0">
                            <div class="card-body">
                                <div class="me-lg-5">   
                                    <img :src="require('@/assets/img/site/profile.png')" class="img-fluid d-block mx-auto" alt="">
                                    <h4 class="text-primary">Useful tip</h4>
                                    <p class="text-muted">
                                        Having a complete CV will increase your chances of being contacted by an employer. And guess what? You can download it as a PDF and use it whenever you want!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
              </div>

              <div class=" justify-content-center">
                <h3 class="text-center">Where to next?</h3>
                <div class="col-md-12 text-center">  
                    <router-link class="btn btn-success mt-4" :to="{ name: 'MyResume'}">Complete My CV</router-link>
                    <router-link class="btn btn-primary mt-4 ms-2" :to="{ name: 'Jobs'}">Search For Jobs</router-link>
                </div>
            </div>
          </div>
    </section>
  </div>
  <div class="home" v-else>
		<Loading />
	</div>
    	<Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: {
   Navbar, Footer,Loading
  },
  data(){
      return {
        form: {
               sex:'',birth_date: '',nationality: '', city: '', visa: 'No Visa', martial_status: '',mobile_phone:'',
            },
        categories:{},
        countries:{},
        industries:{},
        user: {roles: [0]},
        token: localStorage.getItem('user_data'),
        errors: [],
        isLoading: true,
      }
  },
   methods:{
     getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
             if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 1) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 4) {
                this.$router.push({ name: 'CompanyDashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
        editPersonal(){
            axios.post('/profile/stepProfile', this.form).then(response => {
                this.$refs.clearForm.reset()
                this.$router.push({ name: 'Profile'})
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
        },
         profileProgress() {
             axios.get('/profile/completion/' + this.user.id).then(response => {
                 this.profile = response.data
             }).catch(errors => {
             })
         }
  },
  created(){
      document.title = `Register Completed - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`
      this.getUser()  
      this.profileProgress() 
    }

}
</script>

<style>

</style>