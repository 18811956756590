import { ref } from 'vue'

const getSingleJob = (id) => {
    const candidate = ref(null)
    const age = ref(null)
    const rating = ref(null)
    const error = ref(null)
    const errorAge = ref(null)

    const load = async () => {
        try {
            let data = await fetch('https://api.vipawaworks.com/api/candidates/showFreelancer/' + id)
            if (!data.ok){
                throw Error('The Candidate does not exist')
            }
            candidate.value = await data.json()
            document.title = candidate.value.fname +'- Vipawaworks'
        }
        catch (err){
            error.value = err.message
        }
        try {
            let dataAge = await fetch('https://api.vipawaworks.com/api/profile/getAge/' + id)
            if (!dataAge.ok){
                throw Error('The Age does not exist')
            }
            age.value = await dataAge.json()
        }
        catch (err){
            errorAge.value = err.message
        }
        try {
            let dataRating = await fetch('https://api.vipawaworks.com/api/candidates/ratings/' + id)
            if (!dataRating.ok){
                throw Error('The Age does not exist')
            }
            rating.value = await dataRating.json()
        }
        catch (err){
            dataRating.value = err.message
        }
    }
    return { candidate, age, rating, error, errorAge, load }
  }

  export default getSingleJob