<template>
   <Navbar /> 
    <section class="section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h2 class="text-center text-primary">Terms and Conditions</h2>
                    <div class="card shadow border-0 rounded">
                        <div class="card-body">
                            <h5 class="card-title text-primary">Introduction :</h5>
                            <p class="text-muted">
                                By accessing this web site, you are agreeing to be bound by this website's Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws.
                                    If you do not agree with any of these terms, you are prohibited from using or accessing this site.
                                    The materials contained in this web site are protected by applicable copyright and trade mark law. 
                            </p>

                            <h5 class="card-title text-primary">Use License</h5>
                            <p class="text-muted">
                                    Permission is granted to temporarily download one copy of the materials (information or software) on VIPAWAWORKS (T) LTD's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not: </p>
                                    <ul class="list-unstyled text-muted">
                                    <li><span class="text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>modify or copy the materials;</li>
                                    <li><span class="text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
                                    <li><span class="text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>attempt to recompile or reverse engineer any software contained on VIPAWAWORKS (T) LTD's website;</li>
                                    <li><span class="text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>remove any copyright or other proprietary notations from the materials; or</li>
                                    <li><span class="text-success"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right fea icon-sm me-2"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></span>transfer the materials to another person or "mirror" the materials on any other server.</li>
                                    </ul>
                                    <p class="text-muted"> 
                                        This license shall automatically terminate if you violate any of these restrictions and may be terminated by VIPAWAWORKS (T) LTD at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
                            
                            
                            <h5 class="card-title text-primary">Disclaimer </h5>
                            <p class="text-muted">
                                The materials on VIPAWAWORKS (T) LTD's website are provided "as is". VIPAWAWORKS (T) LTD makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchant-ability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. 
                                Further, VIPAWAWORKS (T) LTD does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site. 
                            </p>

                            <h5 class="card-title text-primary">Limitations </h5>
                            <p class="text-muted">
                                In no event shall VIPAWAWORKS (T) LTD or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on VIPAWAWORKS (T) LTD's Internet site, even if VIPAWAWORKS (T) LTD or a VIPAWAWORKS (T) LTD authorized representative has been notified orally or in writing of the possibility of such damage. 
                                Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. 
                            </p>

                            <h5 class="card-title text-primary">Revisions and Errata </h5>
                            <p class="text-muted">
                                The materials on VIPAWAWORKS (T) LTD's website are provided "as is". VIPAWAWORKS (T) LTD makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchant-ability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. 
                                Further, VIPAWAWORKS (T) LTD does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its Internet web site or otherwise relating to such materials or on any sites linked to this site. 
                            </p>
                            <h5 class="card-title text-primary">Links </h5>
                            <p class="text-muted">
                                VIPAWAWORKS (T) LTD has not reviewed all of the sites linked to its Internet web site and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by VIPAWAWORKS (T) LTD of the site. Use of any such linked web site is at the user's own risk
                            </p>
                            <h5 class="card-title text-primary">Site Terms of Use Modifications</h5>
                            <p class="text-muted">
                                VIPAWAWORKS (T) LTD may revise these terms of use for its web site at any time without notice. By using this web site you are agreeing to be bound by the then current version of these Terms and Conditions of Use
                            </p>
                            <h5 class="card-title text-primary">Governing Law </h5>
                            <p class="text-muted">
                                    Any claim relating to VIPAWAWORKS (T) LTD's website shall be governed by the laws of the United Republic of Tanzania without regard to its conflict of law provisions.
                                <br>
                                General Terms and Conditions applicable to Use of a Web Site.  
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
</template>
<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  name: 'Jobs',
  emits: ["paginate"],
  components: {
    Navbar,Footer,Loading,
  },
  data(){
      return {
        currentPage: 'Terms and Conditions',
        previousPage: '',
      }
  },
  created(){
        document.title = `Terms and Condition - Vipawaworks`
    }
  }
</script>
<style scoped>
.nav-pills .nav-link {
    color: #495057 !important;
}
.nav-pills .nav-link.active {
	color: #fff !important;
}
</style>
