<template>
<Navbar />
    <section class="section">
        <br>
        <div class="container">
            <h2 class="text-center text-primary">How can we help you?</h2>
            <form>
                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Search What You Need">
                        </div>
                    </div>
                </div>
            </form>					
            <div id="grid" class="row shuffle justify-content-center">
                <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 picture-item shuffle-item shuffle-item--visible">
                    <div class="card border-0 work-container work-classic">
                        <div class="card-body p-0 text-center">
                            <router-link :to="{ name : 'UserGuide' }" ><img src="@/assets/img/png/help.png" class="rounded work-image" height="200" alt=""></router-link>
                            <div class="content pt-3">
                                <h5 class="mb-0 text-center"><router-link :to="{ name : 'UserGuide' }" class="text-dark title ">User Guide</router-link></h5>
                                <p>Learn more how to use our website in each and every page. in more descriptive way that makes you understand and choose the best option</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    </section>
<Footer />
</template>

<script>
import Navbar from '@/components/help/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { Navbar,Footer },

}
</script>

<style>

</style>