
<template>
    <div class="col mt-4 pt-2" id="tables">
        <div class="component-wrapper rounded shadow">
            <div class="p-4">
                <div class="row">
                    <div class="col">
                        <div class="table-responsive bg-white shadow rounded">
                            <div v-if="preferences.preference_countries.length">
                            <h5 class="text-center">Preferred Countries</h5>
                            <div class="d-flex flex-row-reverse">
                                    <router-link class="btn text-primary end-0" :to="{ name : 'CandidateAddPreferredCountry' }">
                                        <svg height="24" width="24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                    </router-link>
                                </div>
                                <div class="alert alert-danger" v-if="errorsCountry[0]">
                                    <p class="text-center">{{ errorsCountry }}</p>
                                </div>
                                <table class="table mb-0 table-center">
                                    <thead>
                                        <tr>
                                        <th scope="col" class="border-bottom">Country Name</th>
                                        <th scope="col" class="border-bottom">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="country in preferences.preference_countries" :key="country.id">
                                            <td>{{ country.name }}</td>
                                            <td>
                                                <button @click="deleteCountry(country.id)" class="btn btn-pills text-primary">
                                                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </div>
                            <div  v-else>
                                <div class="d-flex flex-row-reverse">
                                    <router-link class="btn text-primary end-0" :to="{ name : 'CandidateAddPreferredCountry' }">
                                        <svg height="24" width="24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                    </router-link>
                                </div>
                                <div class="alert alert-danger">
                                    <h6 class="text-center">Add A Preferable Working Country</h6>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="col">
                        <div class="table-responsive bg-white shadow rounded">
                            <div v-if="preferences.preference_industries.length">
                            <h5 class="text-center">Preferred Industries</h5>
                                <div class="d-flex flex-row-reverse">
                                    <router-link class="btn text-primary end-0" :to="{ name : 'CandidateAddPreferredIndustry' }">
                                        <svg height="24" width="24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                    </router-link>
                                </div>
                                <div class="alert alert-danger" v-if="errorsIndustry[0]">
                                    <p class="text-center">{{ errorsIndustry }}</p>
                                </div>
                                <table class="table mb-0 table-center">
                                    <thead>
                                        <tr>
                                        <th scope="col" class="border-bottom">Industry Name</th>
                                        <th scope="col" class="border-bottom">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="industry in preferences.preference_industries" :key="industry.id">
                                            <td>{{ industry.name }}</td>
                                            <td>
                                                <button @click="deleteIndustry(industry.id)" class="btn btn-pills text-primary">
                                                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </div>
                            <div  v-else>
                                <div class="d-flex flex-row-reverse">
                                    <router-link class="btn text-primary end-0" :to="{ name : 'CandidateAddPreferredIndustry' }">
                                        <svg height="24" width="24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                    </router-link>
                                </div>
                                <div class="alert alert-danger">
                                    <h6 class="text-center">Add a new Preferable working Industries</h6>
                                </div>
                            </div> 
                        </div>   
                    </div>
                    <div class="col">
                        <div class="table-responsive bg-white shadow rounded">
                            <div v-if="employmentTypes.length">
                            <h5 class="text-center">Preferred Employment Type</h5>
                                <div class="d-flex flex-row-reverse">
                                    <router-link class="btn text-primary end-0" :to="{ name : 'CandidateAddPreferredType' }">
                                        <svg height="24" width="24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                    </router-link>
                                </div>
                                <table class="table mb-0 table-center">
                                    <thead>
                                        <tr>
                                        <th scope="col" class="border-bottom">Employment Type</th>
                                        <th scope="col" class="border-bottom">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="type in employmentTypes" :key="type.id">
                                            <td>
                                                <span v-if="type.type_id == 1">Consultant</span>
                                                <span v-if="type.type_id == 2">Contractor</span>
                                                <span v-if="type.type_id == 3">Employee</span>
                                                <span v-if="type.type_id == 4">Temporary Employee</span>
                                                <span v-if="type.type_id == 5">Volunteer</span>
                                            </td>
                                            <td>
                                                <button @click="deleteType(type.id)" class="btn btn-pills text-primary">
                                                    <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> 
                            </div>
                            <div  v-else>
                                <div class="d-flex flex-row-reverse">
                                    <router-link class="btn text-primary end-0" :to="{ name : 'CandidateAddPreferredType' }">
                                        <svg height="24" width="24" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path></svg>
                                    </router-link>
                                </div>
                                <div class="alert alert-danger">
                                    <h6 class="text-center">Add Employment Type that you Prefeer to Work with</h6>
                                </div>
                            </div> 
                        </div> 
                    </div>
            </div>
        </div> 
    </div>
</div>
    
</template>

<script>
import Swal from 'sweetalert2'
export default {
    props:['employmentTypes','preferences','editable'],
    data(){
      return {
          errorsCountry: [],
          errorsIndustry: [],
          errorsType: [],
      }
  },
     methods: {
        deleteCountry(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/preferredCountry/destroy/'+ id)
                .then(response => {
                    this.preferences.preference_countries = this.preferences.preference_countries.filter(country => {
                      return country.id != id
                      })
                }).catch(() => {
                    this.errorsCountry = 'Something Went Wrong'
                })
                Swal.fire(
                'Deleted!',
                'Education has been Deleted.',
                'success'
                )
            }
            })
        },
        deleteIndustry(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/preferredIndustry/destroy/'+ id)
                .then(response => {
                    this.preferences.preference_industries = this.preferences.preference_industries.filter(industry => {
                      return industry.id != id
                      })
                }).catch(() => {
                    this.errorsIndustry = 'Something Went Wrong'
                })
                Swal.fire(
                'Deleted!',
                'Education has been Deleted.',
                'success'
                )
            }
            })
        },
        deleteType(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
            if (result.isConfirmed) {
                axios.delete('/candidate/employmentType/destroy/'+ id)
                .then(response => {
                    this.employmentTypes = this.employmentTypes.filter(type => {
                      return type.id != id
                      })
                }).catch(() => {
                    this.errorsType = 'Something Went Wrong'
                })
                Swal.fire(
                'Deleted!',
                'Education has been Deleted.',
                'success'
                )
            }
            })
        },
        
    }

}
</script>

<style>

</style>