<template>
<NavEmployer :user="user" />
    <!-- <Profile :profile="user_info.companies[0]" :user="user"/> -->
    <div v-if="!isLoading">
        
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h5 class="text-center">Personal Detail :</h5>
                                <div class="alert alert-success" v-if="success">
                                    <p class="text-center">{{ success }}</p>
                                </div>
                                <div class="alert alert-danger" v-if="imageErrors">
                                    <p class="text-center">{{ imageErrors }}</p>
                                </div>
                                <div class="alert alert-danger" v-else-if="errors[0]">
                                    <p class="text-center">{{ errors[0] }}</p>
                                </div>
                                <div class="alert alert-danger" v-else-if="errors">
                                    <p class="text-center">{{ errors }}</p>
                                </div>
                                <div class="mt-3 text-center">
                                    <div class="text-center">
                                        <div v-if="form.photo">
                                            <img :src="form.photo" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                        <div v-else-if="user.avatar">
                                            <img v-bind:src="'https://api.vipawaworks.com/' + user.avatar" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                        <div v-else>
                                            <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar float-md-left avatar-medium rounded-circle shadow me-md-4" alt="">
                                        </div>
                                         <button class=" btn text-success mt-2 border-0 bg-light collapsed" type="button" v-on:click="editable = !editable">
                                            <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path></svg>
                                        </button>
                                        <form @submit.prevent="profileUpload" v-if="editable" id="hide" enctype="multipart/form-data" method="post">
                                            <div class="custom-file">
                                                <input class="custom-file-input input-sm" @change="imageSelected" type="file"  id="customFile">
                                            </div>
                                            <button type="submit" class="btn btn-outline-success mt-2 ms-2" >Upload</button>
                                        </form>
                                    </div>
                                    <div class="mt-md-4 mt-3 mt-sm-0">
                                       
                                        <router-link class="btn btn-warning end-1" :to="{ name : 'CompanyChangePassword' }">Change Password</router-link>
                                        <div   style="">
                                                
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div>
                                     <h5 class="text-center">Personal Information</h5>
                                    <div class="d-flex flex-row-reverse">
                                        <router-link class="btn btn-primary end-0" :to="{ name : 'CompanyEditMyProfile' }">Edit Basic Info</router-link>
                                        
                                    </div>
                                    <div v-if="user">
                                        <table class="table table-borderless">    
                                            <tbody>
                                                <tr>
                                                    <td><span class="fw-bold">Full Name:</span> {{ user.fname }} {{ user.lname }}</td>
                                                    <td><span class="fw-bold">Title:</span> {{ user.roles[0].name }}</td>
                                                    <td><span class="fw-bold">Email:</span> {{ user.email }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="alert alert-danger" v-else>
                                        <h6 class="text-center">Nothing to Show!</h6>
                                    </div>
                                </div>
                                  <div v-if="!user_info.companies[0]" class="col-12">
                                    <div class="justify-content-center">
                                        <h4 class="text-center">Empty Company Profile</h4>
                                        <p class="text-center">Get Started by Filling Up Your <router-link class="btn btn-primary" :to="{ name : 'AddCompanyProfile' }">Company Info</router-link></p>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
    <div v-else>
       <Loading />
   </div>
   <Footer />
</template>

<script>
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import Profile from '@/components/company/Profile.vue'
import Sidebar from '@/components/company/Sidebar.vue'
import CompanyUsers from '@/components/company/CompanyUsers.vue'
import moment from 'moment'
import Swal from 'sweetalert2'
import Loading from '@/views/custom/Loading.vue'
export default {
    components: { Profile,NavEmployer,Footer,Sidebar,Loading,CompanyUsers },
    data(){
        return {
            drawer: true,
            currentUser: {},
            userProfile: {},
            user: {roles:[0]},
            form:{
                photo: null
            },
            editable: false,
            success: false,
            errors: false,
            imageErrors: false,
            applicant: {},
            job: {
                applicants: []
            },
            company_id: null,
            isLoading: true,
            user_info: { companies:[0], },
            token: localStorage.getItem('user_data'),
        }
    },
  methods: {
    getUser(){
    axios.get('/user').then(response => {
        this.currentUser = response.data
        this.user = this.currentUser.user
        if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
        axios.get('/company/' + this.user.id).then(response => {
            this.user_info = response.data
        })
    }).catch(errors => {
         if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
    }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
    })
    }, 
    imageSelected(event){
        let file = event.target.files[0]
        if (file.size > 1548770) {
            this.imageErrors = 'Image Should be less than 1MB'
        }else{
            this.errors = false
            let reader = new FileReader()
            reader.onload = event => {
                this.form.photo = event.target.result
            }
            reader.readAsDataURL(file)
        }
    },
    profileUpload(){
        axios.post('/profile/upload/profile/' + this.user.id, this.form).then(response => {
            this.$refs.clearForm.reset(),
            this.user.avatar = response.data.avatar
            this.success = 'Profile Picture Changed'
            editable = !editable
        }).catch((error) => {
            if (!error.response) {
                this.editable = !this.editable
                return this.success = 'Profile Picture Changed'
            }
            this.errors = error.response.data.errors
        }) 
    }
  },
  mounted(){
      document.title = `My Profile - Vipawaworks`
     axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`  
      this.moment = moment
      this.getUser()  
  }
}
</script>

<style>

</style>