<template>
<header id="topnav" class="defaultscroll sticky ">
  <div id="smallNav">
      <div class="navbar-top d-none d-lg-block small hidden-mobile">
        <div class="container">
          <div class="d-md-flex justify-content-between align-items-center">
            <!-- Top bar left -->
            <ul class="nav">
              <li class="nav-item">
                <router-link :to="{ name : 'About' }" class="nav-link text-primary" aria-current="page">About Us</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'Contacts' }" class="nav-link text-primary" aria-current="page">Contacts Us</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'Feedback' }" class="nav-link text-primary" aria-current="page">Feedback</router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name : 'Help' }" class="nav-link text-primary" aria-current="page">Need Help?</router-link>
              </li>
            </ul>
            <!-- Top bar right -->
            <div class="d-flex align-items-center">
              <ul class="nav">
                <li class="nav-item">
                <a target="_blank" href="https://web.facebook.com/Vipawaworks-100433252263696" class="rounded text-primary px-2 fs-5">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social">
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://www.instagram.com/vipawaworks/" class="rounded text-primary px-2 fs-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social">
                      <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                  </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://twitter.com/vipawaworks" class="rounded text-primary px-2 fs-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social">
                      <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                  </a>
                </li>
                <li class="nav-item">
                  <a target="_blank" href="https://www.linkedin.com/company/vipaji-jobs/" class="rounded text-primary px-2 fs-5">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social">
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="nav-bg">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light ">
      <div class="container-fluid">
        <router-link :to="{ name : 'Home' }" class="navbar-brand bg-transparent ">
          <img src="@/assets/img/logon.png" alt="" class="nav-logo" style="height: 50px;" >
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navigation">
          <ul class="navigation-menu navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link :to="{ name : 'Dashboard' }" class="nav-link" aria-current="page">Dashboard</router-link>
            </li>
             <li class="nav-item dropdown">
              <router-link :to="{ name : 'Jobs' }" class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-current="page">Find Jobs</router-link>
                <ul class="dropdown-menu nav-bg" aria-labelledby="navbarDropdown">
                    <li><router-link :to="{ name : 'Jobs' }" class="btn dropdown-item text-white">All Jobs</router-link></li>
                    <li><router-link :to="{ name : 'Companies' }" class="btn dropdown-item text-white">Search By Companies</router-link></li>
                </ul>
            </li>
            <li class="nav-item">
              <router-link :to="{ name : 'MyResume' }" class="nav-link" aria-current="page">My Resume</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name : 'Activities' }" class="nav-link" aria-current="page">My Applications</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name : 'SeekerJobAlert' }" class="nav-link" aria-current="page">Manage Job Alerts</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name : 'SelfTests' }" class="nav-link text-white" aria-current="page">
                Self Tests
              </router-link>
            </li>
          </ul>
        
          <div>
            <div class="justify-content-end login-btn">
                <ul class="navigation-menu mr-2 navbar-nav">
                  <li class="nav-item">
                      <router-link :to="{ name : 'EmployerRegister' }" class="nav-link" >
                        <button type="button" class="btn btn-outline-light">
                          For Employers
                        </button>
                      </router-link>
                  </li>
                  
                  <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle text-white" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <svg height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"></path></svg>
                      </a>
                        <ul class="dropdown-menu nav-bg dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><router-link :to="{ name : 'Profile' }" class="btn dropdown-item text-white">Account Setting</router-link></li>
                            <li><a class="btn dropdown-item text-white" @click="logout">Log Out</a></li>
                        </ul>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
    </div>
  </div>
    
</header>

    
</template>
<script>

  export default {
    props:['user'],
    data(){
        return{
               token:false,
        }
    },
    
    methods: {
        logout() {
            axios.post('/logout').then(response => {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
            }).catch(errors => {
            })
        }
    },
  
  }
</script>
<style scoped>
.nav-bg{
  background-color: #01406a;
}
 .navbar-light .navbar-nav .nav-link {
	color: #fff;
}
.dropdown-item:hover{
  color: #8ec73a !important;
  background-color: #01406a !important;
}
 .btn-light {
	color: #828a93 !important;
  
}
.nav-link:hover{
  color: #8ec73a;
}
.router-link-exact-active{
    color: #8ec73a !important;
 }
 
 .navbar {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

#topnav .navigation-menu > li > a {
	padding-top: 0px;
	padding-bottom: 0px;
	min-height: 0px;
}
.auth_button {
    min-width: 150px;
    max-width: 150px;
}
.hidden-mobile {
  /* background-image: url(../assets/img/nav.jpg); */
  background-color: #e8effd;
}
</style>