<template>
    <Navbar /> 
    <section class="bg-half-260 d-table w-100 company-bg">
        <div class="bg-overlay"></div>
    </section>
    <div v-if="!isLoading">
        <section class="section" v-if="company">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-5 col-12">
                        <div class="job-profile position-relative">
                            <div class="rounded shadow bg-white">
                                <div class="text-center py-5 border-bottom">
                                    <div v-if="company.logo != null">
                                        <img v-bind:src="'https://api.vipawaworks.com/' + company.logo" class="avatar avatar-medium mx-auto rounded-circle d-block" alt="">
                                    </div>
                                    <div v-else>
                                        <img src="@/assets/img/svg/defaultCompany.svg" class="avatar avatar-medium mx-auto rounded-circle d-block" alt="">
                                    </div>
                                    <h5 class="mt-3 mb-0">{{ company.name }}</h5>
                                    <p class="text-muted mb-0">{{ company.short_name }}</p>
                                </div>

                                <div class="p-4">
                                    <h5>Company Details :</h5>
                                    <ul class="list-unstyled mb-4">
                                        <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin fea icon-sm text-warning me-3"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg><span class="text-muted">Location :</span>{{ company.short_name  }}</li>
                                        <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link fea icon-sm text-warning me-3"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg><span class="text-muted">Company :</span><a :href=" company.web_address" target="blank">Website</a></li>
                                        <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign fea icon-sm text-warning me-3"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg><span class="text-muted">Email :</span>{{company.email}}</li>
                                        <li class="h6"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign fea icon-sm text-warning me-3"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg><span class="text-muted">phone :</span>{{company.phone}}</li>
                                    </ul>
                                    <ul class="list-unstyled social-icon mb-0 mt-4">
                                        <li class="list-inline-item" v-if="company.facebook_url">
                                            <a :href="company.facebook_url" class="rounded text-primary"  target="blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" v-if="company.instagram_url">
                                            <a :href="company.instagram_url" class="rounded text-primary" target="blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" v-if="company.tweeter_url">
                                            <a :href="company.tweeter_url" class="rounded text-primary" target="blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>
                                            </a>
                                        </li>
                                        <li class="list-inline-item" v-if="company.linkedin_url" target="blank">
                                            <a :href="company.linkedin_url" class="rounded text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <div class="ms-md-4">
                            <h4 v-if="company.description">About us :</h4>
                            <p class="text-muted">{{ company.description }}</p>
                            <div v-if="jobs && jobs.length">
                                <div class="row">
                                    <h3 class="text-center text-primary">Jobs At {{ company.name }}</h3>
                                    <div v-for="job in jobs" :key="job.id" class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mt-4 pt-2">
                                        <SingleJob :job="job" />
                                    </div>
                                </div>
                            </div> 
                            <div v-else>
                                <img src="@/assets/img/svg/empty.svg" height="200" class=" rounded mx-auto d-block">
                                <h3 class="text-center">No Job Found</h3>
                                <p class="text-center">This Company Has no Active Jobs Available</p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div v-else>
        <Loading />
    </div>
    <Footer /> 
</template>

<script>
import getSingleCompany from '@/composable/getSingleCompany'
import SingleJob from '@/components/SingleJob.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
  components: { SingleJob,Navbar,Footer,Loading },
    props: ['id'],
    data(){
      return {
          isLoading: true,
      }
  },
    setup(props){
        const { company, jobs,errorCompany, errorJobs, loadCompany } =  getSingleCompany(props.id)

        loadCompany()
        return { company, jobs, errorCompany, errorJobs, }
    },
  methods:{
    isLoad() {
      setTimeout(function () {
        this.isLoading =  false
    }.bind(this), 1000);
   }
  },
  mounted () {
  this.isLoad()
}
}
</script>

<style>
.bg-overlay{
    position: absolute;
}
</style>