<template>
    <div class="sidebar sticky-bar p-4 rounded shadow">
        <div class="card-body">
            <div class="row justify-content-center d-sm-flex">
                <div v-if="user_info.avatar" class="col-md-4">
                    <img v-bind:src="'https://api.vipawaworks.com/' + user_info.avatar" class="avatar avatar-large shadow me-md-4" alt="">
                </div>
                <div v-else class="col-md-4">
                    <img src="@/assets/img/svg/defaultAvatar.svg" class="avatar avatar-large shadow me-md-4" alt="">
                </div>
            </div>
        </div>
        <div class="widget" v-if="profile">
            <h5 class="widget-title text-center">Activities :</h5>
            <div class="row mt-4">
                <div class="col-6 text-center">
                    <svg class="feather feather-users fea icon-ex-md text-primary mb-1" width="24" height="24" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                    <h5 class="mb-0" v-if="profile[1]">{{ profile[1] }}</h5>
                    <h5 class="mb-0" v-else>0</h5>
                    <p class="text-muted mb-0">Job Applied</p>
                </div>
                <div class="col-6 text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users fea icon-ex-md text-primary mb-1">
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <h5 class="mb-0" v-if="profile[2]">{{ profile[2] }}</h5>
                    <h5 class="mb-0" v-else>0</h5>
                    <p class="text-muted mb-0">Interviews Done</p>
                </div>
            </div>
        </div>
        <div class="widget mt-4 pt-2" v-if="profile">
            <h5 class="widget-title" v-if="profile[0] < 70">Improve Your Profile</h5>
            <div class="progress-box mt-4" v-if="profile[0]">
                <div class="progress">
                    <div class="progress-bar position-relative bg-success" v-if="profile[0] > 80" :style="{'width':profile[0] + '%'}">
                          <div class="progress-value d-block text-muted h6">Profile Completion {{ profile[0] }}%</div>
                    </div>
                    <div class="progress-bar position-relative bg-primary" v-else-if="profile[0] >70" :style="{'width':profile[0] + '%'}">
                          <div class="progress-value d-block text-muted h6">Profile Completion {{ profile[0] }}%</div>
                    </div>
                    <div class="progress-bar position-relative bg-warning" v-else-if="profile[0] > 49" :style="{'width':profile[0] + '%'}">
                        <div class="progress-value d-block text-muted h6">Profile Completion {{ profile[0] }}%</div>
                    </div>
                    <div class="progress-bar position-relative bg-danger" v-else :style="{'width':profile[0] + '%'}">
                        <div class="progress-value d-block text-muted h6">Profile Completion {{ profile[0] }}%</div>
                    </div>
                </div>
                <div v-if="profile[0] < 80">
                    <p class="text-center">
                        Reach profile strength of <span class="text-success">80%</span> to be in the top 10% of highly visible users. 
                        
                    </p>
                    <div v-if="picture == 0" class="text-center">
                        <router-link :to="{ name : 'MyResume' }" class="btn btn-primary">Complete Resume</router-link>
                    </div>
                </div>
                
                <div class="text-center" v-if="picture == 1">
                    <router-link :to="{ name : 'CandidateJob' }" type="button" class="btn btn-outline-primary" v-if="!user_info.candidate_jobs.length">Add Your preference Job & Country +20</router-link>
                    <router-link :to="{ name : 'AddEducation' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.education.length">Add Your Education +10</router-link>                    
                    <router-link :to="{ name : 'AddExperience' }" type="button" class="btn btn-outline-primary" v-if="!user_info.experiences.length">Add Your Experience +7</router-link>
                    <router-link :to="{ name : 'AddSpecialist' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.specialists.length">Add Your Specialists +5</router-link>
                    <router-link :to="{ name : 'AddMembership' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.memberships.length">Add Your Membership +3</router-link>
                    <router-link :to="{ name : 'AddSkill' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.skills.length">Add Your Skills +5</router-link>
                    <router-link :to="{ name : 'AddLanguage' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.languages.length">Add Your Languages +5</router-link>
                    <router-link :to="{ name : 'AddTraining' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.trainings.length">Add Your Training +5</router-link>
                    <router-link :to="{ name : 'AddCertificate' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.certificates.length">Add Your Certificate +5</router-link>
                    <router-link :to="{ name : 'AddHobby' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.hobbies.length">Add Your Hobby +5</router-link>
                    <router-link :to="{ name : 'AddReferee' }"  type="button" class="btn btn-outline-primary" v-if="!user_info.referees.length">Add Your Referee +5</router-link>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>

import Nav from './Nav.vue'
export default {
    props:['user','user_info','picture'],
     components: { Nav },
     data(){
      return {
          profile: [],
          token: localStorage.getItem('user_data'),
      }
  },

      methods: {
         profileProgress() {
             axios.get('/profile/completion/' + this.user.id).then(response => {
                 this.profile = response.data
             }).catch(errors => {
             })
         }
     },
     created(){
      this.profileProgress()  
  }
    
}
</script>

<style>
.btn-outline-primary{
    min-width: 300px;
    max-width: 300px;
    margin-top: 5px;
}
</style>