<template>
<NavEmployer :user="user" />
    <div v-if="!isLoading">
        <section class="section mt-60">
            <div class="container mt-lg-3">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-12 d-lg-block ">
                        <ProgressSidebar ProgressSidebar :progress="user_info.companies[0]" :job="job"/>
                    </div>

                    <div class="col-lg-10 col-md-10 col-12">
                        <div class="card border-0 rounded shadow">
                            <div class="card-body">
                                <h3 class="text-center">Interview Progress <span class="text-primary">{{ applicant.fname }} {{ applicant.sname }} {{ applicant.lname }}</span> </h3>
                                <div class="alert alert-success" v-if="success">
                                    <h6 class="text-center">{{ success }}</h6>
                                </div>
                                <div class="alert alert-danger" v-if="errors[0]">
                                    <h6 class="text-center">{{ errors }}</h6>
                                </div>
                                
                                <div class="border-bottom pb-4" v-if="first_interview.attitude">
                                    <div class="row">
                                        <div class="col-md-6 mt-4">
                                            <div class="mt-4">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-1" >
                                                        <h5 class="text-center">First Interview Results</h5>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Skills:</a> 
                                                                <span v-if="first_interview.skill == 9">Outstanding</span>
                                                                <span v-if="first_interview.skill == 8">Excellent</span>
                                                                <span v-if="first_interview.skill == 7">Very Good</span>
                                                                <span v-if="first_interview.skill == 6">Good</span>
                                                                <span v-if="first_interview.skill == 5">Above Average</span>
                                                                <span v-if="first_interview.skill == 4">Average</span>
                                                                <span v-if="first_interview.skill == 3">Below Average</span>
                                                                <span v-if="first_interview.skill == 2">Poor</span>
                                                                <span v-if="first_interview.skill == 1">Very Poor</span>
                                                                <span v-if="first_interview.skill == 0">Extreemly Poor</span>
                                                        </p> 
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Attitude:</a> 
                                                            <span v-if="first_interview.attitude == 9">Outstanding</span>
                                                                <span v-if="first_interview.attitude == 8">Excellent</span>
                                                                <span v-if="first_interview.attitude == 7">Very Good</span>
                                                                <span v-if="first_interview.attitude == 6">Good</span>
                                                                <span v-if="first_interview.attitude == 5">Above Average</span>
                                                                <span v-if="first_interview.attitude == 4">Average</span>
                                                                <span v-if="first_interview.attitude == 3">Below Average</span>
                                                                <span v-if="first_interview.attitude == 2">Bad</span>
                                                                <span v-if="first_interview.attitude == 1">Very Bad</span>
                                                                <span v-if="first_interview.attitude == 0">Extreemly Worse</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Knowledge:</a> 
                                                                <span v-if="first_interview.knowledge == 9">Outstanding</span>
                                                                <span v-if="first_interview.knowledge == 8">Extreemly Knowledgable</span>
                                                                <span v-if="first_interview.knowledge == 7">Very Knowledgable</span>
                                                                <span v-if="first_interview.knowledge == 6">Knowledgable</span>
                                                                <span v-if="first_interview.knowledge == 5">Above Average</span>
                                                                <span v-if="first_interview.knowledge == 4">Average</span>
                                                                <span v-if="first_interview.knowledge == 3">Below Average</span>
                                                                <span v-if="first_interview.knowledge == 2">Poor</span>
                                                                <span v-if="first_interview.knowledge == 1">Very Poor</span>
                                                                <span v-if="first_interview.knowledge == 0">Extreemly Poor</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Availability:</a> 
                                                                <span v-if="first_interview.availability == 8">One Year</span>
                                                                <span v-if="first_interview.availability == 7">Six Months</span>
                                                                <span v-if="first_interview.availability == 6">Three Months</span>
                                                                <span v-if="first_interview.availability == 5">Two Months or Less</span>
                                                                <span v-if="first_interview.availability == 4">One Month or Less</span>
                                                                <span v-if="first_interview.availability == 3">Two Weeks or Less</span>
                                                                <span v-if="first_interview.availability == 2">One Week or Less</span>
                                                                <span v-if="first_interview.availability == 1">Immediately</span>
                                                        </p>

                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Additional Knowledge:</a> 
                                                            {{ first_interview.addition_knowledge }}
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Salary:</a> 
                                                            {{ first_interview.salary }}
                                                            <span v-if="first_interview.currency == 1">TSH</span>
                                                                <span v-if="first_interview.currency == 2">USD</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Rating:</a> 
                                                            <star-rating v-model:rating="first_interview.rating" :increment="0.1" :read-only="true" :animate="true"></star-rating>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">General Comment:</a> 
                                                                {{ first_interview.employer_comment }}
                                                        </p>
                                                        <hr>
                                                        <router-link :to="{ name:'CompanyFirstInterview', params:{ id:applicant.id, job:job} }" class="btn btn-primary">
                                                           Change Interview Results
                                                        </router-link>
                                                        <div class="mt-4" v-if="progress.first_interview == 0">
                                                            <button type="button"  @click="publishResults({applicant:applicant.id})" class="btn btn-success">Publish Results</button>
                                                            <p><small>This button will publish the result to the Candidate</small></p>
                                                        </div>
                                                        <div v-else>
                                                            <p class="text-success"><small>This Results is already published to Candidate</small></p>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 mt-4 pt-2 pt-sm-0">
                                            <div class="mt-4" v-if="second_interview.attitude">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-1">
                                                        <h5 class="text-center">Second Interview Results</h5>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Skills:</a> 
                                                                <span v-if="second_interview.skill == 9">Outstanding</span>
                                                                <span v-if="second_interview.skill == 8">Excellent</span>
                                                                <span v-if="second_interview.skill == 7">Very Good</span>
                                                                <span v-if="second_interview.skill == 6">Good</span>
                                                                <span v-if="second_interview.skill == 5">Above Average</span>
                                                                <span v-if="second_interview.skill == 4">Average</span>
                                                                <span v-if="second_interview.skill == 3">Below Average</span>
                                                                <span v-if="second_interview.skill == 2">Poor</span>
                                                                <span v-if="second_interview.skill == 1">Very Poor</span>
                                                                <span v-if="second_interview.skill == 0">Extreemly Poor</span>
                                                        </p> 
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Attitude:</a> 
                                                                <span v-if="second_interview.attitude == 9">Outstanding</span>
                                                                <span v-if="second_interview.attitude == 8">Excellent</span>
                                                                <span v-if="second_interview.attitude == 7">Very Good</span>
                                                                <span v-if="second_interview.attitude == 6">Good</span>
                                                                <span v-if="second_interview.attitude == 5">Above Average</span>
                                                                <span v-if="second_interview.attitude == 4">Average</span>
                                                                <span v-if="second_interview.attitude == 3">Below Average</span>
                                                                <span v-if="second_interview.attitude == 2">Bad</span>
                                                                <span v-if="second_interview.attitude == 1">Very Bad</span>
                                                                <span v-if="second_interview.attitude == 0">Extreemly Worse</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Knowledge:</a> 
                                                                <span v-if="second_interview.knowledge == 9">Outstanding</span>
                                                                <span v-if="second_interview.knowledge == 8">Extreemly Knowledgable</span>
                                                                <span v-if="second_interview.knowledge == 7">Very Knowledgable</span>
                                                                <span v-if="second_interview.knowledge == 6">Knowledgable</span>
                                                                <span v-if="second_interview.knowledge == 5">Above Average</span>
                                                                <span v-if="second_interview.knowledge == 4">Average</span>
                                                                <span v-if="second_interview.knowledge == 3">Below Average</span>
                                                                <span v-if="second_interview.knowledge == 2">Poor</span>
                                                                <span v-if="second_interview.knowledge == 1">Very Poor</span>
                                                                <span v-if="second_interview.knowledge == 0">Extreemly Poor</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Availability:</a> 
                                                                <span v-if="second_interview.availability == 8">One Year</span>
                                                                <span v-if="second_interview.availability == 7">Six Months</span>
                                                                <span v-if="second_interview.availability == 6">Three Months</span>
                                                                <span v-if="second_interview.availability == 5">Two Months or Less</span>
                                                                <span v-if="second_interview.availability == 4">One Month or Less</span>
                                                                <span v-if="second_interview.availability == 3">Two Weeks or Less</span>
                                                                <span v-if="second_interview.availability == 2">One Week or Less</span>
                                                                <span v-if="second_interview.availability == 1">Immediately</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Additional Knowledge:</a> 
                                                            {{ second_interview.addition_knowledge }}
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Salary:</a> 
                                                            {{ second_interview.salary }}
                                                            <span v-if="second_interview.currency == 1">TSH</span>
                                                                <span v-if="second_interview.currency == 2">USD</span>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">Rating:</a> 
                                                           <star-rating v-model:rating="second_interview.rating" :increment="0.1" :read-only="true" :animate="true"></star-rating>
                                                        </p>
                                                        <p class="text-muted mb-0">
                                                            <a href="javascript:void(0)" class="text-primary">General Comment:</a> 
                                                                {{ second_interview.employer_comment }}
                                                        </p>
                                                        <hr>
                                                        <router-link :to="{ name:'CompanySecondInterview', params:{ id:applicant.id, job:job} }" class="btn btn-primary">
                                                           Change Interview Results
                                                        </router-link>
                                                        <div class="mt-4" v-if="progress.second_interview == 0">
                                                            <button type="button"  @click="publishSecondResults({applicant:applicant.id})" class="btn btn-success">Publish Results</button>
                                                            <p><small>This button will publish the result to the Candidate</small></p>
                                                        </div>

                                                        <div v-else>
                                                            <p class="text-success"><small>This Results is already published to Candidate</small></p>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-4 alert alert-danger text-center" v-else>
                                                <h5>This Candidate has not been Second Interviewed</h5>
                                                <div v-if="progress.second_shortlisted == 1">
                                                    <router-link :to="{ name:'CompanySecondInterview', params:{ id:applicant.id, job:job} }" class="btn btn-primary">
                                                        Interview Now
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4 alert alert-danger text-center" v-else>
                                    <h4 class="text-center">This Candidate has not been Interviewed</h4>
                                     <div v-if="progress.first_shortlisted == 1">
                                        <router-link :to="{ name:'CompanyFirstInterview', params:{ id:applicant.id, job:job} }" class="btn btn-primary">
                                            Interview Now
                                        </router-link>
                                     </div>
                                </div>
                                <div class="mt-4" >
                                    <h4 class="text-center">Take Action</h4>
                                    <div class="d-grid gap-2 d-md-flex d-md-block justify-content-center">
                                        <router-link :to="{ name:'CandidateResume', params:{ id:applicant.id} }" class="btn btn-secondary  me-md-2">Preview Resume</router-link>
                                        <div v-if="progress.passed == 1">
                                            <button type="button"  @click="hireApplicant({applicant:applicant.id})" class="btn btn-success">Hire</button>
                                        </div>
                                        <div v-else-if="progress.second_interview == 1">
                                            <button type="button"  @click="passApplicant({applicant:applicant.id})" class="btn btn-success">Pass</button>
                                        </div>
                                        <div v-else-if="progress.first_shortlisted == 0">
                                            <button type="button" v-on:click="shortlist_first = !shortlist_first" class="btn btn-success">Shortlist</button>
                                        </div>
                                        <div v-else-if="progress.second_shortlisted == 0">
                                            <div v-if="progress.second_shortlisted == 0">
                                                <button type="button"  v-on:click="shortlist_second = !shortlist_second" class="btn btn-success">Second Shortlist</button>
                                            </div>
                                        </div>
                                        
                                        <div v-if="progress.rejected == 1">
                                            <button class="btn btn-danger" @click="revokeRejectionApplicant({applicant:applicant.id})">Revoke Rejection</button>
                                        </div>
                                        <div v-else>
                                            <button class="btn btn-danger" @click="rejectApplicant({applicant:applicant.id})">Reject</button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="shortlist_first">
                                    <form @submit.prevent="shortList" enctype="multipart/form-data" method="post">
                                        <div class="row">
                                            <input type="hidden" v-model="form.applicant" />
                                            <div class="col-6">
                                                <label class="form-label">Date of interview</label>
                                                <input type="date" id="meeting-time" v-model="form.interview_date" rows="4" class="form-control"> 
                                                <div v-if="errors.interview_date" class="text-small text-danger">{{ errors.interview_date[0] }}</div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Time of interview</label>
                                                <input type="time" id="meeting-time" v-model="form.interview_time" rows="4" class="form-control"> 
                                                <div v-if="errors.interview_time" class="text-small text-danger">{{ errors.interview_time[0] }}</div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Interview Location</label>
                                                <textarea v-model="form.interview_location" rows="4" class="form-control" placeholder="Please provide some neccessary location for interview"></textarea>
                                                <div v-if="errors.interview_location" class="text-small text-danger">{{ errors.interview_location[0] }}</div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Interview Instructions</label>
                                                <textarea v-model="form.interview_instructions" rows="4" class="form-control" placeholder="Please provide some neccessary infomation for interview"></textarea>
                                                <div v-if="errors.interview_instructions" class="text-small text-danger">{{ errors.interview_instructions[0] }}</div>
                                            </div>
                                        </div>
                                        
                                    <button type="submit" class="btn btn-outline-success mt-2 ms-2" >Shortlist Applicant</button>
                                    </form>
                                </div>
                                <div v-if="shortlist_second">
                                    <form @submit.prevent="secondShortlistApplicant" enctype="multipart/form-data" method="post">
                                        <div class="row">
                                            <input type="hidden" v-model="form.applicant" />
                                            <div class="col-6">
                                                <label class="form-label">Date of interview</label>
                                                <input type="date" id="meeting-time" v-model="form.interview_date" rows="4" class="form-control"> 
                                                <div v-if="errors.interview_date" class="text-small text-danger">{{ errors.interview_date[0] }}</div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Time of interview</label>
                                                <input type="time" id="meeting-time" v-model="form.interview_time" rows="4" class="form-control"> 
                                                <div v-if="errors.interview_time" class="text-small text-danger">{{ errors.interview_time[0] }}</div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Interview Location</label>
                                                <textarea v-model="form.interview_location" rows="4" class="form-control" placeholder="Please provide some neccessary location for interview"></textarea>
                                                <div v-if="errors.interview_location" class="text-small text-danger">{{ errors.interview_location[0] }}</div>
                                            </div>
                                            <div class="col-6">
                                                <label class="form-label">Interview Instructions</label>
                                                <textarea v-model="form.interview_instructions" rows="4" class="form-control" placeholder="Please provide some neccessary infomation for interview"></textarea>
                                                <div v-if="errors.interview_instructions" class="text-small text-danger">{{ errors.interview_instructions[0] }}</div>
                                            </div>
                                        </div>
                                        
                                    <button type="submit" class="btn btn-outline-success mt-2 ms-2" >Second Shortlist Applicant</button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
     <div v-else>
       <Loading />
   </div>
<Footer />
</template>

<script>
import moment from 'moment'
import StarRating from 'vue-star-rating'
import NavEmployer from '@/components/company/NavEmployer.vue'
import Footer from '@/components/Footer.vue'
import ProgressSidebar from '@/components/company/ProgressSidebar.vue'
import Loading from '@/views/custom/Loading.vue'
export default {
    props:['job'],
  components: { NavEmployer,Footer,ProgressSidebar,Loading, StarRating },
    data(){
        return {
            drawer: true,
            currentUser: {},
            userProfile: {},
            user: {},
            first_interview: {},
            second_interview: {},
            success: false,
            errors: false,
            applicant: {},
            shortlist_first: false,
            shortlist_second: false,
            form:{
                applicant: this.$route.params.id,
               	interview_date: '',
                interview_time:'',
                interview_location:'',
                interview_instructions:'',
            },
            isLoading: true,
            progress: {},
            user_info: {companies:[0]},
            token: localStorage.getItem('user_data'),
        }
    },
  methods: {
      getUser(){
        axios.get('/user').then(response => {
            this.currentUser = response.data
            this.user = this.currentUser.user
            if (this.user.banned == 1) {
                this.$router.push({ name: 'Banned'})
            }
             if (this.user.email_verified_at == null) {
                this.$router.push({ name: 'VerifyEmail'})
            }
            if (this.user.roles.length == 0) {
                this.$router.push({ name: 'Roles'})
            }if (this.user.roles[0].id == 3 || this.user.roles[0].id == 5) {
                this.$router.push({ name: 'Dashboard'})
            }if (this.user.roles[0].id == 2 ) {
                this.$router.push({ name: 'FreelancerDashboard'})
            }
            axios.get('/company/' + this.user.id).then(response => {
                this.user_info = response.data
            })
        }).catch(errors => {
             if (errors.response.status === 401) {
                 localStorage.removeItem('user_data')
                 this.$router.push({ name: 'Login'})
            }
        })
       
      },
      getApplicant(){
        let id = this.$route.params.id
        axios.get('/profile/' + id).then(response => {
            this.applicant = response.data
            }).catch(errors => {
        }).finally(() => {
            setTimeout(function () {
                this.isLoading =  false
            }.bind(this), 500);
        })
      },
      shortList(){
          axios.post('/application/shortlist/' + this.job, this.form).then(response => {
              this.success = response.data.success
              this.$router.push({ name: 'CompanyApplicationProgress', params:{ id:this.job}})
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
       checkInterview(){
        let id = this.$route.params.id
          axios.get('/application/checkInterview/' + id + '/' + this.job).then(response => {
              this.first_interview = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      checkSecondInterview(){
        let id = this.$route.params.id
          axios.get('/application/checkSecondInterview/' + id + '/' + this.job).then(response => {
              this.second_interview = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      checkProgress(){
        let id = this.$route.params.id
          axios.get('/candidate/application/checkInterview/' + id + '/' + this.job).then(response => {
              this.progress = response.data
          }).catch(errors => {
            this.errors = errors.response.data.errors
        })
      },
      rejectApplicant(applicant){
          let job = this.$route.params.job
          axios.post('/application/reject/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
      },
      revokeRejectionApplicant(applicant){
          let job = this.$route.params.job
          axios.post('/application/revokeRejection/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
      },
      publishResults(applicant){
          let job = this.$route.params.job
          axios.post('/application/firstInterviewed/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = true
        })
      },
      publishSecondResults(applicant){
          let job = this.$route.params.job
          axios.post('/application/secondInterviewed/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
      },  
      secondShortlistApplicant(){
          let job = this.$route.params.job
          axios.post('/application/secondShortlist/' + job, this.form).then(response => {
              this.success = response.data.success
              this.$router.push({ name: 'CompanySecondShortlisted', params:{ id:this.job}})
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false
        })
      },
      passApplicant(applicant){
          let job = this.$route.params.job
          axios.post('/application/pass/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = false

        })
      },
      hireApplicant(applicant){
          let job = this.$route.params.job
          axios.post('/application/hire/' + job, applicant).then(response => {
              this.success = response.data.success
              this.errors = false
          }).catch(errors => {
            this.errors = errors.response.data.errors
            this.success = true
        })
      },
  },
  created(){
      document.title = `Candidate Details - Vipawaworks`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.moment = moment
      this.getUser()  
      this.getApplicant()
      this.checkProgress()
      this.checkInterview()
      this.checkSecondInterview()
  },
}
</script>

<style>

</style>