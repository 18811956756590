<template>
    <div v-if="freelancers.length">
        <div class="row">
            <div v-for="freelancer in freelancers" :key="freelancer.id" class="col-12 mt-4 pt-2">
                <div class="card shop-list border-0 shadow position-relative">
                    <div class="row align-items-center g-0">
                        <div class="col-lg-4 col-md-6">
                            <div class="shop-image position-relative overflow-hidden">
                                <router-link to="" class="overlay-work" v-if="freelancer.user.avatar">
                                    <img v-bind:src="'https://api.vipawaworks.com/' + freelancer.user.avatar" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                                <router-link to="" class="overlay-work" v-else>
                                    <img src="@/assets/img/svg/defaultAvatar.svg" height="150" class="rounded mx-auto d-block" alt="">
                                </router-link>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-6">
                            <div class="card-body content p-4">
                                <router-link to="" class="text-dark product-name h5">{{ freelancer.user.fname }} {{ freelancer.user.lname}}</router-link>
                                <div class="d-lg-flex align-items-center mt-2 mb-3">
                                    <h6 class="small fst-italic mb-0 me-3 text-success"> Amount Offered:<span v-if="freelancer.currency == 1"> Tsh</span> <span v-else-if="freelancer.currency == 2"> USD</span> {{ Number(freelancer.amount).toLocaleString() }}
                                        <span v-if="freelancer.duration == 1">per Day</span><span v-else-if="freelancer.duration == 2">Weekly</span><span v-else-if="freelancer.duration == 3">Monthlty</span><span v-else-if="freelancer.duration == 4">Until Service is Over</span>
                                    </h6>
                                </div>
                                <ul class="list-unstyled mb-0">
                                    <br>
                                    <li class="list-inline-item"><a href="javascript:void(0)" class="text-primary">
                                        <span class="text-success">
                                            <svg height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                        </span>
                                        Status: <span class="text-danger" v-if="freelancer.rejected == 1">Rejected Offer</span><span class="text-success" v-else-if="freelancer.completed == 1">Completed Task</span>
                                        <span class="text-success" v-else-if="freelancer.accepted == 1">Offer Accepted</span><span class="text-secondary" v-else>Offer Pending</span>
                                    </a>
                                    </li>
                                </ul>
                                <br>
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <router-link :to="{name : 'CompanyFreelancerDetails', params:{ id:freelancer.id }}" type="button" class="btn btn-pills btn-success ">Details</router-link>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
     <div v-else>
        <div class="justify-content-center">
            <img src="@/assets/img/svg/empty.svg" height="200" class="rounded mx-auto d-block">
            <h4 class="text-center">No Freelancers</h4>
            <p class="text-center">There are no freelancers Requested For now.</p>
        </div>
    </div>
</template>

<script>
export default {
    props:['company'],
    data(){
        return {
            freelancers: [],
            editable: false,
             form: {
                amount: '',
                currency: 1,
                duration: 1,
                details: '',
            },
            success: false,
            errors: false,
        }
    },
    methods:{
         getFreelancers(){
          axios.get('/company/freelancers/' + this.company.id).then(response => {
                this.freelancers = response.data
        }).catch(errors => {
        })
      },
      requestService(){
            axios.post('/freelancing/request/service/' + this.company.id, this.form).then(response => {
                this.success = response.data.success
            }).catch(errors => {
            this.errors = errors.response.data.errors
        })
        },
    },
    created(){
        this.getFreelancers()
    }
}
</script>

<style>

</style>